import { FC, useState } from "react";
import { Formik, Form } from "formik";
import {
  forgetPasswordInitValues,
  forgetPasswordSchema,
} from "../../helpers/auth.helper";
import InputField from "../../../../components/Form/InputField/InputField";
import { AuthMessage } from "../../../../constants/api.message";
import { ApiMessageType } from "../../types/auth.types";
import classes from "./ForgetPasswordScreen.module.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { forgetPassword } from "../../redux/auth.slice";
import { Link, useHistory } from "react-router-dom";
import { PathsEnum } from "../../../../constants/paths";
import logo from "../../../../assets/logo.png";
import { RootState } from "../../../../redux/store";

const ForgetPasswordScreen: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  const [displaySentEmail, setDisplaySentEmail] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();

  if (token) {
    history.push(PathsEnum.CHAT);
  }

  const handleForgetPassword = (values: any) => {
    dispatch(
      forgetPassword({
        Email: values.email,
      })
    )
      .unwrap()
      .then((data) => {
        if (data.apiMessage.Type === "success") {
          setDisplaySentEmail(true);
        }
        setApiMessage(data.apiMessage);
      });
  };
  return (
    <div className={classes.forgetPasswordScreen}>
      <img alt="logo" src={logo} className={classes.logo} />
      <div className={classes.title}>Project manager</div>
      <div className={classes.forgetPasswordText}>Quên mật khẩu</div>
      {displaySentEmail ? (
        <div>
          <div className={classes.label}>{AuthMessage[apiMessage.Code]}</div>
          <div
            className={classes.returnLogin}
            onClick={() => history.push(PathsEnum.LOGIN)}
          >
            Quay lại trang Đăng nhập
          </div>
        </div>
      ) : (
        <>
          <div className={classes.label}>
            Vui lòng nhập email để đặt lại mật khẩu
          </div>
          <Formik
            initialValues={forgetPasswordInitValues}
            validationSchema={forgetPasswordSchema}
            onSubmit={handleForgetPassword}
          >
            <Form>
              <InputField
                type="email"
                name="email"
                placeholder="Email"
                required
              />
              {apiMessage?.Type === "error" && (
                <div className={classes.error}>
                  {AuthMessage[apiMessage?.Code]}
                </div>
              )}
              <InputField type="submit" name="submit" value="Gửi email" />
              <div className={classes.loginLink}>
                <Link to={PathsEnum.LOGIN} className={classes.loginLink}>
                  Quay lại Đăng nhập
                </Link>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </div>
  );
};

export default ForgetPasswordScreen;
