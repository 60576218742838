import React, { FC, useEffect, useState } from "react";
import classes from "./ChatScreen.module.css";
import ChatSidebar from "../../components/ChatSidebar/ChatSidebar";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { useHistory, useParams } from "react-router-dom";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";
import {
  addNewMessage,
  deleteMessageSignalR,
  getConversations,
  updateConversationsDueDelete,
} from "../../redux/chat.slice";
import Header from "../../../../components/Header/Header";
import { PathsEnum } from "../../../../constants/paths";
import Popup from "../../../../components/Popup/Popup";
import NewChat from "../../components/NewChat/NewChat";
import NewGroupChat from "../../components/NewGroupChat/NewGroupChat";
import NoChat from "../../components/NoChat/NoChat";
import {
  GroupChatActionEnum,
  MessageTypeEnum,
} from "../../../../constants/enum";
const ChatBox = React.lazy(() => import("../../components/ChatBox/ChatBox"));

const ChatScreen: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { receiverId } = useParams<{ receiverId: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.auth.token);
  const connection = useAppSelector(
    (state: RootState) => state.home.connection
  );
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const conversations = useAppSelector(
    (state: RootState) => state.chat.conversations
  );
  const conversation = conversations.find((con) => con.Id === Number(id));

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [displayNewChat, setDisplayNewChat] = useState(false);
  const [displayNewGroupChat, setDisplayNewGroupChat] = useState(false);
  const [displaySidebar, setDisplaySidebar] = useState(
    receiverId || id ? false : true
  );

  if (!token) {
    history.push(LOGIN_ROUTE);
  }

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (conversations.length > 0 && screenWidth >= 992) {
      if (receiverId) {
        history.push(
          PathsEnum.NEW_CONVERSATION.replace(":receiverId", receiverId)
        );
      } else if (id) {
        history.push(PathsEnum.EXIST_CONVERSATION.replace(":id", id));
      } else {
        history.push(
          PathsEnum.EXIST_CONVERSATION.replace(":id", `${conversations[0]?.Id}`)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations.length]);

  useEffect(
    () => {
      connection?.on("ReceiveMessage", (data) => {
        let isConversation = conversations.some(
          (conversation) => conversation.Id === data.ConversationId
        );
        if (data.Type === 6) {
          dispatch(deleteMessageSignalR(data.Id));
        }
        if (!isConversation) {
          dispatch(getConversations(currentUser.Id))
            .unwrap()
            .then(() => dispatch(addNewMessage(data)));
        }
        if (isConversation && data.Type !== MessageTypeEnum.DELETE_MESSAGE) {
          dispatch(addNewMessage(data));
        }
        if (
          isConversation &&
          data.Type === MessageTypeEnum.DELETE_MESSAGE &&
          data.Id === conversation.LastMessageId
        ) {
          dispatch(getConversations(currentUser.Id));
        }
        if (data.Type === MessageTypeEnum.DELETE_GROUP_CHAT) {
          dispatch(getConversations(currentUser.Id))
            .unwrap()
            .then(() => history.push(PathsEnum.CHAT));
        }
        if (data?.UserIdsDeleted?.length > 0) {
          if (data?.UserIdsDeleted[0] === `${currentUser.Id}`) {
            dispatch(updateConversationsDueDelete(data.ConversationId));
          } else {
            dispatch(getConversations(currentUser.Id));
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connection]
  );

  return (
    <>
      <Header />
      {screenWidth < 992 ? (
        <div className={classes.chatContainer}>
          {displaySidebar ? (
            <ChatSidebar
              id={id}
              conversations={conversations}
              setDisplaySidebar={setDisplaySidebar}
              screenWidth={screenWidth}
            />
          ) : (
            <>
              {conversations.length === 0 && !id && !receiverId ? (
                <NoChat
                  currentUser={currentUser}
                  setDisplayNewChat={setDisplayNewChat}
                  setDisplayNewGroupChat={setDisplayNewGroupChat}
                  screenWidth={screenWidth}
                />
              ) : (
                <ChatBox
                  id={id}
                  receiverId={receiverId}
                  currentUserId={currentUser.Id}
                  conversations={conversations}
                  connection={connection}
                  setDisplaySidebar={setDisplaySidebar}
                  screenWidth={screenWidth}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className={classes.chatContainer}>
          <ChatSidebar
            id={id}
            conversations={conversations}
            screenWidth={screenWidth}
          />
          {conversations.length === 0 && !id && !receiverId ? (
            <NoChat
              currentUser={currentUser}
              setDisplayNewChat={setDisplayNewChat}
              setDisplayNewGroupChat={setDisplayNewGroupChat}
              screenWidth={screenWidth}
            />
          ) : (
            <ChatBox
              id={id}
              receiverId={receiverId}
              currentUserId={currentUser.Id}
              conversations={conversations}
              connection={connection}
              screenWidth={screenWidth}
            />
          )}
        </div>
      )}
      {displayNewChat && (
        <Popup
          title="Cuộc trò chuyện mới"
          onClose={() => setDisplayNewChat(false)}
        >
          <NewChat
            conversations={conversations}
            onClosePopup={() => setDisplayNewChat(false)}
          />
        </Popup>
      )}

      {displayNewGroupChat && (
        <Popup
          title="Nhóm trò chuyện mới"
          onClose={() => setDisplayNewGroupChat(false)}
        >
          <NewGroupChat
            type={GroupChatActionEnum.CREATE_GROUP_CHAT}
            onClosePopup={() => setDisplayNewGroupChat(false)}
          />
        </Popup>
      )}
    </>
  );
};

export default ChatScreen;
