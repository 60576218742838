import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import Header from "../../../../components/Header/Header";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ProjectListScreen.module.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import Popup from "../../../../components/Popup/Popup";
import NewProject from "../../components/NewProject/NewProject";
import {
  filterContainUsers,
  getAvatarSrc,
} from "../../../chat/helpers/chat.helpers";
import {
  filterByName,
  projectCreatedDate,
} from "../../helpers/project.helpers";
import { getProjects, searchProjects } from "../../redux/project.slice";
import { useHistory } from "react-router-dom";
import { PathsEnum } from "../../../../constants/paths";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";
import Loader from "../../../../components/Loader/Loader";
import { LocalStorage } from "../../../../helpers/localStorage";

const ProjectStatus = [
  {
    id: 1,
    value: "In progress",
    color: "#009EF7",
    bgColor: "#F1FAFF",
  },
  {
    id: 2,
    value: "Pending",
    color: "#7239EA",
    bgColor: "#F8F5FF",
  },
  {
    id: 3,
    value: "Done",
    color: "#50CD89",
    bgColor: "#E8FFF3",
  },
];

const ProjectListScreen: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const users = useAppSelector((state: RootState) => state.chat.users);
  const listProject = useAppSelector(
    (state: RootState) => state.project.listProject
  );
  const isDisplayCreateProject = LocalStorage.getCreateProject();
  const [filterProjects, setFilterProjects] = useState(listProject);
  const [displayNewProject, setDisplayNewProject] = useState(
    isDisplayCreateProject || false
  );
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser.Id && !listProject) {
      dispatch(getProjects(currentUser.Id))
        .unwrap()
        .then((data) => {
          setFilterProjects(data.listProject);
        })
        .catch(() => console.log("Get projects fail"))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.Id]);

  const onClickProject = (projectId: number) => {
    history.push(PathsEnum.PROJECT_DETAIL.replace(":id", `${projectId}`));
  };

  const onChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    if (!event.target.value) {
      setFilterProjects(listProject);
    }
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const searchResult = filterByName(listProject, keyword);
    setFilterProjects(searchResult);
  };

  return (
    <>
      <Header />
      {!listProject && loading ? (
        <Loader isFullScreen />
      ) : (
        <div className={classes.projectListScreen}>
          <div className={classes.projectListHeader}>
            <div className={classes.title}>Tất cả dự án</div>
            <div className={classes.headerRight}>
              <div className={classes.searchWrapper}>
                <form className={classes.form} onSubmit={handleSearch}>
                  <input
                    type="text"
                    value={keyword}
                    placeholder="Tìm kiếm"
                    onChange={onChangeKeyword}
                    className={classes.searchInput}
                    autoComplete="off"
                  />
                  <button type="submit" className={classes.searchBtn}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="lg"
                      className={classes.searchIcon}
                    />
                  </button>
                </form>
              </div>
              <button
                className={classes.createBtn}
                onClick={() => setDisplayNewProject(true)}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" /> Tạo
              </button>
            </div>
          </div>
          <div className={classes.projectListContainer}>
            {filterProjects?.length > 0 ? (
              <>
                {filterProjects?.map((project) => {
                  const members = filterContainUsers(users, project?.MemberIds);
                  return (
                    <div
                      key={project.Id}
                      className={classes.projectItem}
                      onClick={() => onClickProject(project.Id)}
                    >
                      <div className={classes.projectTop}>
                        <img
                          alt="avt"
                          src={getAvatarSrc(project?.Avatar)}
                          className={classes.avatar}
                        />
                      </div>
                      <div className={classes.projectCenter}>
                        <div className={classes.projectName}>
                          {project?.Name}
                        </div>
                        <div className={classes.description}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: project?.Description,
                            }}
                          ></span>
                        </div>

                        <div>
                          <div className={classes.createdDate}>
                            Ngày bắt đầu
                          </div>
                          <div className={classes.date}>
                            {projectCreatedDate(project.CreatedDate)}
                          </div>
                        </div>
                        <div
                          style={{
                            width: `${project.Process}%`,
                          }}
                          className={classes.process}
                        ></div>
                      </div>
                      <div className={classes.projectBottom}>
                        <div className={classes.members}>
                          {members.map(
                            (member, id) =>
                              id < 4 && (
                                <div key={member.Id}>
                                  <img
                                    alt=""
                                    className={classes.avatarMember}
                                    src={getAvatarSrc(member?.Avatar)}
                                  />
                                </div>
                              )
                          )}
                          {members.length > 4 ? (
                            <div className={classes.avatarMember}>
                              +{members.length - 4}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>Không có dự án nào.</>
            )}
          </div>
        </div>
      )}
      {displayNewProject && (
        <Popup
          title="Tạo dự án mới"
          height="80vh"
          width="600px"
          onClose={() => {
            setDisplayNewProject(false);
            LocalStorage.removeCreateProject();
          }}
        >
          <NewProject
            onClose={() => {
              setDisplayNewProject(false);
              LocalStorage.removeCreateProject();
            }}
          />
        </Popup>
      )}
    </>
  );
};

export default ProjectListScreen;
