import { FC, useState } from "react";
import { Formik, Form } from "formik";
import { newFeatureSchema, onEnterForm } from "../../helpers/project.helpers";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import InputField from "../../../../components/Form/InputField/InputField";
import { createFeature, editFeature } from "../../redux/project.slice";
import { RootState } from "../../../../redux/store";
import { FeatureType, ProjectsType } from "../../types/project.types";
import { ApiMessageType } from "../../../auth/types/auth.types";
import CancelButton from "../../../../components/Button/CancelButton/CancelButton";
import classes from "./AddOrEditFeature.module.css";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import { ActionEnum } from "../../../../constants/enum";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";

interface AddOrEditFeatureProps {
  onClosePopup: () => void;
  projectParent?: ProjectsType;
  choosenFeature?: FeatureType;
  type: number;
  setApiMessage?: (message: ApiMessageType) => void;
}

const AddOrEditFeature: FC<AddOrEditFeatureProps> = ({
  type,
  projectParent,
  choosenFeature,
  onClosePopup,
  setApiMessage,
}) => {
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const project = useAppSelector((state: RootState) => state.project.project);
  const dispatch = useAppDispatch();
  const [description, setDescription] = useState(
    type === ActionEnum.ADD ? "" : choosenFeature?.Description
  );

  const initValues = {
    name: type === ActionEnum.ADD ? "" : choosenFeature?.Name,
  };

  const handleSubmit = (values: { name: string; description: string }) => {
    if (type === ActionEnum.ADD) {
      const requestData = {
        CreatedBy: currentUser.Id,
        ProjectId: projectParent?.Id || project.Id,
        ParentId: choosenFeature?.Id | 0,
        Name: values.name,
        Description: description,
        ChangedBy: currentUser.Id,
      };
      dispatch(createFeature(requestData))
        .unwrap()
        .then((data) => {
          setApiMessage(data.apiMessage);
          onClosePopup();
        })
        .catch((error) => console.log(error));
    } else {
      const requestData = {
        Id: choosenFeature?.Id,
        Name: values.name,
        Description: description,
        ChangedBy: currentUser.Id,
      };
      dispatch(editFeature(requestData))
        .unwrap()
        .then((data) => {
          onClosePopup();
          setApiMessage(data.apiMessage);
        })
        .catch(() => console.log("Edit feature fail"));
    }
  };

  return (
    <div className={classes.form}>
      <Formik
        initialValues={initValues}
        validationSchema={newFeatureSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form onKeyDown={onEnterForm}>
            {projectParent && (
              <>
                <div className={classes.label}>Dự án</div>
                <div className={classes.projectInfo}>
                  <img
                    alt="avt"
                    src={getAvatarSrc(projectParent?.Avatar)}
                    className={classes.avatar}
                  />
                  <span>{projectParent.Name}</span>
                </div>
              </>
            )}
            <InputField
              type="text"
              name="name"
              placeholder="Tên chức năng"
              label="Tên chức năng"
              required
              value={values?.name}
            />
            {/* <TextareaField
              rows={3}
              name="description"
              placeholder="Mô tả"
              label="Mô tả"
              value={values?.description}
            /> */}
            <TextEditor
              text={description}
              setText={setDescription}
              placeholder="Mô tả"
              label="Mô tả"
            />
            <div className={classes.buttonGroup}>
              <CancelButton label="Hủy" onClick={onClosePopup} />
              <InputField
                type="submit"
                name="submit"
                value={type === ActionEnum.ADD ? "Tạo" : "Lưu"}
                width="120px"
                disabled={!values.name.trim() || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditFeature;
