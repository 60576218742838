import { FC } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import classes from "./Loader.module.css";

type LoaderProps = {
  isFullScreen?: boolean;
};

const Loader: FC<LoaderProps> = ({ isFullScreen = false }) => {
  return (
    <div
      className={clsx({
        [classes.fullScreen]: isFullScreen,
        [classes.insideElement]: !isFullScreen,
      })}
    >
      <FontAwesomeIcon
        icon={faCircleNotch}
        size="2x"
        className={classes.iconItem}
      />
    </div>
  );
};

export default Loader;
