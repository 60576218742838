import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Header from "../../../../components/Header/Header";
import { PathsEnum } from "../../../../constants/paths";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import ProjectSidebar from "../../components/ProjectSidebar/ProjectSidebar";
import {
  deleteTask,
  deleteTaskDocument,
  downloadFile,
  editTask,
  getFeature,
  getProject,
  getTask,
} from "../../redux/project.slice";
import classes from "./TaskScreen.module.css";
import documentClasses from "../../components/ProjectDocumentList/ProjectDocumentList.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrashAlt,
  faPlus,
  faPaperclip,
  faLink,
  faShareAlt,
  faCheck,
  faDownload,
  faPlayCircle,
  faFile,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import clsx from "clsx";
import { projectCreatedDate } from "../../helpers/project.helpers";
import Popup from "../../../../components/Popup/Popup";
import ConfirmPopup from "../../../../components/ConfirmPopup/ConfirmPopup";
import { ApiMessageType } from "../../../auth/types/auth.types";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { ProjectMessage } from "../../../../constants/api.message";
import AddOrEditTask, {
  TaskStatus,
} from "../../components/AddOrEditTask/AddOrEditTask";
import AlertPopup from "../../../../components/AlertPopup/AlertPopup";
import { getFileTypes } from "../../../../helpers/helpers";
import { ProjectDocumentType } from "../../types/project.types";
import FileListView from "../../../../components/FileListView/FileListView";
import { AttachmentType } from "../../../chat/types/chat.types";
import { ActionEnum, FileTypeEnum } from "../../../../constants/enum";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";
import Loader from "../../../../components/Loader/Loader";
import EditHistory from "../../components/EditHistory/EditHistory";

const TaskScreen: FC = () => {
  const { id, featureId, taskId } = useParams<{
    id: string;
    featureId: string;
    taskId: string;
  }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }

  const project = useAppSelector((state: RootState) => state.project.project);
  const feature = useAppSelector((state: RootState) => state.project.feature);
  const task = useAppSelector((state: RootState) => state.project.task);
  const users = useAppSelector((state: RootState) => state.chat.users);

  const [displayEditHistory, setDisplayEditHistory] = useState(false);
  const [displayEditTask, setDisplayEditTask] = useState(false);
  const [displayDeleteTask, setDisplayDeleteTask] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const inputDocumentRef = useRef<HTMLInputElement>();
  const [hasLargeFile, setHasLargeFile] = useState(false);

  let fileTypes = getFileTypes(task?.Files);
  const [viewDocument, setViewDocument] = useState<
    AttachmentType | ProjectDocumentType
  >(null);
  const [viewType, setViewType] = useState(0);
  const [deleteDocumentId, setDeleteDocumentId] = useState(null);

  useEffect(() => {
    dispatch(getTask(Number(taskId)))
      .unwrap()
      .then((data) => {
        if (!feature) {
          dispatch(getFeature(data.task.FeatureId));
        }
      });
    if (!project || project?.Id !== Number(id)) {
      dispatch(getProject(id));
    }
    if (!feature || feature?.Id !== Number(featureId)) {
      dispatch(getFeature(Number(featureId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const createdUser = users.find((user) => user.Id === task?.CreatedBy);
  const assignee = users.find((user) => user.Id === task?.Assignee);
  const status = TaskStatus.find((status) => status.id === task?.Status);

  const handleDeleteTask = () => {
    dispatch(
      deleteTask({
        Id: task.Id,
      })
    )
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
        if (data.apiMessage.Type === "success") {
          setDisplayDeleteTask(false);
          history.push(
            PathsEnum.FEATURE.replace(":id", id).replace(
              ":featureId",
              `${feature?.Id}`
            )
          );
        }
      })
      .catch(() => console.log("Delete task fail"));
  };

  const handleAddFile = (file: File, size: string) => {
    const formData = new FormData();
    formData.append("ProjectId", id);
    formData.append("FeatureId", featureId);
    formData.append("Id", taskId);
    formData.append("Files", file);
    formData.append("SizeOfFiles", size);
    dispatch(editTask(formData))
      .unwrap()
      .then((data) => setApiMessage(data.apiMessage))
      .catch(() => console.log("Add document fail"));
  };

  const handleChangeDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    let element: any;
    Object.keys(fileList).forEach((key) => {
      if (fileList[key].size <= 100 * 1024 * 1024) {
        const url = URL.createObjectURL(fileList[key]);
        if (fileList[key].type.includes("image")) {
          element = document.createElement("img");
          element.src = url;
          element.onload = (e: any) => {
            const size = `${e.target.naturalWidth}x${e.target.naturalHeight}`;
            handleAddFile(fileList[key], size);
          };
        } else if (fileList[key].type.includes("video")) {
          element = document.createElement("video");
          element.src = url;
          element.onloadedmetadata = (e: any) => {
            const size = `${e.target.videoWidth}x${e.target.videoHeight}`;
            handleAddFile(fileList[key], size);
          };
        } else {
          handleAddFile(fileList[key], "0x0");
        }
      } else {
        setHasLargeFile(true);
      }
    });
  };

  const handleDownloadFile = (path: string, name: string) => {
    dispatch(downloadFile(path))
      .unwrap()
      .then((response) => {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      });
  };

  const handleDeleteDocument = () => {
    setDeleteDocumentId(null);
    dispatch(
      deleteTaskDocument({
        Id: deleteDocumentId,
      })
    )
      .unwrap()
      .then((data) => setApiMessage(data.apiMessage))
      .catch(() => console.log("Delete document fail"));
  };

  return (
    <>
      <Header />
      <div className={classes.taskScreen}>
        <div className={classes.task}>
          <Breadcrumbs
            linkList={[
              ["Tất cả dự án", PathsEnum.PROJECTS],
              [project?.Name, PathsEnum.PROJECT_DETAIL.replace(":id", id)],
              [
                feature?.Name,
                PathsEnum.FEATURE.replace(":id", id).replace(
                  ":featureId",
                  `${feature?.Id}`
                ),
              ],
              [
                task?.Name,
                PathsEnum.TASK.replace(":id", id)
                  .replace(":featureId", `${feature?.Id}`)
                  .replace(":taskId", taskId),
              ],
            ]}
          />
          <div className={classes.taskHeader}>
            <div className={classes.title}>{task?.Name}</div>
            <div className={classes.actionGroup}>
              {/* <button
                className={classes.createBtn}
                onClick={() => setDisplayCreateSubTask(true)}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" /> Sub-task
              </button> */}
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                className={clsx(classes.iconItem, classes.iconHistory)}
                onClick={() => {
                  setDisplayEditHistory(true);
                }}
              />
              <FontAwesomeIcon
                icon={faLink}
                className={clsx(classes.iconItem, classes.iconLink)}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setApiMessage({
                    Type: "success",
                    Code: "copy001",
                  });
                }}
              />
              {/* <FontAwesomeIcon icon={faShareAlt} className={classes.iconItem} /> */}
              <FontAwesomeIcon
                icon={faPaperclip}
                className={clsx(classes.iconItem, classes.iconDocument)}
                onClick={() => inputDocumentRef.current.click()}
              />
              <FontAwesomeIcon
                icon={faPen}
                className={clsx(classes.iconEdit, classes.iconItem)}
                onClick={() => setDisplayEditTask(true)}
              />
              <FontAwesomeIcon
                icon={faTrashAlt}
                className={clsx(classes.iconDelete, classes.iconItem)}
                onClick={() => setDisplayDeleteTask(true)}
              />
              <div className={classes.history}>Lịch sử chỉnh sửa</div>
              <div className={classes.link}>Sao chép liên kết</div>
              <div className={classes.document}>Thêm tệp tin</div>
              <div className={classes.edit}>Chỉnh sửa</div>
              <div className={classes.delete}>Xóa</div>
            </div>
          </div>
          <div className={classes.taskBody}>
            <div className={classes.taskBodyLeft}>
              <div className={classes.description}>
                <div className={classes.label}>Mô tả</div>
                <div className={classes.content}>
                  {task?.Description === "<p><br></p>" || !task?.Description ? (
                    "Chưa có mô tả."
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: task?.Description,
                      }}
                    ></span>
                  )}
                </div>
              </div>
              {/* <div>
                <div className={classes.label}>Sub-task</div>
                <div className={classes.content}>
                  <TaskTable taskList={[]} />
                </div>
              </div> */}
              {task?.Files?.length > 0 && (
                <>
                  <div className={classes.label}>Tài liệu</div>
                  {/* <DocumentList
                    type={2}
                    list={task?.Files}
                    setApiMessage={setApiMessage}
                  /> */}
                  <div className={documentClasses.documentContainer}>
                    {task.Files?.length > 0 &&
                      fileTypes.map((fileType, id) => (
                        <Fragment key={task.Files[id].Path}>
                          <div
                            className={documentClasses.documentItem}
                            onClick={() => {
                              setViewDocument(task.Files[id]);
                              setViewType(fileType);
                            }}
                          >
                            <div className={documentClasses.actionGroup}>
                              <div
                                className={documentClasses.actionItem}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDownloadFile(
                                    task.Files[id]?.Path,
                                    task.Files[id].Name
                                  );
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  size="lg"
                                  className={documentClasses.iconItem}
                                />
                              </div>
                              <div
                                className={documentClasses.actionItem}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteDocumentId(task.Files[id]?.Id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  size="lg"
                                  className={documentClasses.iconItem}
                                />
                              </div>
                            </div>
                            {fileType === FileTypeEnum.IMAGE ? (
                              <div className={documentClasses.thumbnail}>
                                <img
                                  alt="img"
                                  src={getAvatarSrc(task.Files[id]?.Path)}
                                  className={documentClasses.image}
                                />
                              </div>
                            ) : fileType === FileTypeEnum.VIDEO ? (
                              <div className={documentClasses.thumbnail}>
                                <video
                                  src={getAvatarSrc(task.Files[id].Path)}
                                  className={documentClasses.image}
                                ></video>
                                <FontAwesomeIcon
                                  icon={faPlayCircle}
                                  className={documentClasses.playVideoIcon}
                                  size="2x"
                                />
                              </div>
                            ) : (
                              <div className={documentClasses.thumbnail}>
                                <FontAwesomeIcon icon={faFile} size="3x" />
                              </div>
                            )}
                            <div className={documentClasses.documentDetail}>
                              <div className={documentClasses.name}>
                                {task.Files[id]?.Name}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                </>
              )}
            </div>
            <div className={classes.taskBodyRight}>
              <div className={classes.header}>
                <div
                  className={classes.status}
                  style={{
                    backgroundColor: status?.bgColor,
                  }}
                >
                  {status?.id === 5 && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {status?.value}
                </div>
                <div
                  className={classes.process}
                  style={{
                    backgroundColor: status?.bgColor,
                  }}
                >
                  {task?.Process}%
                </div>
              </div>
              <div className={classes.wrapper}>
                <div className={classes.wrapperItem}>
                  <div className={classes.label}>Người thực hiện</div>
                  <div className={clsx(classes.content, classes.editContent)}>
                    <div className={classes.assignee}>
                      <img
                        alt="avt"
                        src={getAvatarSrc(assignee?.Avatar)}
                        className={classes.avatar}
                      />
                      <div className={classes.username}>
                        {assignee?.Fullname}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.wrapperItem}>
                  <div className={classes.label}>Người giao</div>
                  <div className={classes.content}>
                    <div className={classes.assignee}>
                      <img
                        alt="avt"
                        src={getAvatarSrc(createdUser?.Avatar)}
                        className={classes.avatar}
                      />
                      <div className={classes.username}>
                        {createdUser?.Fullname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.wrapper}>
                <div className={classes.wrapperItem}>
                  <div className={classes.label}>Ngày tạo</div>
                  <div className={classes.content}>
                    {projectCreatedDate(task?.CreatedDate)}
                  </div>
                </div>
                <div className={classes.wrapperItem}>
                  <div className={classes.label}>Hạn thực hiện</div>
                  <div className={classes.content}>
                    {projectCreatedDate(task?.Deadline)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={inputDocumentRef}
        hidden
        multiple
        onChange={handleChangeDocument}
      />
      {/* {displayCreateSubTask && (
        <Popup
          title="Tạo task mới"
          width="600px"
          onClose={() => setDisplayCreateSubTask(false)}
        >
          <AddOrEditTask
            type={1}
            onClosePopup={() => setDisplayCreateSubTask(false)}
          />
        </Popup>
      )} */}
      {displayEditTask && (
        <Popup
          title="Chỉnh sửa"
          width="600px"
          onClose={() => setDisplayEditTask(false)}
        >
          <AddOrEditTask
            type={ActionEnum.EDIT}
            currentTask={task}
            hasChooseParent={false}
            onClosePopup={() => setDisplayEditTask(false)}
            setApiMessage={setApiMessage}
            hasLargeFile={hasLargeFile}
            setHasLargeFile={setHasLargeFile}
          />
        </Popup>
      )}
      {displayDeleteTask && (
        <ConfirmPopup
          title={`Chắc chắn muốn xóa ${task.Name}?`}
          onClose={() => setDisplayDeleteTask(false)}
          onOK={handleDeleteTask}
        />
      )}
      {apiMessage && (
        <NotifyAlert
          type={apiMessage.Type}
          message={ProjectMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
      {hasLargeFile && (
        <AlertPopup
          title="Không thể gửi tệp"
          message="File bạn chọn quá lớn. Vui lòng chọn file không quá 100MB."
          onClose={() => setHasLargeFile(false)}
        />
      )}
      {deleteDocumentId && (
        <ConfirmPopup
          title="Chắc chắn muốn xóa tài liệu này?"
          onClose={() => {
            setDeleteDocumentId(null);
          }}
          onOK={() => {
            handleDeleteDocument();
          }}
        />
      )}
      {displayEditHistory && (
        <Popup
          title="Lịch sử chỉnh sửa"
          width="600px"
          onClose={() => setDisplayEditHistory(false)}
        >
          <EditHistory taskId={taskId} users={users} />
        </Popup>
      )}
      <FileListView
        viewType={viewType}
        setViewType={setViewType}
        currentFile={viewDocument}
        setCurrentFile={setViewDocument}
        handleDownloadFile={handleDownloadFile}
      />
    </>
  );
};

export default TaskScreen;
