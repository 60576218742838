import { FC, useState } from "react";
import { Formik, Form } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import classes from "./NewGroupChat.module.css";
import {
  newGroupChatInitValues,
  newGroupChatSchema,
} from "../../helpers/chat.form.helpers";
import InputField from "../../../../components/Form/InputField/InputField";
import SearchUser from "../SearchUser/SearchUser";
import { CreateGroupChatRequest } from "../../types/chat.types";
import { createGroupChat, getConversations } from "../../redux/chat.slice";
import { useHistory } from "react-router-dom";
import { filterContainUsers, getGroupName } from "../../helpers/chat.helpers";
import ChooseMemberGroup from "../ChooseMemberGroup/ChooseMemberGroup";
import { PathsEnum } from "../../../../constants/paths";
import CancelButton from "../../../../components/Button/CancelButton/CancelButton";
import {
  ConversationTypeEnum,
  GroupChatActionEnum,
} from "../../../../constants/enum";
import { onEnterForm } from "../../../project/helpers/project.helpers";

interface NewGroupChatProps {
  //type=1: create new group, type=2 create new group from single chat
  type: number;
  onClosePopup: () => void;
  receiverId?: number;
}

const NewGroupChat: FC<NewGroupChatProps> = ({
  type,
  onClosePopup,
  receiverId,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const users = useAppSelector((state: RootState) =>
    state.chat.users.filter(
      (user) => user.Id !== currentUser.Id && user.Id !== receiverId
    )
  );

  const [usersFilter, setUsersFilter] = useState(users);
  const [keyword, setKeyword] = useState("");

  const handleCreateNewGroupChat = (
    values: Pick<CreateGroupChatRequest, "Name" | "MemberGroup">
  ) => {
    const newGroupMember = filterContainUsers(users, values.MemberGroup);
    const groupName = getGroupName(currentUser.Fullname, newGroupMember);
    dispatch(
      createGroupChat({
        CreatedBy: `${currentUser.Id}`,
        Name: values.Name || groupName,
        MemberGroup: [...values.MemberGroup, `${receiverId}`],
        Type: ConversationTypeEnum.GROUP_CHAT,
      })
    )
      .unwrap()
      .then((data) => {
        onClosePopup();
        dispatch(getConversations(currentUser.Id));
        history.push(
          PathsEnum.EXIST_CONVERSATION.replace(":id", `${data.ConversationId}`)
        );
      })
      .catch(() => {
        console.log("Create conversation fail");
      });
  };

  return (
    <div className={classes.container}>
      <Formik
        initialValues={newGroupChatInitValues}
        validationSchema={newGroupChatSchema}
        onSubmit={handleCreateNewGroupChat}
        onKeyDown={onEnterForm}
      >
        {({ values }) => (
          <Form onKeyDown={onEnterForm}>
            {type === GroupChatActionEnum.CREATE_GROUP_CHAT && (
              <div className={classes.containerHeader}>
                <InputField type="text" name="Name" placeholder="Tên nhóm" />
                <div className={classes.text}>Thêm thành viên</div>
              </div>
            )}
            <SearchUser
              keyword={keyword}
              setKeyword={setKeyword}
              users={users}
              setUsersFilter={setUsersFilter}
            />
            {usersFilter.length === 0 ? (
              <div className={classes.noResult}>Không có kết quả.</div>
            ) : (
              <div
                role="group"
                style={{
                  height:
                    type === GroupChatActionEnum.CREATE_GROUP_CHAT
                      ? "34vh"
                      : "43vh",
                }}
                className={classes.checkboxGroup}
              >
                <ChooseMemberGroup
                  name="MemberGroup"
                  users={usersFilter}
                  values={values.MemberGroup}
                />
              </div>
            )}
            <div className={classes.buttonGroup}>
              <CancelButton label="Hủy" onClick={onClosePopup} />
              <InputField
                type="submit"
                name="submit"
                width="120px"
                value={
                  type === GroupChatActionEnum.CREATE_GROUP_CHAT
                    ? "Tạo"
                    : "Thêm"
                }
                disabled={usersFilter.length === 0}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewGroupChat;
