import { FC } from "react";
import classes from "./ConfirmPopup.module.css";

interface ConfirmPopupProps {
  title: string;
  onOK: () => void;
  onClose: () => void;
}

const ConfirmPopup: FC<ConfirmPopupProps> = ({ title, onOK, onClose }) => {
  return (
    <div className={classes.popup}>
      <div className={classes.popupContainer}>
        <div className={classes.header}>
          {title && <span className={classes.popupTitle}>{title}</span>}
        </div>
        <div className={classes.actionContainer}>
          <div onClick={onClose}>Đóng</div>
          <div onClick={onOK} style={{ color: "var(--bgMainColor)" }}>
            OK
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
