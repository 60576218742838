import { profileApi } from "./../api/profile.api";
import {
  ProfileState,
  UserState,
  ChangePasswordRequest,
  UserProfileType,
  GetCurrentUserWithTokenRequest,
} from "./../types/profile.types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState: ProfileState = {
  currentUser: {
    Id: 0,
    Avatar: "",
    Fullname: "",
    Email: "",
    Phone: "",
  },
  userProfile: null,
  apiMessage: null,
};

export const getCurrentUser = createAsyncThunk<UserState, string>(
  "auth/getCurrentUser",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await profileApi.getCurrentUserApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurrentUserWithToken = createAsyncThunk<
  UserState,
  GetCurrentUserWithTokenRequest
>("auth/getCurrentUserWithToken", async (requestData, { rejectWithValue }) => {
  try {
    const response = await profileApi.getCurrentUserWithTokenApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserProfile = createAsyncThunk<UserProfileType, string>(
  "auth/getUserProfile",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await profileApi.getUserProfileApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk<
  ProfileState,
  ChangePasswordRequest
>("auth/changePassword", async (requestData, { rejectWithValue }) => {
  try {
    const response = await profileApi.changePasswordApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeProfile = createAsyncThunk<any, FormData>(
  "auth/changeProfile",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await profileApi.changeProfileApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = { ...action.payload };
    });
    builder.addCase(getCurrentUserWithToken.fulfilled, (state, action) => {
      state.currentUser = { ...action.payload };
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.currentUser = { ...action.payload };
    });
    builder.addCase(changeProfile.fulfilled, (state, action) => {
      state.currentUser = action.payload.userProfile;
    });
  },
});

export const profileReducer = profileSlice.reducer;
