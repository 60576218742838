import { FC, useRef, useState } from "react";
import classes from "./ProfileInfomation.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "./../../../../hooks/hooks";
import { changeProfile } from "./../../redux/profile.slice";
import { ApiMessageType } from "../../../auth/types/auth.types";
import { Form, Formik } from "formik";
import { profileSchema } from "../../helpers/profile.helpers";
import InputField from "../../../../components/Form/InputField/InputField";
import { getAvatarSrcNoHost } from "../../../chat/helpers/chat.helpers";
import { UserState } from "../../types/profile.types";
import clsx from "clsx";

interface ProfileInfomationProps {
  currentUser: UserState;
  setApiMessage: (apiMessate: ApiMessageType) => void;
  screenWidth?: number;
}

const ProfileInfomation: FC<ProfileInfomationProps> = ({
  currentUser,
  setApiMessage,
  screenWidth,
}) => {
  const dispatch = useAppDispatch();
  const inputFileRef = useRef<HTMLInputElement>();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const formData = new FormData();

  const profileInitValues = {
    fullname: currentUser?.Fullname,
    phone: currentUser?.Phone,
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files[0]);
    setFileUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleChangeProfile = (values: any) => {
    formData.append("Id", `${currentUser.Id}`);
    if (file?.name) {
      formData.append("Avatar", file);
    }
    formData.append("Fullname", values.fullname);
    formData.append("Phone", values.phone);
    dispatch(changeProfile(formData))
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
      });
  };

  return (
    <>
      <div className={classes.title}>Thông tin cá nhân</div>
      <Formik
        initialValues={profileInitValues}
        validationSchema={profileSchema}
        onSubmit={handleChangeProfile}
      >
        {({ values, isValid }) => (
          <Form>
            <div className={classes.profileContainer}>
              <div className={classes.profileLeft}>
                <div className={classes.profileItem}>
                  <div className={classes.label}>Email</div>
                  <div className={clsx(classes.content, classes.email)}>
                    {currentUser.Email}
                  </div>
                </div>
                <div className={classes.profileItem}>
                  <InputField
                    type="text"
                    name="fullname"
                    placeholder="Nhập tên"
                    label="Họ và tên"
                    value={values.fullname}
                    required
                    width={screenWidth < 992 ? "100%" : "90%"}
                  />
                </div>
                <div className={classes.profileItem}>
                  <InputField
                    type="text"
                    name="phone"
                    value={values.phone}
                    placeholder="Nhập số điện thoại"
                    label="Số điện thoại"
                    width={screenWidth < 992 ? "100%" : "90%"}
                  />
                </div>
              </div>
              <div className={classes.profileRight}>
                <div className={classes.label}>Ảnh đại diện</div>
                <div className={classes.avatarWrapper}>
                  <div className={classes.avatar}>
                    <img
                      alt="avt"
                      src={getAvatarSrcNoHost(fileUrl, currentUser.Avatar)}
                      className={classes.image}
                    />
                  </div>
                  <div
                    className={classes.editAvatarIcon}
                    onClick={() => inputFileRef?.current?.click()}
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      className={classes.editIcon}
                    />
                  </div>
                </div>
              </div>
            </div>

            <input
              type="file"
              id="file"
              name="Avatar"
              accept="image/*"
              hidden
              ref={inputFileRef}
              onChange={onChangeFile}
            />
            <div style={{ textAlign: "center" }}>
              <InputField
                type="submit"
                name="submit"
                value="Lưu"
                disabled={!isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProfileInfomation;
