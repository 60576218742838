import { FC, memo, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ROUTE_LIST } from "./routes.config";
import Loader from "../components/Loader/Loader";
import DefaultLayout from "../layouts/DefaultLayout/DefaultLayout";

const Routes: FC = () => {
  return (
    <Suspense fallback={<Loader isFullScreen />}>
      <Switch>
        <DefaultLayout>
          {ROUTE_LIST.map((route) => {
            return (
              <Route
                key={route.id}
                exact
                path={route.path}
                component={route.component}
              />
            );
          })}
        </DefaultLayout>
      </Switch>
    </Suspense>
  );
};

export default memo(Routes);
