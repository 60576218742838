import { chatApi } from "./../api/chat.api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ChatState,
  ConversationType,
  GetMessagesRequest,
  SendPrivateMessageRequest,
  SendGroupMessageRequest,
  DeleteMessageRequest,
  MessageType,
  CreateConversationRequest,
  NewConversationType,
  CreateGroupChatRequest,
  DeleteConversationRequest,
  AddMemberRequest,
  MarkImportantRequest,
  AttachmentType,
  SearchMessagesRequest,
  GetFilesRequest,
  ChangeIsReadRequest,
  GetConversationsWithTokenRequest,
  GetFileRequest,
  DeleteMemberRequest,
} from "./../types/chat.types";
import { conversationsSort, sortMessages } from "./../helpers/chat.helpers";
import { UserState } from "../../profile/types/profile.types";
import { MessageTypeEnum } from "../../../constants/enum";

const initialState: ChatState = {
  id: 0,
  newConversation: null,
  conversations: [],
  messages: [],
  importants: [],
  files: [],
  users: [],
  loading: false,
};

export const getConversations = createAsyncThunk<ConversationType[], number>(
  "chat/getConversations",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getConversationsApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getConversationsWithToken = createAsyncThunk<
  ConversationType[],
  GetConversationsWithTokenRequest
>(
  "chat/getConversationsWithToken",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getConversationsWithTokenApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMessages = createAsyncThunk<MessageType[], GetMessagesRequest>(
  "chat/getMessages",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getMessagesApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreMessages = createAsyncThunk<
  MessageType[],
  GetMessagesRequest
>("chat/getMoreMessages", async (requestData, { rejectWithValue }) => {
  try {
    requestData.isMore = true;
    const response = await chatApi.getMessagesApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendPrivateMessage = createAsyncThunk<
  any,
  SendPrivateMessageRequest
>("chat/sendPrivateMessage", async (requestData, { rejectWithValue }) => {
  try {
    await chatApi.sendPrivateMessageApi(requestData);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendGroupMessage = createAsyncThunk<any, SendGroupMessageRequest>(
  "chat/sendGroupMessage",
  async (requestData, { rejectWithValue }) => {
    try {
      await chatApi.sendGroupMessageApi(requestData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMessage = createAsyncThunk<any, DeleteMessageRequest>(
  "chat/deleteMessage",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.deleteMessageApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsers = createAsyncThunk<UserState[], null>(
  "chat/getUsersToChat",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getUsersApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createConversation = createAsyncThunk<
  NewConversationType,
  CreateConversationRequest
>("chat/createConversation", async (requestData, { rejectWithValue }) => {
  try {
    const response = await chatApi.createConversationApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createGroupChat = createAsyncThunk<
  NewConversationType,
  CreateGroupChatRequest
>("chat/createGroupChat", async (requestData, { rejectWithValue }) => {
  try {
    const response = await chatApi.createGroupChatApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteConversation = createAsyncThunk<
  any,
  DeleteConversationRequest
>("chat/deleteConversation", async (requestData, { rejectWithValue }) => {
  try {
    await chatApi.deleteConversationApi(requestData);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addMemberGroup = createAsyncThunk<any, AddMemberRequest>(
  "chat/addMemberGroup",
  async (requestData, { rejectWithValue }) => {
    try {
      await chatApi.addMemberGroupApi(requestData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMemberGroup = createAsyncThunk<any, DeleteMemberRequest>(
  "chat/deleteMemberGroup",
  async (requestData, { rejectWithValue }) => {
    try {
      await chatApi.deleteMemberApi(requestData);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getImportantMessages = createAsyncThunk<
  MessageType[],
  GetMessagesRequest
>("chat/getImportantMessages", async (requestData, { rejectWithValue }) => {
  try {
    const response = await chatApi.getImportantApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const markImportantMessage = createAsyncThunk<any, MarkImportantRequest>(
  "chat/markImportantMessage",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.markImportantApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendFileMessage = createAsyncThunk<any, FormData>(
  "chat/sendFileMessage",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.sendFileMessageApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFiles = createAsyncThunk<AttachmentType[], GetFilesRequest>(
  "chat/getFiles",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getFilesApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreFiles = createAsyncThunk<AttachmentType[], GetFilesRequest>(
  "chat/getMoreFiles",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getFilesApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFile = createAsyncThunk<any, GetFileRequest>(
  "chat/getFile",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.getFileApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchMessages = createAsyncThunk<
  MessageType[],
  SearchMessagesRequest
>("chat/searchMessages", async (requestData, { rejectWithValue }) => {
  try {
    const response = await chatApi.searchMessagesApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changeGroupInformation = createAsyncThunk<any, FormData>(
  "chat/changeGroupInformation",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.changeGroupInfoApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeIsRead = createAsyncThunk<any, ChangeIsReadRequest>(
  "chat/changeIsRead",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await chatApi.changeIsReadApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addNewMessage: (state, action) => {
      const index = state.conversations
        .map((conversation) => conversation.Id)
        .indexOf(action.payload.ConversationId);
      if (state.conversations[index]) {
        if (
          action.payload.Type === MessageTypeEnum.READ &&
          action.payload.SenderId
        ) {
          state.conversations[index].ReadBy.push(action.payload.SenderId);
        } else {
          state.messages.unshift(action.payload);
          if (action.payload?.Message) {
            state.conversations[index].LastMessage = action.payload?.Message;
          }
          if (action.payload?.CreatedDate) {
            state.conversations[index].LastTime = action.payload?.CreatedDate;
          }
          state.conversations[index].ReadBy = [action.payload.SenderId];
        }
      }
      state.conversations = conversationsSort(state.conversations);
    },
    updateMessages: (state, action) => {
      state.messages = action.payload;
    },
    updateConversationsDueDelete: (state, action) => {
      state.conversations = state.conversations.filter(
        (con) => con.Id !== action.payload
      );
    },
    deleteMessageSignalR: (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.Id !== action.payload
      );
    },
    addUserRegistered: (state, action) => {
      state.users.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    //pending
    builder.addCase(getConversations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createConversation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createGroupChat.pending, (state) => {
      state.loading = true;
    });
    //fulfilled

    builder.addCase(getConversations.fulfilled, (state, action) => {
      state.conversations = [...action.payload];
      state.loading = false;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.messages = action.payload;
      state.loading = false;
    });
    builder.addCase(getMoreMessages.fulfilled, (state, action) => {
      state.messages = sortMessages([...state.messages, ...action.payload]);
      state.loading = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = [...action.payload];
      state.loading = false;
    });
    builder.addCase(createConversation.fulfilled, (state, action) => {
      state.newConversation = action.payload;
      state.loading = false;
    });
    builder.addCase(createGroupChat.fulfilled, (state, action) => {
      state.newConversation = action.payload;
      state.loading = false;
    });
    builder.addCase(getImportantMessages.fulfilled, (state, action) => {
      state.importants = action.payload;
      state.loading = false;
    });
    builder.addCase(getFiles.fulfilled, (state, action) => {
      state.files = action.payload;
      state.loading = false;
    });
    builder.addCase(getMoreFiles.fulfilled, (state, action) => {
      state.files = [...state.files, ...action.payload];
      state.loading = false;
    });
    builder.addCase(markImportantMessage.fulfilled, (state, action) => {
      const index = state.messages
        .map((message) => message.Id)
        .indexOf(action.payload.Id);
      state.messages[index].Important = action.payload.Important;
      if (action.payload.Important) {
        state.importants = [action.payload, ...state.importants];
      } else {
        state.importants = state.importants.filter(
          (message) => message.Id !== action.payload.Id
        );
      }
      state.loading = false;
    });
    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      const newMessages = state.messages.filter(
        (message) => message.Id !== action.payload
      );
      state.messages = newMessages;
      state.loading = false;
    });
    builder.addCase(changeIsRead.fulfilled, (state, action) => {
      const index = state.conversations
        .map((conversation) => conversation.Id)
        .indexOf(action.payload.conversationId);
      state.conversations[index].ReadBy = action.payload.readBy;
    });
  },
});

export const {
  addNewMessage,
  updateMessages,
  updateConversationsDueDelete,
  deleteMessageSignalR,
  addUserRegistered,
} = chatSlice.actions;

export const chatReducer = chatSlice.reducer;
