import React, { FC, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./ImageView.module.css";
import clsx from "clsx";
import { DirectionEnum } from "../../constants/enum";

interface ImageViewProps {
  path: string;
  isAutoHeight?: boolean;
  onClose: () => void;
  hanleGetFile?: (direction: number) => void;
  handleDownloadFile?: () => void;
}

const ImageView: FC<ImageViewProps> = ({
  path,
  isAutoHeight,
  onClose,
  hanleGetFile,
  handleDownloadFile,
}) => {
  return (
    <div className={classes.viewContainer}>
      {handleDownloadFile && (
        <FontAwesomeIcon
          icon={faDownload}
          size="2x"
          className={classes.optionIcon}
          onClick={handleDownloadFile}
        />
      )}

      <FontAwesomeIcon
        icon={faTimes}
        size="2x"
        className={classes.closeIcon}
        onClick={onClose}
      />
      <div
        className={classes.imageContainer}
        style={{
          margin: "auto",
          width: isAutoHeight ? "auto" : "90%",
          height: isAutoHeight ? "90%" : "auto",
        }}
      >
        <img
          alt="img"
          src={path}
          className={classes.image}
          width="100%"
          height="100%"
        />
      </div>
      {hanleGetFile && (
        <>
          {" "}
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="2x"
            className={clsx(classes.arrow, classes.prevArrow)}
            onClick={() => hanleGetFile(DirectionEnum.UP)}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            size="2x"
            className={clsx(classes.arrow, classes.nextArrow)}
            onClick={() => hanleGetFile(DirectionEnum.DOWN)}
          />
        </>
      )}
    </div>
  );
};

export default ImageView;
