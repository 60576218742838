export enum MessageTypeEnum {
  TEXT = 1,
  FILE = 2,
  NOTIF = 3,
  READ = 4,
  TASK_NOTI = 5,
  DELETE_MESSAGE = 6,
  DELETE_GROUP_CHAT = 7,
}

export enum ConversationTypeEnum {
  SINGLE_CHAT = 1,
  GROUP_CHAT = 2,
}

export enum ActionEnum {
  ADD = 1,
  EDIT = 2,
}

export enum GroupChatActionEnum {
  CREATE_GROUP_CHAT = 1,
  CREATE_GROUP_CHAT_FROM_SINGLE_CHAT = 2,
}

export enum DirectionEnum {
  DOWN = 1,
  UP = 2,
}

export enum FileTypeEnum {
  IMAGE = 1,
  VIDEO = 2,
  OTHER = 0,
}

export enum TaskListTypeEnum {
  ALL_TASK = 1,
  TASK_IN_FEATURE = 2,
}
