import { EndpointsEnum } from "../../../constants/endpoints";
import { api } from "./../../../api/api";
import {
  LoginRequest,
  LoginReponse,
  RegisterRequest,
  RegisterReponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  ForgetPasswordRequest,
  ResetPasswordRequest,
  ForgetPasswordResponse,
  ResetPasswordResponse,
} from "./../types/auth.types";

const loginApi = (data: LoginRequest): Promise<LoginReponse> => {
  return api.post(EndpointsEnum.LOGIN, data);
};

const registerApi = (data: RegisterRequest): Promise<RegisterReponse> => {
  return api.post(EndpointsEnum.REGISTER, data);
};

const refreshTokenApi = (
  data: RefreshTokenRequest
): Promise<RefreshTokenResponse> => {
  return api.post(EndpointsEnum.REFRESH_TOKEN, data);
};

const forgetPasswordApi = (
  data: ForgetPasswordRequest
): Promise<ForgetPasswordResponse> => {
  return api.post(EndpointsEnum.FORGET_PASSWORD, data);
};

const resetPasswordApi = (
  data: ResetPasswordRequest
): Promise<ResetPasswordResponse> => {
  return api.post(EndpointsEnum.RESET_PASSWORD, data);
};

export const authApi = {
  registerApi,
  loginApi,
  refreshTokenApi,
  forgetPasswordApi,
  resetPasswordApi,
};
