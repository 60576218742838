import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import Header from "../../../../components/Header/Header";
import Loader from "../../../../components/Loader/Loader";
import { TaskListTypeEnum } from "../../../../constants/enum";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";
import TaskTable from "../../components/TaskTable/TaskTable";
import { filterByName } from "../../helpers/project.helpers";
import { getTasks } from "../../redux/project.slice";
import classes from "./TaskListScreen.module.css";

const TaskListScreen: FC = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }
  const currentUserId = useAppSelector(
    (state: RootState) => state.profile.currentUser.Id
  );
  const listTask = useAppSelector((state: RootState) => state.project.listTask);
  const loading = useAppSelector((state: RootState) => state.project.loading);

  const [filterTasks, setFilterTasks] = useState(listTask);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (currentUserId) {
      dispatch(getTasks(currentUserId))
        .unwrap()
        .then((data) => setFilterTasks(data.listTask));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const onChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    if (!event.target.value) {
      setFilterTasks(listTask);
    }
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const searchResult = filterByName(listTask, keyword);
    setFilterTasks(searchResult);
  };

  return (
    <>
      {loading && <Loader />}
      <Header />
      <div className={classes.taskListScreen}>
        <div className={classes.taskListHeader}>
          <div className={classes.title}>My tasks</div>
          <div className={classes.searchWrapper}>
            <form onSubmit={handleSearch}>
              <input
                type="text"
                value={keyword}
                placeholder="Tìm kiếm"
                autoComplete="off"
                onChange={onChangeKeyword}
                className={classes.searchInput}
              />
              <button type="submit" className={classes.searchBtn}>
                <FontAwesomeIcon
                  icon={faSearch}
                  size="lg"
                  className={classes.searchIcon}
                />
              </button>
            </form>
          </div>
        </div>
        <TaskTable type={TaskListTypeEnum.ALL_TASK} taskList={filterTasks} />
      </div>
    </>
  );
};

export default TaskListScreen;
