import { PathsEnum } from "../../../constants/paths";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import { RouteItemDef } from "./../../../types/routes.types";

const HOME_SCREEN: RouteItemDef = {
  id: "home",
  path: PathsEnum.HOME,
  component: HomeScreen,
};

const HOME_ROUTES = [HOME_SCREEN];

export default HOME_ROUTES;
