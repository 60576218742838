import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HomeState, ReadNotificationRequest } from "./../types/home.types";
import { homeApi } from "./../api/api";

const initialState: HomeState = {
  connection: null,
  notifications: [],
};

export const getNotifications = createAsyncThunk<any, number>(
  "home/getNotifications",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await homeApi.getNotificationsApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const readNotification = createAsyncThunk<any, ReadNotificationRequest>(
  "home/readNotification",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await homeApi.readNotificationApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    updateConnection: (state, action) => {
      state.connection = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.listNoti;
    });
    builder.addCase(readNotification.fulfilled, (state, action) => {
      let index = state.notifications
        .map((noti) => noti.Id)
        .indexOf(action.payload.notif.Id);
      state.notifications[index].IsRead = true;
    });
  },
});

export const { updateConnection, addNotification } = homeSlice.actions;

export const homeReducer = homeSlice.reducer;
