import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";
import { authApi } from "../api/auth.api";
import {
  AuthState,
  LoginRequest,
  RegisterRequest,
  RegisterState,
  LoginState,
  ForgetPasswordRequest,
  ResetPasswordRequest,
} from "./../types/auth.types";

const initialState: AuthState = {
  id: 0,
  token: "",
  refreshToken: "",
  apiMessage: null,
};

export const register = createAsyncThunk<RegisterState, RegisterRequest>(
  "auth/register",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await authApi.registerApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk<LoginState, LoginRequest>(
  "auth/login",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await authApi.loginApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = () => {
  storage.removeItem("persist:auth");
};

export const forgetPassword = createAsyncThunk<any, ForgetPasswordRequest>(
  "auth/forgetPassword",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await authApi.forgetPasswordApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk<any, ResetPasswordRequest>(
  "auth/resetPassword",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await authApi.resetPasswordApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      state.token = action.payload.Token;
      state.refreshToken = action.payload.RefreshToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.id = action.payload.Id;
      state.token = action.payload.Token;
      state.refreshToken = action.payload.RefreshToken;
      state.apiMessage = action.payload.apiMessage;
    });
  },
});

const authConfig = {
  key: "auth",
  storage,
  whitelist: ["id", "token", "refreshToken"],
};

export const { updateToken } = authSlice.actions;

export const authReducer = persistReducer(authConfig, authSlice.reducer);
