import { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import Header from "../../../../components/Header/Header";
import { PathsEnum } from "../../../../constants/paths";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import ProjectSidebar from "../../components/ProjectSidebar/ProjectSidebar";
import {
  addProjectDocument,
  getProject,
  getProjectDocument,
} from "../../redux/project.slice";
import classes from "./ProjectDocumentScreen.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { ProjectMessage } from "../../../../constants/api.message";
import { ApiMessageType } from "../../../auth/types/auth.types";
import ProjectDocumentList from "../../components/ProjectDocumentList/ProjectDocumentList";
import AlertPopup from "../../../../components/AlertPopup/AlertPopup";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";

const ProjectDocumentScreen: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }
  const project = useAppSelector((state: RootState) => state.project.project);
  const inputDocumentRef = useRef<HTMLInputElement>();
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const [hasLargeFile, setHasLargeFile] = useState(false);

  useEffect(() => {
    if (!project) {
      dispatch(getProject(id));
    }
    dispatch(getProjectDocument(id))
      .unwrap()
      .then()
      .catch(() => console.log("Get document fail"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.Id]);

  const handleAddDocument = (file: File, size: string) => {
    const formData = new FormData();
    formData.append("ProjectId", id);
    formData.append("Files", file);
    formData.append("Size", size);
    dispatch(addProjectDocument(formData))
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
      });
  };

  const handleChangeDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    let element: any;
    Object.keys(fileList).forEach((key) => {
      if (fileList[key].size <= 100 * 1024 * 1024) {
        const url = URL.createObjectURL(fileList[key]);
        if (fileList[key].type.includes("image")) {
          element = document.createElement("img");
          element.src = url;
          element.onload = (e: any) => {
            const size = `${e.target.naturalWidth}x${e.target.naturalHeight}`;
            handleAddDocument(fileList[key], size);
          };
        } else if (fileList[key].type.includes("video")) {
          element = document.createElement("video");
          element.src = url;
          element.onloadedmetadata = (e: any) => {
            const size = `${e.target.videoWidth}x${e.target.videoHeight}`;
            handleAddDocument(fileList[key], size);
          };
        } else {
          handleAddDocument(fileList[key], "0x0");
        }
      } else {
        setHasLargeFile(true);
      }
    });
  };

  return (
    <>
      <Header />
      <div className={classes.documentScreen}>
        <ProjectSidebar />
        <div className={classes.projectDocument}>
          <Breadcrumbs
            linkList={[
              ["Tất cả dự án", PathsEnum.PROJECTS],
              [project?.Name, PathsEnum.PROJECT_MEMBER.replace(":id", id)],
            ]}
          />
          <div className={classes.documentHeader}>
            <div className={classes.title}>Tài liệu</div>
            <button
              className={classes.addDocumentBtn}
              onClick={() => inputDocumentRef.current.click()}
            >
              <FontAwesomeIcon icon={faPlus} size="sm" /> Thêm
            </button>
            <input
              ref={inputDocumentRef}
              type="file"
              multiple
              hidden
              onChange={handleChangeDocument}
            />
          </div>
          <ProjectDocumentList
            list={project?.Files}
            setApiMessage={setApiMessage}
          />
        </div>
      </div>
      {apiMessage && (
        <NotifyAlert
          type={apiMessage.Type}
          message={ProjectMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
      {hasLargeFile && (
        <AlertPopup
          title="Không thể gửi tệp"
          message="File bạn chọn quá lớn. Vui lòng chọn file không quá 100MB."
          onClose={() => setHasLargeFile(false)}
        />
      )}
    </>
  );
};

export default ProjectDocumentScreen;
