import { FC, Fragment, useEffect, useState } from "react";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import classes from "./ProjectMemberScreen.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Popup from "../../../../components/Popup/Popup";
import AddOrEditMemberProject from "../../components/AddOrEditMemberProject/AddOrEditMemberProject";
import ConfirmPopup from "../../../../components/ConfirmPopup/ConfirmPopup";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { deleteMemberProject, getProject } from "../../redux/project.slice";
import { RootState } from "../../../../redux/store";
import { UserState } from "../../../profile/types/profile.types";
import clsx from "clsx";
import { memberRoles } from "../ProjectDetailScreen/ProjectDetailScreen";
import Header from "../../../../components/Header/Header";
import ProjectSidebar from "../../components/ProjectSidebar/ProjectSidebar";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { PathsEnum } from "../../../../constants/paths";
import { useParams } from "react-router-dom";
import { ApiMessageType } from "../../../auth/types/auth.types";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { ProjectMessage } from "../../../../constants/api.message";
import { ActionEnum } from "../../../../constants/enum";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";

const ProjectMemberScreen: FC = () => {
  const { id } = useParams<{ id: string }>();
  const iOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform);
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const project = useAppSelector((state: RootState) => state.project.project);
  const [displayAddMember, setDisplayAddMember] = useState(false);
  const [choosenEditMember, setChoosenEditMember] = useState<UserState>();
  const [choosenDeleteMember, setChoosenDeleteMember] = useState<UserState>();
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const isAdmin =
    project?.Members?.find((member) => member.Id === currentUser.Id)?.Role ===
    1;

  useEffect(() => {
    if (!project) {
      dispatch(getProject(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteMember = (userId: number) => {
    setChoosenDeleteMember(null);
    dispatch(
      deleteMemberProject({
        UserId: userId,
        ProjectId: project.Id,
      })
    )
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
      })
      .catch(() => console.log("Delete member fail"));
  };

  return (
    <>
      <Header />
      <div className={classes.memberScreen}>
        <ProjectSidebar />
        <div className={classes.projectMember}>
          <Breadcrumbs
            linkList={[
              ["Tất cả dự án", PathsEnum.PROJECTS],
              [project?.Name, PathsEnum.PROJECT_MEMBER.replace(":id", id)],
            ]}
          />
          <div className={classes.memberHeader}>
            <div className={classes.title}>Thành viên</div>
            {isAdmin && (
              <button
                className={classes.addMemberBtn}
                onClick={() => setDisplayAddMember(true)}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" /> Thêm
              </button>
            )}
          </div>
          <div className={classes.memberBody}>
            <table
              className={clsx(classes.memeberTable, { [classes.ios]: iOS })}
            >
              <thead>
                <tr>
                  <th className={classes.name}>Tên</th>
                  <th className={classes.email}>Email</th>
                  <th className={classes.role}>Vai trò</th>
                  {isAdmin && <th className={classes.action}></th>}
                </tr>
              </thead>
              <tbody>
                {project?.Members.map((member, id) => {
                  const memberRole = memberRoles.find(
                    (role) => role.id === member.Role
                  );
                  return (
                    <Fragment key={member.Id}>
                      <tr className={classes.memberItem}>
                        <td className={classes.name}>
                          <div className={classes.nameWrapper}>
                            <div className={classes.avatar}>
                              <img
                                alt="avt"
                                src={getAvatarSrc(member?.Avatar)}
                                className={classes.avatar}
                              />
                            </div>
                            <div className={classes.username}>
                              {member?.Fullname}
                            </div>
                          </div>
                        </td>
                        <td className={classes.email}>{member?.Email}</td>
                        <td className={classes.role}>{memberRole?.value}</td>
                        {isAdmin && member.Id !== currentUser.Id ? (
                          <td className={classes.action}>
                            <FontAwesomeIcon
                              icon={faPen}
                              size="sm"
                              className={classes.iconItem}
                              onClick={() => {
                                setChoosenEditMember(member);
                              }}
                            />
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              size="sm"
                              className={classes.iconItem}
                              onClick={() => {
                                setChoosenDeleteMember(member);
                              }}
                            />
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
          {displayAddMember && (
            <Popup
              title="Thêm thành viên"
              height="fit-content"
              onClose={() => setDisplayAddMember(false)}
            >
              <AddOrEditMemberProject
                type={ActionEnum.ADD}
                onClosePopup={() => setDisplayAddMember(false)}
                setApiMessage={setApiMessage}
              />
            </Popup>
          )}
          {choosenEditMember?.Id && (
            <Popup
              title="Chỉnh sửa"
              height="fit-content"
              onClose={() => setChoosenEditMember(null)}
            >
              <AddOrEditMemberProject
                type={ActionEnum.EDIT}
                choosenMember={choosenEditMember}
                onClosePopup={() => setChoosenEditMember(null)}
                setApiMessage={setApiMessage}
              />
            </Popup>
          )}
          {choosenDeleteMember?.Id && (
            <ConfirmPopup
              title={`Chắc chắn muốn xóa ${choosenDeleteMember.Fullname}?`}
              onClose={() => setChoosenDeleteMember(null)}
              onOK={() => handleDeleteMember(choosenDeleteMember.Id)}
            />
          )}
        </div>
      </div>
      {apiMessage && (
        <NotifyAlert
          type={apiMessage.Type}
          message={ProjectMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
    </>
  );
};

export default ProjectMemberScreen;
