import { FC, ReactNode } from "react";
import classes from "./Popup.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface PopupProps {
  title?: string;
  children: ReactNode;
  onClose: () => void;
  height?: string;
  width?: string;
}

const Popup: FC<PopupProps> = ({ title, children, height, width, onClose }) => {
  return (
    <div className={classes.popup}>
      <div
        className={classes.popupContainer}
        style={{ height: height, width: width }}
      >
        <div className={classes.header}>
          {title && <span className={classes.popupTitle}>{title}</span>}
          <FontAwesomeIcon
            icon={faTimes}
            className={classes.popupClose}
            onClick={onClose}
          />
        </div>
        <div className={classes.popupChild}>{children}</div>
      </div>
    </div>
  );
};

export default Popup;
