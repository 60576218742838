import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classes from "./FileView.module.css";

interface FileViewProps {
  onClose: () => void;
  handleDownloadFile: () => void;
}

const FileView: FC<FileViewProps> = ({ onClose, handleDownloadFile }) => {
  return (
    <div className={classes.fileViewContainer}>
      <FontAwesomeIcon
        icon={faTimes}
        size="2x"
        className={classes.closeIcon}
        onClick={onClose}
      />
      <div className={classes.imageContainer}>
        <div className={classes.text}>
          Không có bản xem trước. Vui lòng tải xuống.
        </div>
        <button className={classes.button} onClick={handleDownloadFile}>
          Tải xuống
        </button>
      </div>
    </div>
  );
};

export default FileView;
