import { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PathsEnum } from "../../../../constants/paths";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import { projectCreatedDate } from "../../helpers/project.helpers";
import { TaskDetailType, TaskType } from "../../types/project.types";
import { faTasks, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import classes from "./TaskTable.module.css";
import { TaskStatus } from "../AddOrEditTask/AddOrEditTask";
import { getProjects } from "../../redux/project.slice";
import Table from "react-bootstrap/Table";
import { TaskListTypeEnum } from "../../../../constants/enum";

interface TaskTableProps {
  //type=1: all task, type=2: task in feature
  type?: number;
  taskList: TaskType[] | TaskDetailType[];
}

const TaskTable: FC<TaskTableProps> = ({
  type = TaskListTypeEnum.TASK_IN_FEATURE,
  taskList,
}) => {
  const iOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id, featureId } = useParams<{
    id: string;
    featureId: string;
  }>();
  const currentUserId = useAppSelector(
    (state: RootState) => state.profile.currentUser.Id
  );
  const users = useAppSelector((state: RootState) => state.chat.users);
  const listProject = useAppSelector(
    (state: RootState) => state.project.listProject
  );

  useEffect(() => {
    if (!listProject && type === TaskListTypeEnum.ALL_TASK && currentUserId) {
      dispatch(getProjects(currentUserId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  return taskList?.length === 0 ? (
    <div>Không có task nào.</div>
  ) : (
    <Table
      className={clsx(classes.taskList, { [classes.ios]: iOS })}
      responsive="sm"
    >
      <thead>
        <tr>
          <th className={classes.type}></th>
          <th className={classes.name}>Tên task</th>
          {type === 1 && <th className={classes.project}>Dự án</th>}
          <th className={classes.status}>Status</th>
          <th className={classes.createdUser}>Người tạo</th>
          <th className={classes.createdUser}>Người thực hiện</th>
          <th className={classes.deadline}>Hạn thực hiện</th>
        </tr>
      </thead>
      <tbody>
        {taskList?.map((task) => {
          const project = listProject?.find(
            (project) => project.Id === task?.ProjectId
          );
          const createdUser = users.find((user) => user.Id === task?.CreatedBy);
          const assignee = users.find((user) => user.Id === task?.Assignee);
          const statusObj = TaskStatus.find(
            (status) => status.id === task?.Status
          );
          return (
            <tr
              key={task?.Id}
              className={classes.taskItem}
              onClick={() =>
                history.push(
                  PathsEnum.TASK.replace(":id", id || task.ProjectId)
                    .replace(":featureId", featureId || task.FeatureId)
                    .replace(":taskId", `${task.Id}`)
                )
              }
            >
              <td className={classes.type}>
                <FontAwesomeIcon icon={faTasks} size="sm" />
              </td>
              <td className={classes.name}>{task?.Name}</td>
              {type === TaskListTypeEnum.ALL_TASK && (
                <td className={classes.project}>{project?.Name}</td>
              )}
              <td className={classes.status}>{statusObj?.value}</td>
              <td className={classes.createdUser}>
                <div className={classes.nameWrapper}>
                  <img
                    alt="avt"
                    src={getAvatarSrc(createdUser?.Avatar)}
                    className={classes.avatar}
                  />
                  <div className={classes.name}>{createdUser?.Fullname}</div>
                </div>
              </td>
              <td className={classes.createdUser}>
                <div className={classes.nameWrapper}>
                  <img
                    alt="avt"
                    src={getAvatarSrc(assignee?.Avatar)}
                    className={classes.avatar}
                  />
                  <div className={classes.name}>{assignee?.Fullname}</div>
                </div>
              </td>
              <td className={classes.deadline}>
                {projectCreatedDate(task?.Deadline)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TaskTable;
