import { FC, memo, useRef, useState } from "react";
import { useField } from "formik";
import classes from "../InputField/InputField.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { isHasAnySpaces } from "../../../helpers/helpers";
import _ from "lodash";

export interface InputPasswordFieldProps {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  hint?: string;
  required: boolean;
  width?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoginPassword?: boolean;
}

const InputPasswordField: FC<InputPasswordFieldProps> = (props) => {
  const inputPasswordRef = useRef<HTMLInputElement>();
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const [isOnBlur, setIsOnBlur] = useState(false);

  const handleShow = () => {
    if (showPassword) {
      setShowPassword(false);
      inputPasswordRef.current.type = "password";
    } else {
      setShowPassword(true);
      inputPasswordRef.current.type = "text";
    }
  };
  return (
    <div className={classes.inputWrapper} style={{ width: props.width }}>
      {props.label && (
        <div className={classes.label}>
          {props.label}
          {props.required && <span className={classes.required}>*</span>}
        </div>
      )}
      <div className={classes.inputPasswordWrapper}>
        <input
          type="password"
          className={classes.input}
          ref={inputPasswordRef}
          {...field}
          {..._.omit(props, "isLoginPassword", "width", "hint")}
          autoComplete="off"
          onBlur={(e) => {
            field.onBlur(e);
            setIsOnBlur(true);
          }}
        />
        <FontAwesomeIcon
          size="sm"
          icon={showPassword ? faEyeSlash : faEye}
          onClick={handleShow}
          className={classes.iconItem}
        />
      </div>
      {meta.touched && meta.error ? (
        <p className={classes.wrongInput}>{meta.error}</p>
      ) : (
        <p className={classes.rightInput}>{props.hint}</p>
      )}
      {!props.isLoginPassword &&
        props.required &&
        isOnBlur &&
        field.value.length > 0 &&
        isHasAnySpaces(field.value) && (
          <p className={classes.wrongInput}>
            Vui lòng nhập khác khoảng trắng {isHasAnySpaces(field.value)}
          </p>
        )}
    </div>
  );
};

export default memo(InputPasswordField);
