import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import {
  convertDateToLocal,
  convertMessageDate,
  getAvatarSrc,
} from "../../../chat/helpers/chat.helpers";
import { readNotification } from "../../redux/home.slice";
import classes from "./NotifyModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faCircle } from "@fortawesome/free-solid-svg-icons";
import { clsx } from "clsx";
import { useHistory } from "react-router-dom";
import { NotificationType } from "../../types/home.types";

interface NotifyModalProps {
  currentUserId: number;
  onCloseModal: () => void;
}

const NotifyModal: FC<NotifyModalProps> = ({ currentUserId, onCloseModal }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const users = useAppSelector((state: RootState) => state.chat.users);
  const notifications = useAppSelector(
    (state: RootState) => state.home.notifications
  );

  const onClick = (
    projectId: number,
    featureId: number,
    taskId: number,
    notif: NotificationType
  ) => {
    if (!notif.IsRead) {
      dispatch(readNotification({ Id: notif.Id }));
    }
    setTimeout(() => {
      history.replace(
        `/projects/${projectId}/detail/${featureId}/task/${taskId}`
      );
      onCloseModal();
    }, 500);
  };

  return (
    <div className={classes.notiModal}>
      <div className={classes.notiHeader}>
        <div className={classes.title}>Thông báo</div>
        {/* <FontAwesomeIcon icon={faEllipsis} className={classes.iconItem} /> */}
      </div>
      <div className={classes.notiBody}>
        {notifications?.length > 0 ? (
          <>
            {notifications.map((noti) => {
              const createdUser = users.find(
                (user) => user.Id === noti?.CreatedBy
              );
              return (
                <div
                  key={noti.Id}
                  className={classes.notiItem}
                  onClick={() =>
                    onClick(noti.ProjectId, noti.FeatureId, noti.TaskId, noti)
                  }
                >
                  <img
                    alt="avt"
                    src={getAvatarSrc(createdUser?.Avatar)}
                    className={classes.avatar}
                  />
                  <div className={classes.notiWrapper}>
                    <div className={classes.content}>
                      <span className={classes.username}>
                        {createdUser?.Fullname}
                      </span>
                      {" " + noti.Content}
                    </div>
                    <div className={classes.notiDateWrapper}>
                      <div
                        className={clsx(classes.createdDate, {
                          [classes.unread]: !noti.IsRead,
                        })}
                      >
                        {convertMessageDate(
                          convertDateToLocal(noti.CreatedDate)
                        )}
                      </div>
                      {!noti.IsRead && (
                        <div className={classes.unreadIconWrapper}>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className={classes.unreadIcon}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div>Không có thông báo</div>
        )}
      </div>
    </div>
  );
};

export default NotifyModal;
