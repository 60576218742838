import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import AUTH_ROUTES from "../features/auth/routes/auth.routes";
import { CLIENT_HOST, HOST } from "./../constants/variable";

const baseURL = `${HOST}/api`;

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With",
  },
});

export const fileApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */

const requestInterceptor = (request: AxiosRequestConfig) => {
  const auth = JSON.parse(localStorage.getItem("persist:auth")!);
  const token = auth?.token?.replaceAll('"', "");
  // if (!token && window.location.href !== "/login") {
  //   window.location.href = "/login";
  // }
  if (token) {
    const expireIn = jwtDecode<{ exp: number }>(token)?.exp * 1000;
    const nowDate = new Date().getTime();
    if (expireIn < nowDate && window.location.href !== "/login") {
      window.location.href = "/login";
    }
    request.headers.Authorization = `Bearer ${auth?.token?.replaceAll(
      '"',
      ""
    )}`;
  }
  return request;
};

/**
 * Axios response interceptors
 * @param {AxiosResponse} response
 */
const responseInterceptor = (response: AxiosResponse) => {
  // response.data = toCamelCase(response.data);
  return response;
};

const apiErrorInterceptor = async (error: any) => {
  const prevRequest = error?.config;
  if (error?.response?.status === 401 && !prevRequest?.sent) {
    localStorage.clear();
    // window.location.href = "/login";
    // prevRequest.sent = true;
    // let auth = JSON.parse(localStorage.getItem("persist:auth")!);
    // const token = auth?.token?.replaceAll('"', "");
    // const refreshtoken = auth?.refreshToken?.replaceAll('"', "");
    // const newAccessToken = await authApi.refreshTokenApi({
    //   Token: token,
    //   RefreshToken: refreshtoken,
    // });
    // updateToken(newAccessToken.data);
    // auth.token = newAccessToken.data.Token;
    // auth.refreshToken = newAccessToken.data.RefreshToken;

    // localStorage.setItem("persist:auth", JSON.stringify(auth));
    // prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
    return api(prevRequest);
  }
  return Promise.reject(error);
};

const fileApiErrorInterceptor = async (error: any) => {
  const prevRequest = error?.config;
  if (error?.response?.status === 401 && !prevRequest?.sent) {
    localStorage.clear();
    // window.location.href = "/login";
    // prevRequest.sent = true;
    // let auth = JSON.parse(localStorage.getItem("persist:auth")!);
    // const token = auth?.token?.replaceAll('"', "");
    // const refreshtoken = auth?.refreshToken?.replaceAll('"', "");
    // const newAccessToken = await authApi.refreshTokenApi({
    //   Token: token,
    //   RefreshToken: refreshtoken,
    // });
    // updateToken(newAccessToken.data);
    // auth.token = newAccessToken.data.Token;
    // auth.refreshToken = newAccessToken.data.RefreshToken;

    // console.log(newAccessToken.data);
    // localStorage.setItem("persist:auth", JSON.stringify(auth));
    // prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
    return fileApi(prevRequest);
  }
  return Promise.reject(error);
};

/** Add interceptor */
api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor, apiErrorInterceptor);

fileApi.interceptors.request.use(requestInterceptor);
fileApi.interceptors.response.use(responseInterceptor, fileApiErrorInterceptor);
