import { FC, useState } from "react";
import { Formik, Form } from "formik";
import {
  newMemberInitValues,
  newMemberSchema,
  onEnterForm,
} from "../../helpers/project.helpers";
import InputField from "../../../../components/Form/InputField/InputField";
import SelectField from "../../../../components/Form/SelectField/SelectField";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import classes from "../AddOrEditFeature/AddOrEditFeature.module.css";
import { filterNotContainUsers } from "../../../chat/helpers/chat.helpers";
import {
  addMemberProject,
  addMemberProjectReducer,
  editMemberProject,
  editMemberProjectReducer,
} from "../../redux/project.slice";
import { memberRoles } from "../../screens/ProjectDetailScreen/ProjectDetailScreen";
import { UserState } from "../../../profile/types/profile.types";
import { ApiMessageType } from "../../../auth/types/auth.types";
import SearchAndChooseUser from "../SearchAndChooseUser/SearchAndChooseUser";
import CancelButton from "../../../../components/Button/CancelButton/CancelButton";
import { ActionEnum } from "../../../../constants/enum";

interface AddOrEditMemberProjectProps {
  type: number;
  choosenMember?: UserState;
  onClosePopup: () => void;
  setApiMessage: (apiMessage: ApiMessageType) => void;
}

const AddOrEditMemberProject: FC<AddOrEditMemberProjectProps> = ({
  type,
  choosenMember,
  onClosePopup,
  setApiMessage,
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const project = useAppSelector((state: RootState) => state.project.project);
  const users = useAppSelector((state: RootState) =>
    state.chat.users.filter((user) => user.Id !== currentUser.Id)
  );
  const notProjectMember = filterNotContainUsers(users, project.Members);
  const [choosenUser, setChoosenUser] = useState(choosenMember || null);
  const editMemberInitValue = {
    role: choosenMember?.Role,
  };

  const handleSubmit = (values: any) => {
    if (type === ActionEnum.ADD) {
      dispatch(
        addMemberProject({
          UserId: choosenUser.Id,
          ProjectId: project.Id,
          Role: Number(values.role) || memberRoles[0].id,
        })
      )
        .unwrap()
        .then((data) => {
          setApiMessage(data.apiMessage);
          const newMember = {
            ...choosenUser,
            Role: Number(values.role) || memberRoles[0].id,
          };
          dispatch(addMemberProjectReducer(newMember));
          onClosePopup();
        })
        .catch(() => console.log("Add member fail"));
    } else {
      dispatch(
        editMemberProject({
          UserId: choosenUser.Id,
          ProjectId: project.Id,
          Role: Number(values.role) || choosenMember.Role,
        })
      )
        .unwrap()
        .then((data) => {
          setApiMessage(data.apiMessage);
          if (data.apiMessage.Type === "success") {
            const newMember = {
              ...choosenUser,
              Role: Number(values.role) || choosenMember.Role,
            };
            dispatch(editMemberProjectReducer(newMember));
            onClosePopup();
          }
        })
        .catch(() => console.log("Edit member fail"));
    }
  };

  return (
    <div className={classes.form}>
      <Formik
        initialValues={
          choosenMember ? editMemberInitValue : newMemberInitValues
        }
        validationSchema={newMemberSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form onKeyDown={onEnterForm}>
            <SearchAndChooseUser
              label="Người dùng"
              required
              choosenUser={choosenUser}
              setChoosenUser={setChoosenUser}
              users={notProjectMember}
              hasRemoveIcon={type === ActionEnum.ADD}
            />
            <SelectField
              name="role"
              options={memberRoles}
              label="Vai trò"
              required
            />
            <div className={classes.buttonGroup}>
              <CancelButton label="Hủy" onClick={onClosePopup} />
              <InputField
                type="submit"
                name="submit"
                value={type === ActionEnum.ADD ? "Thêm" : "Lưu"}
                width="120px"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditMemberProject;
