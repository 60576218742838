import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import {
  convertDateToLocal,
  convertMessageDate,
  getAvatarSrc,
} from "../../../chat/helpers/chat.helpers";
import { UserState } from "../../../profile/types/profile.types";
import { getEditHistory, getMoreEditHistory } from "../../redux/project.slice";
import classes from "./EditHistory.module.css";

export interface EditHistoryProps {
  projectId?: string | number;
  featureId?: string | number;
  taskId?: string | number;
  users: UserState[];
}

const EditHistory: FC<EditHistoryProps> = ({
  projectId,
  featureId,
  taskId,
  users,
}) => {
  const FieldNameList = [
    {
      id: 1,
      name: "Name",
      value: `tên ${projectId ? "dự án" : featureId ? "chức năng" : "task"}`,
    },
    {
      id: 2,
      name: "Description",
      value: `mô tả ${projectId ? "dự án" : featureId ? "chức năng" : "task"}`,
    },
  ];
  const dispatch = useAppDispatch();
  const editHistory = useAppSelector(
    (state: RootState) => state.project.editHistory
  );
  const [currentPage, setCurrentPage] = useState(2);

  useEffect(() => {
    dispatch(
      getEditHistory({
        ProjectId: projectId || 0,
        FeatureId: featureId || 0,
        TaskId: taskId || 0,
        CurrentPage: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetMoreHistory = () => {
    dispatch(
      getMoreEditHistory({
        ProjectId: projectId || 0,
        FeatureId: featureId || 0,
        TaskId: taskId || 0,
        CurrentPage: currentPage,
      })
    )
      .unwrap()
      .then(() => setCurrentPage(currentPage + 1));
  };

  return (
    <div className={classes.editHistoryContainer}>
      {editHistory?.length > 0 ? (
        <InfiniteScroll
          dataLength={editHistory.length}
          next={handleGetMoreHistory}
          hasMore={true}
          loader
          className={classes.editHistoryScroll}
        >
          {editHistory.map((history) => {
            const changeByUser = users.find(
              (user) => user.Id === history.ChangedBy
            );
            const fieldname = FieldNameList.find(
              (field) => field.name === history.FieldName
            );
            const action =
              !history.To || history.To === "<p><br></p>" ? "xóa" : "cập nhật";
            return (
              <div key={history.Id} className={classes.editHistoryItem}>
                <img
                  alt="avt"
                  src={getAvatarSrc(changeByUser?.Avatar)}
                  className={classes.avatar}
                />
                <div className={classes.editHistoryRight}>
                  <div
                    className={classes.editHistoryTop}
                    style={{ marginBottom: fieldname?.id === 1 && "0.5em" }}
                  >
                    <div>
                      <span className={classes.username}>
                        {changeByUser.Fullname}
                      </span>{" "}
                      đã {action} {fieldname?.value}.
                    </div>
                    <div className={classes.changedDate}>
                      {convertMessageDate(
                        convertDateToLocal(history.ChangedDate)
                      )}
                    </div>
                  </div>
                  <div className={classes.historyContent}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          action === "xóa"
                            ? ""
                            : !history.From || history.From !== "<p><br></p>"
                            ? history.From
                            : "",
                      }}
                    ></span>
                    {action === "cập nhật" &&
                      history.From &&
                      history.From !== "<p><br></p>" &&
                      history.To &&
                      history.To !== "<p><br></p>" && (
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className={classes.arrowIcon}
                        />
                      )}
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          action === "xóa"
                            ? ""
                            : !history.To || history.To !== "<p><br></p>"
                            ? history.To
                            : "",
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      ) : (
        <div className={classes.noHistory}>Không có lịch sử chỉnh sửa!</div>
      )}
    </div>
  );
};

export default EditHistory;
