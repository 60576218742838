import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "../../../../components/Popup/Popup";
import { PathsEnum } from "../../../../constants/paths";
import { LocalStorage } from "../../../../helpers/localStorage";
import { useAppDispatch } from "../../../../hooks/hooks";
import { getAllFeature } from "../../redux/project.slice";
import { ProjectsType } from "../../types/project.types";
import AddOrEditFeature from "../AddOrEditFeature/AddOrEditFeature";
import NewProject from "../NewProject/NewProject";
import classes from "./ChooseTaskParent.module.css";

interface ChooseTaskParentProps {
  projects: ProjectsType[];
  projectId: string;
  setProjectId: (id: string) => void;
  setFeatureId: (id: string) => void;
  onCloseTaskPopup: () => void;
}

const ChooseTaskParent: FC<ChooseTaskParentProps> = ({
  projects,
  projectId,
  setProjectId,
  setFeatureId,
  onCloseTaskPopup,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [features, setFeatures] = useState([]);
  const [displayNewProject, setDisplayNewProject] = useState(false);
  const [displayNewFeature, setDisplayNewFeature] = useState(false);

  const onChangeProject = (id: string) => {
    setProjectId(id);
    dispatch(getAllFeature(id))
      .unwrap()
      .then((data) => {
        setFeatures(data.features);
      })
      .catch();
  };
  return (
    <>
      <div className={classes.project}>
        <div className={classes.label}>
          Dự án
          <span className={classes.required}>*</span>
        </div>
        {projects?.length > 0 ? (
          <select
            name="projectId"
            required
            className={clsx(classes.input, classes.select)}
            onChange={(e) => onChangeProject(e.target.value)}
          >
            <option value="">-- Chọn dự án --</option>
            {projects?.map((project) => (
              <option
                key={project.Id}
                value={project.Id}
                className={classes.selectOption}
              >
                {project.Name}
              </option>
            ))}
          </select>
        ) : (
          <div>
            Không có dự án nào.
            <span
              className={classes.addBtn}
              onClick={() => {
                LocalStorage.setCreateProject();
                history.push(PathsEnum.PROJECTS);
                onCloseTaskPopup();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Tạo
            </span>
          </div>
        )}
      </div>
      <div className={classes.feature}>
        <div className={classes.label}>
          Chức năng
          <span className={classes.required}>*</span>
        </div>

        {features.length > 0 ? (
          <select
            name="featureId"
            required
            className={clsx(classes.input, classes.select)}
            onChange={(e) => setFeatureId(e.target.value)}
          >
            <option value="">-- Chọn chức năng --</option>
            {features?.map((feature) => (
              <option
                key={feature.Id}
                value={feature.Id}
                className={classes.selectOption}
              >
                {feature.Name}
              </option>
            ))}
          </select>
        ) : (
          <div>
            Không có chức năng nào.
            {projectId && (
              <span
                className={classes.addBtn}
                onClick={() => {
                  LocalStorage.setCreateFeature();
                  history.push(
                    PathsEnum.PROJECT_DETAIL.replace(":id", projectId)
                  );
                  onCloseTaskPopup();
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Tạo
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ChooseTaskParent;
