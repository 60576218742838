import { FC } from "react";
import { FieldArray } from "formik";
import CheckboxField from "../../../../components/Form/CheckboxField/CheckboxField";
import classes from "../NewGroupChat/NewGroupChat.module.css";
import { UserState } from "../../../profile/types/profile.types";
import { getAvatarSrc } from "../../helpers/chat.helpers";

interface CheckboxGroupProps {
  name: string;
  users: UserState[];
  values: string[];
}

const ChooseMemberGroup: FC<CheckboxGroupProps> = ({ name, users, values }) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) =>
        users.map((user: any) => (
          <CheckboxField
            name={name}
            key={user.Id}
            value={user.Id}
            checked={values?.includes(`${user.Id}`)}
            values={values}
            arrayHelpers={arrayHelpers}
          >
            <img
              alt="avt"
              src={getAvatarSrc(user.Avatar)}
              className={classes.avatar}
            />
            <span className={classes.username}>{user.Fullname}</span>{" "}
          </CheckboxField>
        ))
      }
    />
  );
};

export default ChooseMemberGroup;
