import { FC } from "react";
import classes from "./AlertPopup.module.css";

interface AlertPopupProps {
  title?: string;
  message: string;
  onClose: () => void;
}

const AlertPopup: FC<AlertPopupProps> = ({ title, message, onClose }) => {
  return (
    <div className={classes.popup}>
      <div className={classes.popupContainer}>
        <div className={classes.popupContent}>
          {title && <div className={classes.title}>{title}</div>}
          <div>{message}</div>
        </div>
        <div className={classes.ok} onClick={onClose}>
          OK
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
