export const PathsEnum = {
  HOME: "/",

  LOGIN: "/login",
  REGISTER: "/register",
  PROFILE: "/profile",
  FORGET_PASSWORD: "/forgetpassword",
  RESET_PASSWORD: "/resetpassword",

  CHAT: "/chat",
  EXIST_CONVERSATION: "/chat/:id",
  NEW_CONVERSATION: "/chat/new/:receiverId",

  PROJECTS: "/projects",
  PROJECT: "/projects/:id",
  NEW_PROJECT: "/projects/new",
  PROJECT_DETAIL: "/projects/:id/detail",
  PROJECT_MEMBER: "/projects/:id/member",
  PROJECT_DOCUMENTS: "/projects/:id/documents",
  FEATURE: "/projects/:id/detail/:featureId",
  TASK: "/projects/:id/detail/:featureId/task/:taskId",
  TASKS: "/tasks",
};
