import { FC, memo, useState } from "react";
import { Formik, Form } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import classes from "../LoginScreen/LoginScreen.module.css";
import {
  registerInitValue,
  registerValidateSchema,
} from "../../helpers/auth.helper";
import { ApiMessageType } from "../../types/auth.types";
import { register } from "../../redux/auth.slice";
import InputField from "../../../../components/Form/InputField/InputField";
import { RootState } from "../../../../redux/store";
import { ROOT_ROUTE } from "../../../../routes/routes.config";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { AuthMessage } from "../../../../constants/api.message";
import InputPasswordField from "../../../../components/Form/InputPasswordField/InputPasswordField";
import { PathsEnum } from "../../../../constants/paths";
import logo from "../../../../assets/logo.png";
import { clsx } from "clsx";

const RegisterScreen: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (token) {
    history.push(PathsEnum.CHAT);
  }

  const handleRegister = (values: any) => {
    dispatch(
      register({
        fullname: values.fullname,
        email: values.email,
        password: values.password,
      })
    )
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
        if (data.apiMessage.Type === "success") {
          setTimeout(() => history.push(PathsEnum.LOGIN), 1000);
        }
      })
      .catch(() => console.log("Register fail"));
  };

  return (
    <div>
      <div className={clsx(classes.loginScreen, classes.register)}>
        <img alt="logo" src={logo} className={classes.logo} />
        <div className={classes.title}>Project manager</div>
        <div className={classes.loginText}>Đăng ký</div>
        <Formik
          initialValues={registerInitValue}
          validationSchema={registerValidateSchema}
          onSubmit={handleRegister}
        >
          {({ values, isValid }) => (
            <Form>
              <InputField
                type="text"
                name="fullname"
                placeholder="Họ và tên"
                label="Họ và tên"
                required
              />
              <InputField
                type="email"
                name="email"
                placeholder="Email"
                label="Email"
                required
              />
              <InputPasswordField
                name="password"
                placeholder="Mật khẩu"
                label="Mật khẩu"
                required
              />
              <InputPasswordField
                name="confirmPassword"
                placeholder="Mật khẩu"
                label="Nhập lại mật khẩu"
                required
              />
              {apiMessage?.Type === "error" && (
                <div className={classes.authError}>
                  {AuthMessage[apiMessage.Code]}
                </div>
              )}
              <InputField
                type="submit"
                name="submit"
                value="Đăng ký"
                disabled={!values.password.trim() || !isValid}
              />
            </Form>
          )}
        </Formik>
        <div className={classes.introText}>
          Bạn đã có tài khoản?&nbsp;
          <Link to={PathsEnum.LOGIN} className={classes.registerLink}>
            Đăng nhập
          </Link>
          &nbsp;ngay!
        </div>
      </div>
      {apiMessage?.Type === "success" && (
        <NotifyAlert
          type={apiMessage.Type}
          message={AuthMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
    </div>
  );
};

export default memo(RegisterScreen);
