import { FC } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.css";
import classes from "../Form/InputField/InputField.module.css";

interface TextEditorProps {
  label?: string;
  placeholder?: string;
  required?: boolean;
  text: string;
  setText: (text: string) => void;
}

const TextEditor: FC<TextEditorProps> = ({
  text,
  setText,
  label,
  placeholder,
  required = false,
}) => {
  return (
    <>
      {label && (
        <div className={classes.label}>
          {label}
          {required && <span className={classes.required}>*</span>}
        </div>
      )}
      <div className={"wrapper"}>
        <ReactQuill
          theme="snow"
          value={text}
          onChange={setText}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

export default TextEditor;
