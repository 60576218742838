import { FC, useEffect, Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../../components/Header/Header";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import ProjectSidebar from "../../components/ProjectSidebar/ProjectSidebar";
import { getProject } from "../../redux/project.slice";
import classes from "./ProjectDetailScreen.module.css";
import { RootState } from "../../../../redux/store";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPen,
  faTrashAlt,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import Popup from "../../../../components/Popup/Popup";
import ConfirmPopup from "../../../../components/ConfirmPopup/ConfirmPopup";
import { deleteFeature, deleteProject } from "../../redux/project.slice";
import { useHistory } from "react-router-dom";
import { LOGIN_ROUTE, ROOT_ROUTE } from "../../../../routes/routes.config";
import { ApiMessageType } from "../../../auth/types/auth.types";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { ProjectMessage } from "../../../../constants/api.message";
import { projectCreatedDate } from "../../helpers/project.helpers";
import { FeatureType } from "../../types/project.types";
import clsx from "clsx";
import AddOrEditFeature from "../../components/AddOrEditFeature/AddOrEditFeature";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { PathsEnum } from "../../../../constants/paths";
import EditProject from "../../components/EditProject/EditProject";
import { ActionEnum } from "../../../../constants/enum";
import EditHistory from "../../components/EditHistory/EditHistory";
import { LocalStorage } from "../../../../helpers/localStorage";

export const memberRoles = [
  {
    id: 1,
    value: "Quản lý",
  },
  {
    id: 2,
    value: "Thành viên",
  },
];

const ProjectDetailScreen: FC = () => {
  const { id } = useParams<{ id: string }>();
  const iOS = /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }
  const project = useAppSelector((state: RootState) => state.project.project);
  const users = useAppSelector((state: RootState) => state.chat.users);
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const isDisplayCreateFeature = LocalStorage.getCreateFeature();
  const [displayCreateFeature, setDisplayCreateFeature] = useState(
    isDisplayCreateFeature || false
  );
  const [displayEditProject, setDisplayEditProject] = useState(false);
  const [displayDeleteProject, setDisplayDeleteProject] = useState(false);
  const [choosenEditFeature, setChoosenEditFeature] = useState<FeatureType>();
  const [chooseDeleteFeature, setChoosenDeleteFeature] =
    useState<FeatureType>();
  const [displayEditHistory, setDisplayEditHistory] = useState(false);

  useEffect(() => {
    if (!project || project.Id !== Number(id)) {
      dispatch(getProject(id))
        .unwrap()
        .then()
        .catch(() => console.log("Get project fail"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [apiMessage, setApiMessage] = useState<ApiMessageType>();

  const isAdmin =
    project?.Members?.find((member) => member.Id === currentUser.Id)?.Role ===
    1;

  const handleDeleteProject = () => {
    dispatch(deleteProject({ Id: project.Id }))
      .unwrap()
      .then((data) => {
        setApiMessage(apiMessage);
        if (data.apiMessage.Type === "success") {
          setTimeout(() => {
            history.push(PathsEnum.PROJECTS);
          }, 1000);
        }
      });
  };

  const handleDeleteFeature = (featureId: number) => {
    setChoosenDeleteFeature(null);
    dispatch(
      deleteFeature({
        Id: featureId,
        ProjectId: project.Id,
      })
    )
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
      })
      .catch(() => console.log("Delete feature fail"));
  };

  return (
    <>
      <Header />
      <div className={classes.projectScreen}>
        <ProjectSidebar />
        <div className={classes.projectDetail}>
          <Breadcrumbs
            linkList={[
              ["Tất cả dự án", PathsEnum.PROJECTS],
              [project?.Name, PathsEnum.PROJECT_DETAIL.replace(":id", id)],
            ]}
          />
          <div className={classes.detailHeader}>
            <div className={classes.title}>Thông tin</div>
            <div className={classes.detailHeaderRight}>
              <button
                className={classes.createFeatureBtn}
                onClick={() => {
                  setDisplayCreateFeature(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Chức năng
              </button>
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                className={clsx(classes.iconItem, classes.iconHistory)}
                onClick={() => {
                  setDisplayEditHistory(true);
                }}
              />
              {isAdmin && (
                <>
                  <FontAwesomeIcon
                    icon={faPen}
                    className={clsx(classes.iconEdit, classes.iconItem)}
                    onClick={() => setDisplayEditProject(true)}
                  />
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={clsx(classes.iconDelete, classes.iconItem)}
                    onClick={() => setDisplayDeleteProject(true)}
                  />
                  <div className={classes.edit}>Chỉnh sửa</div>
                  <div className={classes.delete}>Xóa</div>
                </>
              )}
            </div>
          </div>
          <div className={classes.detailBody}>
            <div className={classes.baseInfoWrapper}>
              <div className={classes.projectNameWrapper}>
                <span className={classes.label}>Tên dự án: </span>
                <span className={clsx(classes.content, classes.projectName)}>
                  {project?.Name}
                </span>
              </div>
              <div className={classes.projectProcessWrapper}>
                <span className={classes.label}>Tiến độ dự án: </span>
                <span className={classes.process}>{project?.Process}%</span>
              </div>
              <div className={classes.descriptionWrapper}>
                <div className={classes.label}>Mô tả</div>
                <div className={clsx(classes.content, classes.description)}>
                  {project?.Description === "<p><br></p>" ||
                  !project?.Description ? (
                    "Chưa có mô tả."
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: project?.Description,
                      }}
                    ></span>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.featuresWrapper}>
              <div className={classes.label}></div>
              {project?.Features?.length > 0 ? (
                <table
                  className={clsx(classes.featureTable, { [classes.ios]: iOS })}
                >
                  <thead>
                    <tr>
                      {/* <th className={classes.dropdown}></th> */}
                      <th className={classes.name}>Tên chức năng</th>
                      {/* <th className={classes.description}>Mô tả</th> */}
                      <th className={classes.createdUser}>Người tạo</th>
                      <th className={classes.createdDate}>Ngày tạo</th>
                      <th className={classes.action}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {project.Features.map((feature, id) => {
                      const createdUser = users.find(
                        (user) => user.Id === feature.CreatedBy
                      );
                      return (
                        <Fragment key={feature.Id}>
                          <tr
                            className={classes.featureItem}
                            onClick={() => {
                              history.push(
                                PathsEnum.FEATURE.replace(
                                  ":id",
                                  `${project.Id}`
                                ).replace(":featureId", `${feature.Id}`)
                              );
                            }}
                          >
                            <td className={classes.name}>{feature.Name}</td>
                            {/* <td className={classes.description}>
                              {feature.Description}
                            </td> */}
                            <td className={classes.createdUser}>
                              <div className={classes.nameWrapper}>
                                <img
                                  alt="avt"
                                  src={getAvatarSrc(createdUser?.Avatar)}
                                  className={classes.avatar}
                                />
                                <div className={classes.username}>
                                  {createdUser?.Fullname}
                                </div>
                              </div>
                            </td>
                            <td className={classes.createdDate}>
                              {projectCreatedDate(feature.CreatedDate)}
                            </td>
                            {isAdmin ? (
                              <td className={classes.action}>
                                <div className={classes.actionGroup}>
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    size="sm"
                                    className={clsx(
                                      classes.iconItem,
                                      classes.iconEditProject
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setChoosenEditFeature(feature);
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="sm"
                                    className={clsx(
                                      classes.iconItem,
                                      classes.iconDeleteProject
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setChoosenDeleteFeature(feature);
                                    }}
                                  />
                                </div>
                                <div className={classes.editProject}>
                                  Chỉnh sửa
                                </div>
                                <div className={classes.deleteProject}>Xóa</div>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                  <button
                    className={classes.createFeature}
                    onClick={() => setDisplayCreateFeature(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" /> Thêm
                  </button>
                </table>
              ) : (
                <div className={classes.unfeature}>Không có chức năng nào!</div>
              )}
            </div>
          </div>
          {displayCreateFeature && (
            <Popup
              width="600px"
              title="Tạo chức năng mới"
              onClose={() => {
                setDisplayCreateFeature(false);
                LocalStorage.removeCreateFeature();
              }}
            >
              <AddOrEditFeature
                type={ActionEnum.ADD}
                onClosePopup={() => {
                  setDisplayCreateFeature(false);
                  LocalStorage.removeCreateFeature();
                }}
                setApiMessage={setApiMessage}
              />
            </Popup>
          )}
          {displayEditProject && (
            <Popup
              title="Chỉnh sửa"
              width="600px"
              onClose={() => setDisplayEditProject(false)}
            >
              <EditProject
                project={project}
                onClosePopup={() => setDisplayEditProject(false)}
                setApiMessage={setApiMessage}
              />
            </Popup>
          )}
          {displayDeleteProject && (
            <ConfirmPopup
              title={`Chắc chắn muốn xóa ${project.Name}?`}
              onClose={() => setDisplayDeleteProject(false)}
              onOK={handleDeleteProject}
            />
          )}
        </div>
      </div>
      {choosenEditFeature?.Id && (
        <Popup
          width="600px"
          title="Chỉnh sửa"
          height="fit-content"
          onClose={() => setChoosenEditFeature(null)}
        >
          <AddOrEditFeature
            type={ActionEnum.EDIT}
            choosenFeature={choosenEditFeature}
            onClosePopup={() => setChoosenEditFeature(null)}
            setApiMessage={setApiMessage}
          />
        </Popup>
      )}
      {chooseDeleteFeature && (
        <ConfirmPopup
          title={`Tất cả các sub-feature và task thuộc ${chooseDeleteFeature.Name} cũng sẽ bị xóa. Chắc chắn muốn xóa?`}
          onClose={() => setChoosenDeleteFeature(null)}
          onOK={() => handleDeleteFeature(chooseDeleteFeature.Id)}
        />
      )}
      {displayEditHistory && (
        <Popup
          title="Lịch sử chỉnh sửa"
          width="600px"
          onClose={() => setDisplayEditHistory(false)}
        >
          <EditHistory projectId={id} users={users} />
        </Popup>
      )}
      {apiMessage && (
        <NotifyAlert
          type={apiMessage.Type}
          message={ProjectMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
    </>
  );
};

export default ProjectDetailScreen;
