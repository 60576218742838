import clsx from "clsx";
import { FC, ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classes from "./Sidebar.module.css";

export interface ItemType {
  id: string;
  name: string;
  isCurrent: boolean;
  path?: string;
}

interface SidebarProps {
  items: ItemType[];
  setItems: (items: ItemType[]) => void;
  children?: ReactNode;
  sidebarName?: string;
}

const Sidebar: FC<SidebarProps> = ({ items, setItems, children }) => {
  const history = useHistory();
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const newItems = items.map((item) => {
      if (window.location.href.includes(`${item.id}`)) {
        return {
          ...item,
          isCurrent: true,
        };
      } else {
        return { ...item };
      }
    });
    setItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (id: string, path: string) => {
    const newItems = items.map((item) => ({
      ...item,
      isCurrent: item.id === id,
    }));
    setItems(newItems);
    if (path) {
      history.push(path);
    }
  };
  return (
    <div className={classes.sidebar}>
      {children && <>{children}</>}
      {items.map((item) => (
        <div
          key={item.id}
          onClick={() => {
            onClick(item.id, item?.path);
          }}
          className={clsx(classes.sidebarItem, {
            [classes.isCurrent]: item.isCurrent,
          })}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
