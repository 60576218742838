import { FC, ReactNode } from "react";
import { FieldArrayRenderProps } from "formik";
import classes from "./CheckboxField.module.css";

interface CheckboxFieldProps {
  name: string;
  value: string | number;
  checked: boolean;
  label?: string;
  children?: ReactNode;
  values: any;
  arrayHelpers: FieldArrayRenderProps;
}

const CheckboxField: FC<CheckboxFieldProps> = ({
  name,
  value,
  checked,
  label,
  children,
  values,
  arrayHelpers,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) arrayHelpers.push(`${e.target.value}`);
    else {
      const idx = values.indexOf(`${e.target.value}`);
      arrayHelpers.remove(idx);
    }
  };

  return (
    <label className={classes.label} style={{ cursor: "pointer" }}>
      <input
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        style={{ cursor: "pointer" }}
      />
      {label || children}
    </label>
  );
};

export default CheckboxField;
