import { FC, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import {
  convertDateFormat,
  newTaskSchema,
  onEnterForm,
} from "../../helpers/project.helpers";
import classes from "./AddOrEditTask.module.css";
import InputField from "../../../../components/Form/InputField/InputField";
import TextareaField from "../../../../components/Form/TextareaField/TextareaField";
import SelectField from "../../../../components/Form/SelectField/SelectField";
import UploadFiles from "../../../../components/UploadFiles/UploadFiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import SearchAndChooseUser from "../SearchAndChooseUser/SearchAndChooseUser";
import { RootState } from "../../../../redux/store";
import { UserState } from "../../../profile/types/profile.types";
import { useHistory, useParams } from "react-router-dom";
import { createTask, getFeature, getProjects } from "../../redux/project.slice";
import { PathsEnum } from "../../../../constants/paths";
import { TaskDetailType } from "../../types/project.types";
import { editTask } from "./../../redux/project.slice";
import { ApiMessageType } from "./../../../auth/types/auth.types";
import CancelButton from "../../../../components/Button/CancelButton/CancelButton";
import { MessageType } from "../../../chat/types/chat.types";
import ChooseTaskParent from "../ChooseTaskParent/ChooseTaskParent";
import { filterContainUsers } from "../../../chat/helpers/chat.helpers";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import { ActionEnum } from "../../../../constants/enum";
import InputNumberField from "../../../../components/Form/InputNumberField/InputNumberField";

export const TaskStatus = [
  {
    id: 1,
    value: "To do",
    bgColor: "#009EF7",
  },
  {
    id: 2,
    value: "Developing",
    bgColor: "#009EF7",
  },
  {
    id: 3,
    value: "Developed",
    bgColor: "#00b59c",
  },
  {
    id: 4,
    value: "Testing",
    bgColor: "#009EF7",
  },
  {
    id: 5,
    value: "Done",
    bgColor: "#50CD89",
  },
];

interface AddOrEditTaskProps {
  //type=1: add, type=2: edit
  type: number;
  currentTask?: TaskDetailType;
  message?: MessageType;
  choosenUserId?: number;
  hasChooseParent?: boolean;
  onClosePopup: () => void;
  setApiMessage?: (message: ApiMessageType) => void;
  hasLargeFile: boolean;
  setHasLargeFile: (bool: boolean) => void;
}

const AddOrEditTask: FC<AddOrEditTaskProps> = ({
  type,
  currentTask,
  message,
  choosenUserId,
  hasChooseParent = true,
  onClosePopup,
  setApiMessage,
  hasLargeFile,
  setHasLargeFile,
}) => {
  const { id, featureId } = useParams<{ id: string; featureId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const users = useAppSelector((state: RootState) => state.chat.users);
  const project = useAppSelector((state: RootState) => state.project.project);
  const projects = useAppSelector(
    (state: RootState) => state.project.listProject
  );
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const assignee = users.find(
    (user) => user.Id === choosenUserId || user.Id === currentTask?.Assignee
  );

  const [files, setFiles] = useState<File[]>([]);
  const [sizeOfFiles, setSizeOfFiles] = useState<string[]>([]);
  const [filesUrl, setFilesUrl] = useState([]);
  const [dragFile, setDragFile] = useState(false);
  const inputTextRef = useRef<HTMLTextAreaElement>();

  const [choosenUser, setChoosenUser] = useState<UserState>(assignee);
  const [choosenProjectId, setchoosenProjectId] = useState("");
  const [choosenFeatureId, setchoosenFeatureId] = useState("");
  const [description, setDescription] = useState(
    type === ActionEnum.ADD ? message?.Message || "" : currentTask?.Description
  );

  const newTaskInitValues = {
    name: "",
    status: "",
    deadline: "",
    process: 0,
  };

  const editTaskInitValues = {
    name: currentTask?.Name,
    status: currentTask?.Status || "",
    deadline: convertDateFormat(currentTask?.Deadline) || "",
    process: currentTask?.Process || 0,
  };

  useEffect(() => {
    if (!projects) {
      dispatch(getProjects(currentUser.Id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragFile = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragFile(true);
    }
    if (event.type === "dragleave") {
      setDragFile(false);
    }
  };

  const onRemoveFile = (fileId: number) => {
    const newFiles = files.filter((_file, id) => id !== fileId);
    const newFilesUrl = filesUrl.filter((_file, id) => id !== fileId);
    setFiles(newFiles);
    setFilesUrl(newFilesUrl);
  };

  const handleCreateTask = (values: any) => {
    const formData = new FormData();
    if (currentTask?.Id) {
      formData.append("Id", `${currentTask?.Id}`);
    }
    formData.append("ProjectId", choosenProjectId || id);
    formData.append("FeatureId", choosenFeatureId || featureId);
    formData.append("MessageId", message?.Id ? `${message?.Id}` : "0");
    formData.append("CreatedBy", `${currentUser.Id}`);
    formData.append("Name", values.name);
    formData.append("Status", values.status || 1);
    formData.append("Deadline", values.deadline);
    formData.append("Assignee", `${choosenUser.Id}`);
    formData.append("Process", `${values.process}`);
    formData.append("Description", description);
    formData.append("ChangedBy", `${currentUser.Id}`);
    if (files.length > 0) {
      files.map((file) => formData.append("Files", file));
      sizeOfFiles?.map((size) => formData.append("SizeOfFiles", size));
    }
    if (type === ActionEnum.ADD) {
      dispatch(createTask(formData))
        .unwrap()
        .then((data) => {
          if (data.apiMessage.Type === "success") {
            onClosePopup();
            if (!hasLargeFile) {
              history.push(
                PathsEnum.TASK.replace(":id", choosenProjectId || id)
                  .replace(":featureId", choosenFeatureId || featureId)
                  .replace(":taskId", data.task.Id)
              );
            }
            dispatch(getFeature(Number(choosenFeatureId || featureId)));
          }
        });
    } else {
      dispatch(editTask(formData))
        .unwrap()
        .then((data) => {
          if (data.apiMessage.Type === "success") {
            onClosePopup();
            dispatch(getFeature(Number(featureId)));
          }
        });
    }
  };

  const onClickChooseUser = () => {
    const choosenProject = projects?.find(
      (project) => `${project.Id}` === choosenProjectId
    );
    if (choosenProject) {
      const choosenProjectMember = filterContainUsers(
        users,
        choosenProject?.MemberIds
      );
      return choosenProjectMember;
    }
    if (project) {
      return project?.Members;
    }
  };

  return (
    <div className={classes.form}>
      <Formik
        initialValues={
          type === ActionEnum.ADD ? newTaskInitValues : editTaskInitValues
        }
        validationSchema={newTaskSchema}
        onSubmit={handleCreateTask}
      >
        {({ values, isSubmitting }) => (
          <Form onDragEnter={onDragFile} onKeyDown={onEnterForm}>
            {hasChooseParent && (
              <div>
                <ChooseTaskParent
                  projects={projects}
                  projectId={choosenProjectId}
                  setProjectId={setchoosenProjectId}
                  setFeatureId={setchoosenFeatureId}
                  onCloseTaskPopup={onClosePopup}
                />
              </div>
            )}
            <InputField
              type="text"
              name="name"
              placeholder="Tên task"
              label="Tên task"
              required
            />
            <div style={{ display: "flex", gap: "4%" }}>
              <SelectField
                name="status"
                width="100%"
                options={TaskStatus}
                label="Trạng thái"
              />
              <InputNumberField
                name="process"
                placeholder="Tiến độ"
                label="Tiến độ"
                width="100%"
                min={0}
                max={100}
              />
            </div>
            <SearchAndChooseUser
              label="Người thực hiện"
              required
              choosenUser={choosenUser}
              setChoosenUser={setChoosenUser}
              users={project?.Members || users}
              hasRemoveIcon={true}
              onClickChooseUser={onClickChooseUser}
            />
            <InputField
              type="date"
              name="deadline"
              label="Hạn thực hiện"
              width="100%"
            />
            <TextEditor
              text={description}
              setText={setDescription}
              placeholder="Mô tả"
              label="Mô tả"
            />
            <UploadFiles
              label="Tài liệu"
              files={files}
              setFiles={setFiles}
              sizeOfFiles={sizeOfFiles}
              setSizeOfFiles={setSizeOfFiles}
              filesUrl={filesUrl}
              setFilesUrl={setFilesUrl}
              dragFile={dragFile}
              setDragFile={setDragFile}
              handleDrag={onDragFile}
              inputTextRef={inputTextRef}
              setHasLargeFile={setHasLargeFile}
            >
              {files?.length > 0 && (
                <div
                  className={classes.documentPreview}
                  onClick={(e) => e.stopPropagation()}
                >
                  {filesUrl.map((file, id) => (
                    <div key={file.fileUrl} className={classes.documentItem}>
                      <div className={classes.documentName}>{file.name}</div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="sm"
                        className={classes.iconItem}
                        onClick={() => {
                          onRemoveFile(id);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <button
                className={classes.addDocumentBtn}
                onClick={(e) => e.preventDefault()}
              >
                <FontAwesomeIcon icon={faPlus} /> Thêm
              </button>
            </UploadFiles>
            <div className={classes.buttonGroup}>
              <CancelButton label="Hủy" onClick={onClosePopup} />
              <InputField
                type="submit"
                name="submit"
                value={type === ActionEnum.ADD ? "Tạo" : "Lưu"}
                width="120px"
                disabled={
                  (hasChooseParent && !choosenProjectId) ||
                  (hasChooseParent && !choosenFeatureId) ||
                  !values?.name.trim() ||
                  !choosenUser?.Id ||
                  isSubmitting
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditTask;
