import { GetEditHistoryRequest } from "./../types/project.types";
import { EndpointsEnum } from "./../../../constants/endpoints";
import { api, fileApi } from "../../../api/api";
import {
  GetProjectsResponse,
  GetProjectResponse,
  CreateProjectRequest,
  CreateProjectResponse,
  EditProjectResponse,
  DeleteProjectRequest,
  DeleteProjectResponse,
  AddMemberProjectRequest,
  AddMemberProjectResponse,
  DeleteMemberProjectRequest,
  DeleteMemberProjectResponse,
  CreateFeatureRequest,
  CreateFeatureResponse,
  EditFeatureRequest,
  EditFeatureResponse,
  DeleteFeatureRequest,
  DeleteFeatureResponse,
  GetFeatureResponse,
  GetProjectDocumentResponse,
  GetTaskResponse,
  CreateTaskResponse,
  DeleteDocumentRequest,
  DeleteDocumentResponse,
  GetTasksResponse,
  DeleteTaskRequest,
  DeleteTaskResponse,
  EditTaskResponse,
  SearchProjectsResponse,
  SearchProjectsRequest,
  SearchTasksRequest,
  SearchTasksResponse,
  AddProjectDocumentResponse,
  GetAllFeatureResponse,
  GetEditHistoryResponse,
} from "../types/project.types";

const getProjectsApi = (data: number): Promise<GetProjectsResponse> => {
  return api.get(`${EndpointsEnum.GET_PROJECTS}?id=${data}`);
};

const getProjectApi = (data: string): Promise<GetProjectResponse> => {
  return api.get(`${EndpointsEnum.GET_PROJECT}?id=${data}`);
};

const createProjectApi = (
  data: CreateProjectRequest
): Promise<CreateProjectResponse> => {
  return api.post(EndpointsEnum.CREATE_PROJECT, data);
};

const editProjectApi = (data: FormData): Promise<EditProjectResponse> => {
  return fileApi.post(EndpointsEnum.EDIT_PROJECT, data);
};

const deleteProjectApi = (
  data: DeleteProjectRequest
): Promise<DeleteProjectResponse> => {
  return api.post(EndpointsEnum.DELETE_PROJECT, data);
};

const createFeatureApi = (
  data: CreateFeatureRequest
): Promise<CreateFeatureResponse> => {
  return api.post(EndpointsEnum.CREATE_FEATURE, data);
};

const getFeatureApi = (data: number): Promise<GetFeatureResponse> => {
  return api.get(`${EndpointsEnum.GET_FEATURE}?id=${data}`);
};

const editFeatureApi = (
  data: EditFeatureRequest
): Promise<EditFeatureResponse> => {
  return api.post(EndpointsEnum.EDIT_FEATURE, data);
};

const deleteFeatureApi = (
  data: DeleteFeatureRequest
): Promise<DeleteFeatureResponse> => {
  return api.post(EndpointsEnum.DELETE_FEATURE, data);
};

const addMemberProjectApi = (
  data: AddMemberProjectRequest
): Promise<AddMemberProjectResponse> => {
  return api.post(EndpointsEnum.ADD_MEMBER_PROJECT, data);
};

const editMemberProjectApi = (
  data: AddMemberProjectRequest
): Promise<AddMemberProjectResponse> => {
  return api.post(EndpointsEnum.EDIT_MEMBER_PROJECT, data);
};

const deleteMemberProjectApi = (
  data: DeleteMemberProjectRequest
): Promise<DeleteMemberProjectResponse> => {
  return api.post(EndpointsEnum.DELETE_MEMBER_PROJECT, data);
};

const getProjectDocumentApi = (
  data: string
): Promise<GetProjectDocumentResponse> => {
  return api.get(`${EndpointsEnum.GET_PROJECT_DOCUMENT}?id=${data}`);
};

const getTasksApi = (data: number): Promise<GetTasksResponse> => {
  return api.get(`${EndpointsEnum.GET_TASKS}?id=${data}`);
};

const getTaskApi = (data: number): Promise<GetTaskResponse> => {
  return api.get(`${EndpointsEnum.GET_TASK}?id=${data}`);
};

const createTaskApi = (data: FormData): Promise<CreateTaskResponse> => {
  return fileApi.post(EndpointsEnum.CREATE_TASK, data);
};

const editTaskApi = (data: FormData): Promise<EditTaskResponse> => {
  return fileApi.post(EndpointsEnum.EDIT_TASK, data);
};

const deleteTaskApi = (
  data: DeleteTaskRequest
): Promise<DeleteTaskResponse> => {
  return api.post(EndpointsEnum.DELETE_TASK, data);
};

const deleteTaskDocumentApi = (
  data: DeleteDocumentRequest
): Promise<DeleteDocumentResponse> => {
  return api.post(EndpointsEnum.DELETE_TASK_DOCUMENT, data);
};

const downloadFileApi = (path: string) => {
  return api.get(`${EndpointsEnum.DOWNLOAD_FILE}?path=${path}`, {
    responseType: "blob",
  });
};

const searchProjectsApi = (
  data: SearchProjectsRequest
): Promise<SearchProjectsResponse> => {
  let apiUrl = `${EndpointsEnum.SEARCH_PROJECTS}?userId=${data.userId}&keyword=${data.keyword}`;
  data.pageSize && (apiUrl = apiUrl + `&pageSize=${data.pageSize}`);
  data.currentPage && (apiUrl = apiUrl + `&currentPage=${data.currentPage}`);
  return api.get(apiUrl);
};

const searchTasksApi = (
  data: SearchTasksRequest
): Promise<SearchTasksResponse> => {
  let apiUrl = `${EndpointsEnum.SEARCH_TASKS}?userId=${data.userId}&keyword=${data.keyword}`;
  data.pageSize && (apiUrl = apiUrl + `&pageSize=${data.pageSize}`);
  data.currentPage && (apiUrl = apiUrl + `&currentPage=${data.currentPage}`);
  return api.get(apiUrl);
};

const addProjectDocumentApi = (
  data: FormData
): Promise<AddProjectDocumentResponse> => {
  return api.post(EndpointsEnum.ADD_PROJECT_DOCUMENT, data);
};

const deleteProjectDocumentApi = (
  data: DeleteDocumentRequest
): Promise<DeleteDocumentResponse> => {
  return api.post(EndpointsEnum.DELETE_PROJECT_DOCUMENT, data);
};

const getAllFeatureApi = (data: string): Promise<GetAllFeatureResponse> => {
  return api.get(`${EndpointsEnum.GET_ALL_FEATURE}?id=${data}`);
};

const getProjectEditHistoryApi = (
  data: GetEditHistoryRequest
): Promise<GetEditHistoryResponse> => {
  let apiUrl = `${EndpointsEnum.GET_PROEJCT_EDIT_HISTORY}?projectId=${data.ProjectId}`;
  data.CurrentPage && (apiUrl = apiUrl + `&currentPage=${data.CurrentPage}`);
  return api.get(apiUrl);
};

const getFeatureEditHistoryApi = (
  data: GetEditHistoryRequest
): Promise<GetEditHistoryResponse> => {
  let apiUrl = `${EndpointsEnum.GET_FEATURE_EDIT_HISTORY}?featureId=${data.FeatureId}`;
  data.CurrentPage && (apiUrl = apiUrl + `&currentPage=${data.CurrentPage}`);
  return api.get(apiUrl);
};

const getTaskEditHistoryApi = (
  data: GetEditHistoryRequest
): Promise<GetEditHistoryResponse> => {
  let apiUrl = `${EndpointsEnum.GET_TASK_EDIT_HISTORY}?taskId=${data.TaskId}`;
  data.CurrentPage && (apiUrl = apiUrl + `&currentPage=${data.CurrentPage}`);
  return api.get(apiUrl);
};

export const projectApi = {
  getProjectsApi,
  getProjectApi,
  createProjectApi,
  editProjectApi,
  deleteProjectApi,
  createFeatureApi,
  getFeatureApi,
  editFeatureApi,
  deleteFeatureApi,
  addMemberProjectApi,
  editMemberProjectApi,
  deleteMemberProjectApi,
  getProjectDocumentApi,
  createTaskApi,
  getTasksApi,
  getTaskApi,
  editTaskApi,
  deleteTaskApi,
  deleteTaskDocumentApi,
  downloadFileApi,
  searchProjectsApi,
  searchTasksApi,
  addProjectDocumentApi,
  deleteProjectDocumentApi,
  getAllFeatureApi,
  getProjectEditHistoryApi,
  getFeatureEditHistoryApi,
  getTaskEditHistoryApi,
};
