import * as Yup from "yup";

export const fullnameRegExp =
  /^([a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]+)$/i;
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginInitValues = {
  email: "",
  password: "",
};

export const registerInitValue = {
  fullname: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const forgetPasswordInitValues = {
  email: "",
};

export const resetPasswordInitValues = {
  password: "",
  confirmPassword: "",
};

const fullname = Yup.string()
  .required("Không bỏ trống trường này")
  .matches(fullnameRegExp, "Không nhập ký tự đặc biệt");
const email = Yup.string()
  .email("Nhập đúng định dạng email")
  .required("Không bỏ trống trường này");
export const password = Yup.string()
  .required("Không bỏ trống trường này")
  .min(8, "Nhập tối thiểu 8 kí tự");
export const confirmPassword = Yup.string()
  .required("Mật khẩu không khớp")
  .oneOf([Yup.ref("password")], "Mật khẩu không khớp");

export const loginValidateSchema = Yup.object({
  email,
  password,
});

export const registerValidateSchema = Yup.object({
  fullname,
  email,
  password,
  confirmPassword,
});

export const forgetPasswordSchema = Yup.object({
  email,
});

export const resetPasswordSchema = Yup.object({
  password,
  confirmPassword,
});
