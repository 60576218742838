import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useRef, useState } from "react";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import SearchUser from "../../../chat/components/SearchUser/SearchUser";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import { UserState } from "../../../profile/types/profile.types";
import classes from "./SearchAndChooseUser.module.css";

interface SearchAndChooseUserProps {
  label?: string;
  required?: boolean;
  multiple?: boolean;
  choosenUser: UserState;
  setChoosenUser: (choosenUser: UserState) => void;
  users: UserState[];
  hasRemoveIcon: boolean;
  onClickChooseUser?: () => UserState[];
}

const SearchAndChooseUser: FC<SearchAndChooseUserProps> = ({
  label,
  required = false,
  multiple = false,
  choosenUser,
  setChoosenUser,
  users,
  hasRemoveIcon,
  onClickChooseUser,
}) => {
  const [keyword, setKeyword] = useState("");
  const [usersFilter, setUsersFilter] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  const searchInputContainerRef = useOutsideClick(() => setUsersFilter([]));
  const searchResultRef = useRef<HTMLDivElement>();
  const newMembers = onClickChooseUser ? onClickChooseUser() : users;

  const onBlurInput = () => {
    if (required && !searchResultRef.current && !choosenUser) {
      setIsRequired(true);
    }
  };

  return (
    <>
      {label && (
        <div className={classes.label}>
          {label}
          {required && <span className={classes.required}>*</span>}
        </div>
      )}
      {choosenUser ? (
        <div className={classes.choosenUser}>
          <div className={classes.nameWrapper}>
            <img
              alt="avt"
              src={getAvatarSrc(choosenUser.Avatar)}
              className={classes.avatar}
            />
            <div className={classes.name}>
              <div>{choosenUser.Fullname}</div>
              <div>{choosenUser.Email}</div>
            </div>
          </div>
          {hasRemoveIcon && (
            <FontAwesomeIcon
              icon={faTimes}
              className={classes.removeIcon}
              onClick={() => {
                setChoosenUser(null);
                setKeyword("");
              }}
            />
          )}
        </div>
      ) : (
        <div
          className={classes.searchUserContainer}
          ref={searchInputContainerRef}
        >
          <SearchUser
            keyword={keyword}
            setKeyword={setKeyword}
            users={users}
            setUsersFilter={setUsersFilter}
            onBlur={onBlurInput}
            onClick={() => setUsersFilter(newMembers)}
          />
          {usersFilter?.length > 0 && (
            <div className={classes.searchResult} ref={searchResultRef}>
              {usersFilter.map((user) => (
                <div
                  key={user.Id}
                  className={classes.nameWrapper}
                  onClick={() => {
                    setChoosenUser(user);
                    setKeyword("");
                    setUsersFilter(newMembers);
                    setIsRequired(false);
                  }}
                >
                  <img
                    alt="avt"
                    src={getAvatarSrc(user.Avatar)}
                    className={classes.avatar}
                  />
                  <div className={classes.name}>
                    <div className={classes.fullname}>{user.Fullname}</div>
                    <div className={classes.email}>{user.Email}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {isRequired && (
        <p className={classes.wrongInput}>Không bỏ trống trường này</p>
      )}
    </>
  );
};

export default SearchAndChooseUser;
