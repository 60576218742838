import { FC, memo, useRef, useState } from "react";
import { useField } from "formik";
import classes from "./InputField.module.css";
import clsx from "clsx";
import { disableDates, isHasOnlySpaces } from "../../../helpers/helpers";

export interface InputFieldProps {
  name: string;
  type: string;
  label?: string;
  value?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  width?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showdate?: string;
  disabled?: boolean;
}

const InputField: FC<InputFieldProps> = (props) => {
  const [field, meta] = useField(props);
  const inputRef = useRef<HTMLInputElement>();
  const [isOnBlur, setIsOnBlur] = useState(false);
  return (
    <div className={classes.inputWrapper} style={{ width: props.width }}>
      {props.label && (
        <div className={classes.label}>
          {props.label}
          {props.required && <span className={classes.required}>*</span>}
        </div>
      )}
      <div className={clsx(props.name === "process" && classes.inputField)}>
        <input
          ref={inputRef}
          // min={props.type === "date" ? disableDates() : undefined}
          data-date-format={props.type === "date" ? "YYYY-MM-DD" : undefined}
          className={clsx(classes.input, {
            [classes.submitBtn]: props.type === "submit",
            [classes.dateInput]: props.type === "date",
            [classes.disabled]: props.disabled,
          })}
          disabled={props.type === "submit" ? props.disabled : undefined}
          {...field}
          {...props}
          autoComplete="off"
          onBlur={(e) => {
            field.onBlur(e);
            setIsOnBlur(true);
          }}
          onChange={(e) => {
            field.onChange(e);
            if (props.type === "date") {
              inputRef.current.min = disableDates();
            }
          }}
        />
        {props.name === "process" && <span className={classes.process}>%</span>}
      </div>

      {meta.touched && meta.error ? (
        <p className={classes.wrongInput}>{meta.error}</p>
      ) : (
        <p className={classes.rightInput}>{props.hint}</p>
      )}

      {props.required &&
        isOnBlur &&
        field.value.length > 0 &&
        isHasOnlySpaces(field.value) && (
          <p className={classes.wrongInput}>Nhập ký tự khác khoảng trắng</p>
        )}
    </div>
  );
};

export default memo(InputField);
