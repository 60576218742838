import React, { FC, ReactElement, useEffect, useState } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import classes from "./NotifyAlert.module.css";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NotifyAlertProps {
  type: string;
  message?: string;
  children?: ReactElement;
  setApiMessage: any;
}

const NotifyAlert: FC<NotifyAlertProps> = ({
  type,
  message,
  children,
  setApiMessage,
}) => {
  const [isDisplay, setIsDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsDisplay(false);
      setApiMessage(null);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAlert = () => {
    return React.cloneElement(children);
  };

  return (
    <div
      className={clsx(classes.alert, {
        [classes.error]: type === "error",
        [classes.success]: type === "success",
        [classes.hide]: !isDisplay,
      })}
    >
      <FontAwesomeIcon
        icon={type === "success" ? faCheck : faTimes}
        className={clsx({
          [classes.errorIcon]: type === "error",
          [classes.successIcon]: type === "success",
        })}
      />
      <span className={classes.message}>
        {children ? renderAlert() : message}
      </span>
      {/* <FontAwesomeIcon
        icon={faTimes}
        onClick={handleClose}
        className={classes.closeBtn}
      /> */}
    </div>
  );
};

export default NotifyAlert;
