import { FC, Fragment, useState } from "react";
import { getFileTypes } from "../../../../helpers/helpers";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import {
  faPlayCircle,
  faFile,
  faDownload,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./ProjectDocumentList.module.css";
import ConfirmPopup from "../../../../components/ConfirmPopup/ConfirmPopup";
import { useAppDispatch } from "../../../../hooks/hooks";
import { deleteProjectDocument, downloadFile } from "../../redux/project.slice";
import { ApiMessageType } from "../../../auth/types/auth.types";
import { ProjectDocumentType } from "../../types/project.types";
import ImageView from "../../../../components/ImageView/ImageView";
import { HOST } from "../../../../constants/variable";
import VideoView from "../../../../components/VideoView/VideoView";
import { clsx } from "clsx";
import { FileTypeEnum } from "../../../../constants/enum";
import FileView from "../../../../components/FileView/FileView";

interface DocumentListProps {
  list: ProjectDocumentType[];
  setApiMessage?: (message: ApiMessageType) => void;
}

const ProjectDocumentList: FC<DocumentListProps> = ({
  list,
  setApiMessage,
}) => {
  const dispatch = useAppDispatch();
  let fileTypes = getFileTypes(list);
  const [viewDocument, setViewDocument] = useState<ProjectDocumentType>(null);
  const [deleteDocumentId, setDeleteDocumentId] = useState(null);
  const [viewType, setViewType] = useState(0);

  const handleDeleteDocument = () => {
    setDeleteDocumentId(null);
    dispatch(
      deleteProjectDocument({
        Id: deleteDocumentId,
      })
    )
      .unwrap()
      .then((data) => setApiMessage(data.apiMessage))
      .catch(() => console.log("Delete document fail"));
  };

  const handleDownloadFile = (path: string, name: string) => {
    dispatch(downloadFile(path))
      .unwrap()
      .then((response) => {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      });
  };

  return (
    <>
      <div
        className={clsx(
          classes.documentContainer,
          list?.length > 0 && classes.projectDocumentContainer
        )}
      >
        {list?.length > 0 &&
          fileTypes.map((fileType, id) => (
            <Fragment key={list[id].Path}>
              <div
                className={classes.documentItem}
                onClick={() => {
                  setViewDocument(list[id]);
                  setViewType(fileType);
                }}
              >
                <div className={classes.actionGroup}>
                  <div
                    className={classes.actionItem}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownloadFile(list[id]?.Path, list[id].Name);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="lg"
                      className={classes.iconItem}
                    />
                  </div>
                  <div
                    className={classes.actionItem}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteDocumentId(list[id]?.Id);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      size="lg"
                      className={classes.iconItem}
                    />
                  </div>
                </div>
                {fileType === FileTypeEnum.IMAGE ? (
                  <div className={classes.thumbnail}>
                    <img
                      alt="img"
                      src={getAvatarSrc(list[id]?.Path)}
                      className={classes.image}
                    />
                  </div>
                ) : fileType === FileTypeEnum.VIDEO ? (
                  <div className={classes.thumbnail}>
                    <video
                      src={getAvatarSrc(list[id].Path)}
                      className={classes.image}
                    ></video>
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      className={classes.playVideoIcon}
                      size="2x"
                    />
                  </div>
                ) : (
                  <div className={classes.thumbnail}>
                    <FontAwesomeIcon icon={faFile} size="3x" />
                  </div>
                )}
                <div className={classes.documentDetail}>
                  <div className={classes.name}>{list[id]?.Name}</div>
                </div>
              </div>
            </Fragment>
          ))}
      </div>
      {viewDocument?.Id && viewType === FileTypeEnum.IMAGE && (
        <ImageView
          path={`${HOST}${viewDocument.Path}`}
          onClose={() => setViewDocument(null)}
        />
      )}
      {viewDocument?.Id && viewType === FileTypeEnum.VIDEO && (
        <VideoView
          path={`${HOST}${viewDocument.Path}`}
          onClose={() => setViewDocument(null)}
        />
      )}
      {viewDocument?.Id && viewType === FileTypeEnum.OTHER && (
        <FileView
          onClose={() => setViewDocument(null)}
          handleDownloadFile={() =>
            handleDownloadFile(viewDocument.Path, viewDocument.Name)
          }
        />
      )}
      {deleteDocumentId && (
        <ConfirmPopup
          title="Chắc chắn muốn xóa tài liệu này?"
          onClose={() => {
            setDeleteDocumentId(null);
          }}
          onOK={() => {
            handleDeleteDocument();
          }}
        />
      )}
    </>
  );
};

export default ProjectDocumentList;
