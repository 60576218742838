import { FC, memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../features/auth/redux/auth.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { LOGIN_ROUTE } from "../../routes/routes.config";
import classes from "./Header.module.css";
import clsx from "clsx";
import { RootState } from "../../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faAngleDown,
  faUser,
  faSignOut,
  faAngleUp,
  faBell,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import useOutsideClick from "../../hooks/useOutsideClick";
import { getAvatarSrc } from "../../features/chat/helpers/chat.helpers";
import { PathsEnum } from "../../constants/paths";
import { getProjects } from "../../features/project/redux/project.slice";
import ListProjectModal from "../../features/project/components/ListProjectModal/ListProjectModal";
import Popup from "../Popup/Popup";
import NewProject from "../../features/project/components/NewProject/NewProject";
import AddOrEditTask from "../../features/project/components/AddOrEditTask/AddOrEditTask";
import NotifyModal from "../../features/home/components/NotifyModal/NotifyModal";
import { ActionEnum } from "../../constants/enum";

const Header: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const conversations = useAppSelector(
    (state: RootState) => state.chat.conversations
  );
  const listProject = useAppSelector(
    (state: RootState) => state.project.listProject
  );
  const notifications = useAppSelector(
    (state: RootState) => state.home.notifications
  );

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentItem, setCurrentItem] = useState("");
  const [displayProfileModal, setDisplayProfileModal] = useState(false);
  const [displayProjectModal, setDisplayProjectModal] = useState(false);
  const [displayNotifyModal, setDisplayNotifyModal] = useState(false);
  const [displayNavbar, setDisplayNavbar] = useState(false);

  const [displayNewProject, setDisplayNewProject] = useState(false);
  const [displayNewTask, setDisplayNewTask] = useState(false);
  const [hasLargeFile, setHasLargeFile] = useState(false);

  const navbarModal = useOutsideClick(() => setDisplayNavbar(false));
  const profileModalRef = useOutsideClick(() => setDisplayProfileModal(false));
  const projectModalRef = useOutsideClick(() => {
    setDisplayProjectModal(false);
    setCurrentItem("");
  });
  const notifyModalRef = useOutsideClick(() => {
    setDisplayNotifyModal(false);
    setCurrentItem("");
  });

  const [unreadNotifications, setUnreadNotifications] = useState(
    notifications?.filter((noti) => !noti.IsRead)
  );
  const unreadConversations = conversations?.filter(
    (conversation) =>
      conversation?.ReadBy?.filter((userId) => userId === currentUser.Id)
        .length === 0
  );

  useEffect(() => {
    setUnreadNotifications(notifications?.filter((noti) => !noti.IsRead));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  const onClick = (path: string) => {
    history.push(path);
  };

  const onClickProfile = () => {
    setDisplayProfileModal(!displayProfileModal);
  };

  const handleGetProjects = () => {
    if (!listProject) {
      dispatch(getProjects(currentUser.Id))
        .unwrap()
        .then()
        .catch(() => console.log("Get projects fail"));
    }

    setDisplayProjectModal(!displayProjectModal);
  };

  const handleLogout = () => {
    dispatch(logout);
    window.location.href = LOGIN_ROUTE;
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          {screenWidth < 992 && (
            <div ref={navbarModal} className={classes.navbar}>
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                onClick={() => {
                  setDisplayNavbar(true);
                }}
              />
              {displayNavbar && (
                <div className={clsx(classes.modal, classes.navbarModal)}>
                  <div
                    className={classes.modalItem}
                    onClick={() => onClick(PathsEnum.PROJECTS)}
                  >
                    Tất cả dự án
                  </div>
                  <div
                    className={classes.modalItem}
                    onClick={() => onClick(PathsEnum.TASKS)}
                  >
                    Task của tôi
                  </div>
                </div>
              )}
            </div>
          )}
          {screenWidth > 767 && (
            <div
              className={classes.logo}
              onClick={() => onClick(PathsEnum.PROJECTS)}
            >
              Project Manager
            </div>
          )}

          {screenWidth >= 992 && (
            <>
              <div ref={projectModalRef} className={classes.project}>
                <div
                  className={clsx(
                    classes.headerItem,
                    currentItem === "project" && classes.currentItem
                  )}
                  onClick={() => {
                    setCurrentItem("project");
                    handleGetProjects();
                  }}
                >
                  Tất cả dự án
                </div>
                {displayProjectModal && (
                  <ListProjectModal
                    setDisplayProjectModal={setDisplayProjectModal}
                    setDisplayNewProject={setDisplayNewProject}
                  />
                )}
              </div>
              <div
                className={clsx(
                  classes.headerItem,
                  currentItem === "task" && classes.currentItem
                )}
                onClick={() => {
                  setCurrentItem("task");
                  history.push(PathsEnum.TASKS);
                }}
              >
                Task của tôi
              </div>
            </>
          )}
          <div
            className={classes.createTaskBtn}
            onClick={() => setDisplayNewTask(true)}
          >
            Tạo task
          </div>
        </div>

        <div className={classes.headerRight}>
          <div
            className={classes.headerItem}
            onClick={() => {
              setCurrentItem("chat");
              onClick(PathsEnum.CHAT);
            }}
          >
            <div
              className={clsx(
                classes.iconItem,
                currentItem === "chat" && classes.currentItem
              )}
            >
              <FontAwesomeIcon icon={faMessage} size="sm" />
            </div>
            {unreadConversations?.length > 0 && (
              <div
                className={clsx(
                  classes.unreadAmount,
                  unreadConversations?.length < 10
                    ? classes.oneDigit
                    : classes.twoDigit
                )}
              >
                {unreadConversations?.length}
              </div>
            )}
          </div>
          <div
            ref={notifyModalRef}
            className={classes.headerItem}
            onClick={() => {
              setCurrentItem("notification");
              setDisplayNotifyModal(!displayNotifyModal);
            }}
          >
            <div
              className={clsx(
                classes.iconItem,
                currentItem === "notification" &&
                  displayNotifyModal &&
                  classes.currentItem
              )}
            >
              <FontAwesomeIcon icon={faBell} size="1x" />
            </div>
            {unreadNotifications?.length > 0 && (
              <div
                className={clsx(
                  classes.unreadAmount,
                  unreadNotifications?.length < 10
                    ? classes.oneDigit
                    : classes.twoDigit
                )}
              >
                {unreadNotifications?.length}
              </div>
            )}
            {displayNotifyModal && (
              <NotifyModal
                currentUserId={currentUser.Id}
                onCloseModal={() => setDisplayNotifyModal(false)}
              />
            )}
          </div>
          <div ref={profileModalRef}>
            <div className={classes.headerItem} onClick={onClickProfile}>
              <img
                alt="avt"
                src={getAvatarSrc(currentUser.Avatar)}
                className={classes.avatar}
              />
              {screenWidth >= 992 && (
                <>
                  <span className={classes.username}>
                    {currentUser.Fullname}
                  </span>
                  <FontAwesomeIcon
                    icon={displayProfileModal ? faAngleUp : faAngleDown}
                    className={classes.angleIcon}
                  />
                </>
              )}
            </div>
            {displayProfileModal && (
              <div className={clsx(classes.modal, classes.profileModal)}>
                <div className={classes.modalItem}>
                  <FontAwesomeIcon icon={faUser} size="sm" />
                  <span
                    className={classes.profileItemText}
                    onClick={() => {
                      onClick(`${PathsEnum.PROFILE}?id=${currentUser.Id}`);
                      setDisplayProfileModal(false);
                    }}
                  >
                    Trang cá nhân
                  </span>
                </div>
                <div className={classes.modalItem} onClick={handleLogout}>
                  <FontAwesomeIcon icon={faSignOut} size="sm" />
                  <span className={classes.profileItemText}>Đăng xuất</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {displayNewProject && (
        <Popup
          title="Tạo dự án mới"
          height="80vh"
          width="600px"
          onClose={() => setDisplayNewProject(false)}
        >
          <NewProject onClose={() => setDisplayNewProject(false)} />
        </Popup>
      )}
      {displayNewTask && (
        <Popup
          title="Tạo task mới"
          width="600px"
          onClose={() => setDisplayNewTask(false)}
        >
          <AddOrEditTask
            type={ActionEnum.ADD}
            hasChooseParent
            onClosePopup={() => setDisplayNewTask(false)}
            hasLargeFile={hasLargeFile}
            setHasLargeFile={setHasLargeFile}
          />
        </Popup>
      )}
    </>
  );
};

export default memo(Header);
