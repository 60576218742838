import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangeProfileResponse,
  GetCurrentUserResponse,
  GetUserProfileResponse,
  GetCurrentUserWithTokenRequest,
} from "./../types/profile.types";
import { api, fileApi } from "./../../../api/api";
import { EndpointsEnum } from "../../../constants/endpoints";

const auth = JSON.parse(localStorage.getItem("persist:auth")!);

const getCurrentUserApi = (id: string): Promise<GetCurrentUserResponse> => {
  return api.get(`${EndpointsEnum.GET_CURRENT_USER}?id=${id ? id : auth?.id}`);
};

const getCurrentUserWithTokenApi = (
  data: GetCurrentUserWithTokenRequest
): Promise<GetCurrentUserResponse> => {
  return api.get(`${EndpointsEnum.GET_CURRENT_USER}?id=${data.userId}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

const getUserProfileApi = (id: string): Promise<GetUserProfileResponse> => {
  return api.get(`${EndpointsEnum.GET_USER_PROFILE}?id=${id ? id : auth?.id}`);
};

const changePasswordApi = (
  data: ChangePasswordRequest
): Promise<ChangePasswordResponse> => {
  return api.post(EndpointsEnum.CHANGE_PASSWORD, data);
};

const changeProfileApi = (data: FormData): Promise<ChangeProfileResponse> => {
  return fileApi.post(EndpointsEnum.CHANGE_PROFILE, data);
};

export const profileApi = {
  getCurrentUserApi,
  getCurrentUserWithTokenApi,
  getUserProfileApi,
  changePasswordApi,
  changeProfileApi,
};
