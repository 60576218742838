import { FC, memo } from "react";
import { useHistory } from "react-router-dom";
import { PathsEnum } from "../../../../constants/paths";
import { useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";

const HomeScreen: FC = () => {
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    history.push(LOGIN_ROUTE);
  }

  if (token) {
    history.push(PathsEnum.CHAT);
  }

  return <></>;
};

export default memo(HomeScreen);
