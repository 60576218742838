import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { chatReducer } from "./../features/chat/redux/chat.slice";
import { authReducer } from "./../features/auth/redux/auth.slice";
import { projectReducer } from "./../features/project/redux/project.slice";
import { profileReducer } from "./../features/profile/redux/profile.slice";
import { homeReducer } from "./../features/home/redux/home.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    chat: chatReducer,
    profile: profileReducer,
    project: projectReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      // {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    });
  },
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
