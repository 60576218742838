import AUTH_ROUTES from "../features/auth/routes/auth.routes";
import CHAT_ROUTES from "../features/chat/routes/chat.routes";
import HOME_ROUTES from "../features/home/routes/home.routes";
import PROFILE_ROUTES from "../features/profile/routes/profile.routes";
import PROJECT_ROUTES from "../features/project/routes/project.routes";

export const ROOT_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const REGISTER_ROUTE = "/register";

export const ROUTE_LIST = [
  ...AUTH_ROUTES,
  ...HOME_ROUTES,
  ...CHAT_ROUTES,
  ...PROFILE_ROUTES,
  ...PROJECT_ROUTES,
];
