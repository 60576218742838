import { api, fileApi } from "../../../api/api";
import { EndpointsEnum } from "../../../constants/endpoints";
import {
  GetConversationsResponse,
  GetMessagesRequest,
  GetMessagesResponse,
  SendPrivateMessageRequest,
  SendGroupMessageRequest,
  DeleteMessageRequest,
  GetUsersResponse,
  CreateConversationRequest,
  CreateConversationResponse,
  CreateGroupChatRequest,
  DeleteConversationRequest,
  AddMemberRequest,
  MarkImportantRequest,
  DeleteMessageResponse,
  GetFilesRequest,
  GetFilesResponse,
  SearchMessagesRequest,
  SearchMessagesResponse,
  ChangeGroupInfoResponse,
  MarkImportantResponse,
  ChangeIsReadRequest,
  ChangeIsReadResponse,
  GetConversationsWithTokenRequest,
  GetFileRequest,
  GetFileResponse,
  SendFileMessageResponse,
} from "./../types/chat.types";

const getConversationsApi = (
  data: number
): Promise<GetConversationsResponse> => {
  return api.get(`${EndpointsEnum.GET_CONVERSATIONS}?id=${data}`);
};

const getConversationsWithTokenApi = (
  data: GetConversationsWithTokenRequest
): Promise<GetConversationsResponse> => {
  return api.get(`${EndpointsEnum.GET_CONVERSATIONS}?id=${data.userId}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });
};

const getMessagesApi = (
  data: GetMessagesRequest
): Promise<GetMessagesResponse> => {
  let apiUrl = `?conversationid=${data.conversationId}`;
  data.messageId && (apiUrl = apiUrl + `&messageid=${data.messageId}`);
  data.direction && (apiUrl = apiUrl + `&direction=${data.direction}`);
  data.pageSize && (apiUrl = apiUrl + `&pageSize=${data.pageSize}`);
  data.isMore && (apiUrl = apiUrl + `&isMore=${data.isMore}`);
  return api.get(`${EndpointsEnum.GET_MESSAGES}${apiUrl}`);
};

const searchMessagesApi = (
  data: SearchMessagesRequest
): Promise<SearchMessagesResponse> => {
  let apiUrl = `?conversationid=${data.conversationId}`;
  data.keyword && (apiUrl = apiUrl + `&keyword=${data.keyword}`);
  return api.get(`${EndpointsEnum.SEARCH_MESSAGES}${apiUrl}`);
};

const sendPrivateMessageApi = (
  data: SendPrivateMessageRequest
): Promise<SendPrivateMessageRequest> => {
  return api.post(EndpointsEnum.SEND_PRIVATE_MESSAGE, data);
};

const sendGroupMessageApi = (
  data: SendGroupMessageRequest
): Promise<SendGroupMessageRequest> => {
  return api.post(EndpointsEnum.SEND_GROUP_MESSAGE, data);
};

const deleteMessageApi = (
  data: DeleteMessageRequest
): Promise<DeleteMessageResponse> => {
  return api.post(EndpointsEnum.DELETE_MESSAGE, data);
};

const getUsersApi = (): Promise<GetUsersResponse> => {
  return api.get(EndpointsEnum.GET_USERS);
};

const createConversationApi = (
  data: CreateConversationRequest
): Promise<CreateConversationResponse> => {
  return api.post(EndpointsEnum.CREAT_CONVERSATION, data);
};

const createGroupChatApi = (
  data: CreateGroupChatRequest
): Promise<CreateConversationResponse> => {
  return api.post(EndpointsEnum.CREAT_CONVERSATION, data);
};

const deleteConversationApi = (
  data: DeleteConversationRequest
): Promise<DeleteConversationRequest> => {
  return api.post(EndpointsEnum.DELETE_CONVERSATION, data);
};

const addMemberGroupApi = (
  data: AddMemberRequest
): Promise<AddMemberRequest> => {
  return api.post(EndpointsEnum.ADD_MEMBER, data);
};

const deleteMemberApi = (data: AddMemberRequest): Promise<AddMemberRequest> => {
  return api.post(EndpointsEnum.DELETE_MEMBER, data);
};

const getImportantApi = (
  data: GetMessagesRequest
): Promise<GetMessagesResponse> => {
  return api.get(
    `${EndpointsEnum.GET_IMPORTANT}?conversationid=${data.conversationId}`
  );
};

const markImportantApi = (
  data: MarkImportantRequest
): Promise<MarkImportantResponse> => {
  return api.post(EndpointsEnum.MARK_IMPORTANT, data);
};

const sendFileMessageApi = (
  data: FormData
): Promise<SendFileMessageResponse> => {
  return fileApi.post(EndpointsEnum.SEND_FILE_MESSAGE, data);
};

const getFilesApi = (data: GetFilesRequest): Promise<GetFilesResponse> => {
  let apiUrl = `${EndpointsEnum.GET_FILES}?conversationid=${data.conversationId}`;
  data.page && (apiUrl = apiUrl + `&page=${data.page}`);
  data.pageSize && (apiUrl = apiUrl + `&pageSize=${data.pageSize}`);
  return api.get(apiUrl);
};
const getFileApi = (data: GetFileRequest): Promise<GetFileResponse> => {
  return api.post(EndpointsEnum.GET_FILE, data);
};

const changeGroupInfoApi = (
  data: FormData
): Promise<ChangeGroupInfoResponse> => {
  return fileApi.post(EndpointsEnum.CHANGE_GROUP_INFORMATION, data);
};

const changeIsReadApi = (
  data: ChangeIsReadRequest
): Promise<ChangeIsReadResponse> => {
  return api.post(EndpointsEnum.CHANGE_IS_READ, data);
};

export const chatApi = {
  getConversationsApi,
  getConversationsWithTokenApi,
  getMessagesApi,
  searchMessagesApi,
  sendPrivateMessageApi,
  sendGroupMessageApi,
  deleteMessageApi,
  getUsersApi,
  createConversationApi,
  createGroupChatApi,
  deleteConversationApi,
  addMemberGroupApi,
  deleteMemberApi,
  getImportantApi,
  markImportantApi,
  sendFileMessageApi,
  getFilesApi,
  getFileApi,
  changeGroupInfoApi,
  changeIsReadApi,
};
