import { RouteItemDef } from "./../../../types/routes.types";
import ChatScreen from "../screens/ChatScreen/ChatScreen";
import { PathsEnum } from "../../../constants/paths";

const CHAT_SCREEN: RouteItemDef = {
  id: "chat",
  path: PathsEnum.CHAT,
  component: ChatScreen,
};

const EXIST_CONVERSATION_SCREEN: RouteItemDef = {
  id: "conversation",
  path: PathsEnum.EXIST_CONVERSATION,
  component: ChatScreen,
};

const NEW_CONVERSATION_SCREEN: RouteItemDef = {
  id: "new_conversation",
  path: PathsEnum.NEW_CONVERSATION,
  component: ChatScreen,
};

const CHAT_ROUTES = [
  CHAT_SCREEN,
  EXIST_CONVERSATION_SCREEN,
  NEW_CONVERSATION_SCREEN,
];

export default CHAT_ROUTES;
