import { FC, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { ConversationType } from "../../types/chat.types";
import SearchConversation from "../SearchConversation/SearchConversation";
import SearchUser from "../SearchUser/SearchUser";
import classes from "../NewGroupChat/NewGroupChat.module.css";
interface NewChatProps {
  conversations: ConversationType[];
  onClosePopup: () => void;
}

const NewChat: FC<NewChatProps> = ({ conversations, onClosePopup }) => {
  const currentUserId = useAppSelector((state: RootState) => state.auth.id);
  const users = useAppSelector((state: RootState) =>
    state.chat.users.filter((user) => user.Id !== currentUserId)
  );

  const [usersFilter, setUsersFilter] = useState(users);
  const [keyword, setKeyword] = useState("");

  return (
    <div className={classes.newChatContainer}>
      <SearchUser
        keyword={keyword}
        setKeyword={setKeyword}
        users={users}
        setUsersFilter={setUsersFilter}
      />
      <div className={classes.searchConversationWrapper}>
        <SearchConversation
          conversations={conversations}
          users={usersFilter}
          hideSearchResult={onClosePopup}
        />
      </div>
    </div>
  );
};

export default NewChat;
