import clsx from "clsx";
import { useField } from "formik";
import { FC } from "react";
import classes from "../InputField/InputField.module.css";

interface OptionType {
  id: number;
  value: string;
}

export interface SelectFieldProps {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  width?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: OptionType[];
}

const SelectField: FC<SelectFieldProps> = (props) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.inputWrapper} style={{ width: props.width }}>
      {props.label && (
        <div className={classes.label}>
          {props.label}
          {props.required && <span className={classes.required}>*</span>}
        </div>
      )}
      <select
        className={clsx(classes.input, classes.select)}
        onChange={props.onChange}
        {...field}
        {...props}
      >
        {props.options.map((option) => (
          <option
            key={option.id}
            value={option.id}
            className={classes.selectOption}
          >
            {option.value}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? (
        <p className={classes.wrongInput}>{meta.error}</p>
      ) : (
        <p className={classes.rightInput}>{props.hint}</p>
      )}
    </div>
  );
};

export default SelectField;
