import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import Sidebar, { ItemType } from "../../../../components/Sidebar/Sidebar";
import { PathsEnum } from "../../../../constants/paths";
import { useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { getAvatarSrc } from "../../../chat/helpers/chat.helpers";
import classes from "./ProjectSidebar.module.css";

const ProjectSidebar: FC = () => {
  const { id } = useParams<{ id: string }>();
  const project = useAppSelector((state: RootState) => state.project.project);
  const sidebarItems: ItemType[] = [
    {
      id: "detail",
      name: "Thông tin",
      isCurrent: false,
      path: PathsEnum.PROJECT_DETAIL.replace(":id", id),
    },
    {
      id: "member",
      name: "Thành viên",
      isCurrent: false,
      path: PathsEnum.PROJECT_MEMBER.replace(":id", id),
    },
    {
      id: "documents",
      name: "Tài liệu",
      isCurrent: false,
      path: PathsEnum.PROJECT_DOCUMENTS.replace(":id", id),
    },
  ];
  const [items, setItems] = useState(sidebarItems);

  return (
    <Sidebar items={items} setItems={setItems}>
      <div className={classes.projectThumbnail}>
        <img
          alt="avt"
          src={getAvatarSrc(project?.Avatar)}
          className={classes.avatar}
        />
        <div className={classes.name}>{project?.Name}</div>
      </div>
    </Sidebar>
  );
};

export default ProjectSidebar;
