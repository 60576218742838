import * as Yup from "yup";
import { removeAccents } from "../../chat/helpers/chat.helpers";

export const newProjectInitValues = {
  name: "",
  memberIds: [],
};

export const newMemberInitValues = {
  role: "",
};
const name = Yup.string().required("Không bỏ trống trường này");
const description = Yup.string();
const members = Yup.array<string[]>();
const role = Yup.string();
const type = Yup.string().required("Không bỏ trống trường này");
const status = Yup.string();
const attachments = Yup.array<File[]>();
const assigneeId = Yup.string();
const avatar = Yup.string();
const deadline = Yup.string();
const message = Yup.string();
const process = Yup.number()
  .typeError("Nhập đúng định dạng số")
  .min(0, "Nhập trong khoảng [0, 100]")
  .max(100, "Nhập trong khoảng [0, 100]");

export const newProjectSchema = Yup.object({
  name,
  members,
});

export const editProjectSchema = Yup.object({
  name,
  avatar,
  process,
});

export const newMemberSchema = Yup.object({
  role,
});

export const newTaskSchema = Yup.object({
  name,
  status,
  deadline,
  message,
  process,
});

export const newFeatureSchema = Yup.object({
  name,
});

export const projectCreatedDate = (createdDate: string) => {
  if (createdDate) {
    const [date, time] = createdDate.split("T");
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  } else {
    return "Không có";
  }
};

export const convertDateFormat = (createdDate: string) => {
  if (createdDate) {
    const [date, time] = createdDate.split("T");
    const [year, month, day] = date.split("-");
    return `${year}-${month}-${day}`;
  }
};

export const onEnterForm = (event: any) => {
  if ((event.charCode || event.keyCode) === 13) {
    event.preventDefault();
  }
};

export const filterByName = (list: any[], keyword: string) => {
  return list.filter((item) =>
    removeAccents(item.Name.toLowerCase())?.includes(
      removeAccents(keyword.toLowerCase())
    )
  );
};
