import { FC } from "react";
import classes from "./CancelButton.module.css";

interface CancelButtonProps {
  label: string;
  onClick: () => void;
  width?: string;
  height?: string;
}

const CancelButton: FC<CancelButtonProps> = ({
  label,
  onClick,
  width,
  height,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classes.cancelButton}
      style={{ width: width, height: height }}
    >
      {label}
    </button>
  );
};

export default CancelButton;
