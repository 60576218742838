import { FC, useState, useEffect } from "react";
import Header from "../../../../components/Header/Header";
import Loader from "../../../../components/Loader/Loader";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import Sidebar from "../../../../components/Sidebar/Sidebar";
import { AuthMessage } from "../../../../constants/api.message";
import { useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { ApiMessageType } from "../../../auth/types/auth.types";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import ProfileInfomation from "../../components/ProfileInfomation/ProfileInfomation";
import classes from "./ProfileScreen.module.css";

const sidebarItems = [
  {
    id: "profile",
    name: "Thông tin cá nhân",
    isCurrent: true,
  },
  {
    id: "change_password",
    name: "Mật khẩu",
    isCurrent: false,
  },
];

const ProfileScreen: FC = () => {
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const [items, setItems] = useState(sidebarItems);
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  return (
    <>
      <Header />
      {currentUser.Fullname ? (
        <div className={classes.profileScreen}>
          <Sidebar items={items} setItems={setItems} />
          <div className={classes.screenRight}>
            <div className={classes.information}>
              {items[0].isCurrent && (
                <ProfileInfomation
                  currentUser={currentUser}
                  setApiMessage={setApiMessage}
                  screenWidth={screenWidth}
                />
              )}
              {items[1].isCurrent && (
                <ChangePassword
                  currentUserId={currentUser.Id}
                  setApiMessage={setApiMessage}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader isFullScreen />
      )}
      {apiMessage && (
        <NotifyAlert
          type={apiMessage.Type}
          message={AuthMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
    </>
  );
};

export default ProfileScreen;
