import { FC, memo, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { CLIENT_HOST, CLOUD } from "../../constants/variable";
import {
  addUserRegistered,
  getConversations,
  getUsers,
} from "../../features/chat/redux/chat.slice";
import { getCurrentUser } from "../../features/profile/redux/profile.slice";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { RootState } from "../../redux/store";
import { LayoutProps } from "../../types/routes.types";
import {
  addNotification,
  getNotifications,
  updateConnection,
} from "../../features/home/redux/home.slice";
import AUTH_ROUTES from "../../features/auth/routes/auth.routes";
import { useHistory } from "react-router-dom";
import { PathsEnum } from "../../constants/paths";

const DefaultLayout: FC<LayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  const currentUserId = useAppSelector((state: RootState) => state.auth.id);
  const conversations = useAppSelector(
    (state: RootState) => state.chat.conversations
  );
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(timezone);

  // if (
  //   !token &&
  //   AUTH_ROUTES.findIndex(
  //     (route) => `${CLIENT_HOST}${route.path}` === window.location.href
  //   ) < 0
  // ) {
  //   history.push(PathsEnum.LOGIN);
  // }

  useEffect(() => {
    if (currentUserId) {
      dispatch(getCurrentUser(`${currentUserId!}`))
        .unwrap()
        .catch(() => console.log("Get current user fail"));
      dispatch(getUsers())
        .unwrap()
        .catch(() => {
          console.log("Get users fail");
        });
      dispatch(getNotifications(currentUserId));
      if (conversations.length === 0) {
        dispatch(getConversations(currentUserId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (async () => {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${CLOUD}/chat`)
        .configureLogging(signalR.LogLevel.Information)
        .build();

      await hubConnection.start();

      hubConnection.invoke("Connect", `${currentUserId}`);

      hubConnection.on("ReceiveNotif", (data) => {
        dispatch(addNotification(data));
      });

      hubConnection.on("ReceiveNotifUserRegister", (data) => {
        dispatch(addUserRegistered(data));
        dispatch(getConversations(currentUserId));
      });

      dispatch(updateConnection(hubConnection));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="defaultLayout">{children}</div>;
};

export default memo(DefaultLayout);
