import { FC, useState } from "react";
import classes from "./ChatSidebar.module.css";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFile, faCircle } from "@fortawesome/free-solid-svg-icons";
import { ConversationType } from "../../types/chat.types";
import { useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import {
  convertMessageDate,
  getAvatarSrc,
  convertDateToLocal,
} from "../../helpers/chat.helpers";
import SearchUser from "../SearchUser/SearchUser";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import NewGroupChat from "../../components/NewGroupChat/NewGroupChat";
import Popup from "../../../../components/Popup/Popup";
import NewChat from "../../components/NewChat/NewChat";
import SearchResultNavbar from "../SearchResultNavbar/SearchResultNavbar";
import { PathsEnum } from "../../../../constants/paths";
import { GroupChatActionEnum } from "../../../../constants/enum";

interface ChatSidebarProps {
  conversations: ConversationType[];
  id: string;
  screenWidth: number;
  setDisplaySidebar?: (bool: boolean) => void;
}

const ChatSidebar: FC<ChatSidebarProps> = ({
  conversations,
  id,
  screenWidth,
  setDisplaySidebar,
}) => {
  const history = useHistory();
  const currentUserId = useAppSelector((state: RootState) => state.auth.id);
  const users = useAppSelector((state: RootState) =>
    state.chat.users.filter((user) => user.Id !== currentUserId)
  );
  const groupChats = conversations.filter((con) => con.Type === 2);

  const [usersFilter, setUsersFilter] = useState([]);
  const [groupsFilter, setGroupsFilter] = useState([]);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [displayNewChatModal, setDisplayNewChatModal] = useState(false);
  const [displayNewChat, setDisplayNewChat] = useState(false);
  const [displayNewGroupChat, setDisplayNewGroupChat] = useState(false);

  const searchResultRef = useOutsideClick(() => setDisplaySearch(false));
  const newChatRef = useOutsideClick(() => setDisplayNewChatModal(false));

  const onClickConversation = (id: number) => {
    if (screenWidth < 992) {
      setDisplaySidebar(false);
    }
    history.push(PathsEnum.EXIST_CONVERSATION.replace(":id", `${id}`));
  };

  const displaySearchResult = () => {
    setUsersFilter(users);
    setDisplaySearch(true);
  };

  const hideSearchResult = () => {
    setUsersFilter(users);
    setDisplaySearch(false);
    setKeyword("");
  };

  const onDisplayNewChat = () => {
    setDisplayNewChatModal(false);
    setDisplayNewChat(true);
  };

  const onDisplayNewGroupChat = () => {
    setDisplayNewChatModal(false);
    setDisplayNewGroupChat(true);
  };

  return (
    <>
      <div
        className={clsx(classes.sidebar, {
          [classes.isResponsive]: screenWidth < 992,
          [classes.isTablet]: screenWidth < 768,
          [classes.isMobile]: screenWidth < 575,
        })}
        style={{ width: screenWidth < 992 ? "100%" : "350px" }}
      >
        <div className={classes.sidebarHeader}>
          <div className={classes.searchContainer}>
            <div className={classes.searchWrapper}>
              <SearchUser
                keyword={keyword}
                setKeyword={setKeyword}
                onClickDisplay={displaySearchResult}
                users={users}
                setUsersFilter={setUsersFilter}
                groups={groupChats}
                setGroupsFilter={setGroupsFilter}
              />
            </div>
            {displaySearch && (
              <div ref={searchResultRef} className={classes.searchResult}>
                <SearchResultNavbar
                  conversations={conversations}
                  users={usersFilter}
                  groups={groupsFilter}
                  hideSearchResult={hideSearchResult}
                />
              </div>
            )}
            <FontAwesomeIcon
              icon={faEdit}
              size="lg"
              onClick={() => setDisplayNewChatModal(true)}
              className={classes.iconAddConversation}
            />

            <div className={classes.addConversation}>Thêm trò chuyện</div>
          </div>
          {displayNewChatModal && (
            <div ref={newChatRef} className={classes.newChatModal}>
              <div
                className={classes.newChatModalItem}
                onClick={onDisplayNewChat}
              >
                Cuộc trò chuyện mới
              </div>
              <div
                className={classes.newChatModalItem}
                onClick={onDisplayNewGroupChat}
              >
                Nhóm trò chuyện mới
              </div>
            </div>
          )}
        </div>
        <div className={classes.conversations}>
          {conversations.map((conversation) => {
            const lastTime = convertDateToLocal(conversation.LastTime);
            return (
              <div
                key={conversation.Id}
                className={clsx(classes.conversation, {
                  [classes.isCurrent]: id === `${conversation.Id}`,
                })}
                onClick={() => onClickConversation(conversation.Id)}
              >
                <div className={classes.avatar}>
                  <img
                    alt="avatar"
                    src={getAvatarSrc(
                      conversation.Receiver?.Avatar ||
                        conversation.Group?.Avatar
                    )}
                    className={classes.avatar}
                  />
                </div>
                <div className={classes.conversationWrapper}>
                  <div className={classes.conversationTop}>
                    <div className={classes.username}>
                      {conversation.Receiver?.Fullname ||
                        conversation.Group?.Name}
                    </div>
                    <div className={classes.lastMessageDate}>
                      {convertMessageDate(lastTime)}
                    </div>
                  </div>
                  <div className={classes.conversationBottom}>
                    <div className={classes.lastMessage}>
                      {conversation?.LastMessage ? (
                        <>{conversation?.LastMessage}</>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            size="sm"
                            icon={faFile}
                            className={classes.lastMessageIcon}
                          />
                          File
                        </>
                      )}
                    </div>
                    {conversation?.ReadBy.some(
                      (userId) => userId === currentUserId
                    ) ? (
                      <></>
                    ) : (
                      <div className={classes.unreadIconWrapper}>
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={classes.unreadIcon}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {displayNewChat && (
        <Popup
          title="Cuộc trò chuyện mới"
          onClose={() => setDisplayNewChat(false)}
        >
          <NewChat
            conversations={conversations}
            onClosePopup={() => setDisplayNewChat(false)}
          />
        </Popup>
      )}
      {displayNewGroupChat && (
        <Popup
          title="Nhóm trò chuyện mới"
          onClose={() => setDisplayNewGroupChat(false)}
        >
          <NewGroupChat
            type={GroupChatActionEnum.CREATE_GROUP_CHAT}
            onClosePopup={() => setDisplayNewGroupChat(false)}
          />
        </Popup>
      )}
    </>
  );
};

export default ChatSidebar;
