import { UserState } from "../../profile/types/profile.types";
import { ConversationType, MessageType } from "./../types/chat.types";
import noAvatar from "../../../assets/no-avatar.jpg";
import { HOST, TIMEZONE } from "../../../constants/variable";

export const convertMessageDate = (createdDate: string) => {
  const [date, time] = createdDate.split("T");
  const [year, month, day] = date.split("-");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hour, minute, second] = time?.split(":");
  const dateNow = new Date();
  const nowYear = `${dateNow.getFullYear()}`.padStart(2, "0");
  const nowMonth = `${dateNow.getMonth() + 1}`.padStart(2, "0");
  const nowDay = `${dateNow.getDate()}`.padStart(2, "0");
  if (nowYear === year && nowMonth === month && nowDay === day) {
    return `${`${hour}`.padStart(2, "0")}:${`${minute}`.padStart(2, "0")}`;
  }
  if (nowYear === year && nowMonth === month) {
    const dateAmount = Number(nowDay) - Number(day);
    return `${dateAmount} ngày`;
  }
  return `${day}/${month}/${year}`;
};

export const convertDateToIsoString = (createdDate: Date) => {
  const year = `${createdDate.getFullYear()}`.padStart(2, "0");
  const month = `${createdDate.getMonth() + 1}`.padStart(2, "0");
  const day = `${createdDate.getDate()}`.padStart(2, "0");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hour = createdDate.getHours();
  const minute = createdDate.getMinutes();
  const second = createdDate.getSeconds();

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

export const convertDateToLocal = (dateTimeString: string) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const lastTime = new Date(dateTimeString?.split("+")[0]);

  const now = new Date();
  const dLocal = new Date(now.toLocaleString("en-US", { timeZone: timeZone }));
  const dServer = new Date(now.toLocaleString("en-US", { timeZone: TIMEZONE }));
  let dateTimeFinal = new Date(
    lastTime.getTime() + (dLocal.getTime() - dServer.getTime())
  );
  let isoString = convertDateToIsoString(dateTimeFinal);

  return isoString;
};

export const conversationsSort = (conversations: ConversationType[]) => {
  return conversations.sort((con1, con2) => {
    if (con1.LastTime > con2.LastTime) {
      return -1;
    }
    if (con1.LastTime < con2.LastTime) {
      return 1;
    }
    return 0;
  });
};

export function filterNotContainUsers(
  arrayOne: UserState[],
  arrayTwo: UserState[]
): UserState[] {
  if (arrayTwo) {
    return arrayOne.filter(
      (itemArrOne) =>
        arrayTwo.filter((itemArrTwo) => itemArrTwo.Id === itemArrOne.Id)
          .length === 0
    );
  } else {
    return arrayOne;
  }
}

export function filterContainUsers(
  arrayOne: UserState[],
  arrayTwo: any[]
): UserState[] {
  if (arrayTwo) {
    return arrayOne.filter(
      (itemArrOne) =>
        arrayTwo.filter(
          (itemArrTwo: any) => Number(itemArrTwo) === itemArrOne.Id
        ).length === 1
    );
  } else {
    return arrayOne;
  }
}

export const removeAccents = (keyword: string) => {
  return keyword
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

export const getAvatarSrc = (avatar: string) => {
  if (avatar) {
    return `${HOST}${avatar}`;
  }
  return noAvatar;
};

export const getAvatarSrcNoHost = (avatar: string, avatarWithHost: string) => {
  if (avatar) {
    return avatar;
  }
  if (avatarWithHost) {
    return `${HOST}${avatarWithHost}`;
  }
  return noAvatar;
};

export const getGroupName = (creatorName: string, group: UserState[]) => {
  let name = creatorName + ", ";
  group.forEach((member, index, array) => {
    name += member.Fullname;
    if (index < array.length - 1) {
      name += ", ";
    }
  });
  return name;
};

export const getScrollMessagesHeight = (
  dafaultHeight: string,
  isFilePreview: boolean,
  isReply: boolean,
  inputMessageHeight: string
) => {
  const messageHeight = Number(inputMessageHeight?.slice(0, -2));
  let inputHeight = 0;
  if (isFilePreview) {
    inputHeight += 150;
  }
  if (isReply) {
    inputHeight += 70;
  }
  if (messageHeight >= 50 && messageHeight < 120) {
    inputHeight = inputHeight + (messageHeight - 50);
  }
  if (messageHeight >= 120) {
    inputHeight += 70;
  }
  return `calc(${dafaultHeight} - ${inputHeight}px)`;
};

export const getChatBoxWidth = (
  isMobile: boolean,
  isDisplayContainerRight: boolean
) => {
  if (isMobile) {
    if (isDisplayContainerRight) {
      return 0;
    } else {
      return "100%";
    }
  } else {
    if (isDisplayContainerRight) {
      return "70%";
    } else {
      return "100%";
    }
  }
};

export const getUniqueMessages = (messages: MessageType[]) => {
  return Array.from(new Set(messages.map((message) => message.Id))).map(
    (index) => messages.find((message) => message.Id === index)
  );
};

export const sortMessages = (messages: MessageType[]) => {
  return messages.sort((messages1, message2) => {
    if (messages1.Id > message2.Id) {
      return -1;
    }
    if (messages1.Id < message2.Id) {
      return 1;
    }
    return 0;
  });
};
