import clsx from "clsx";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import classes from "../../../../components/Header/Header.module.css";
import { useAppSelector } from "../../../../hooks/hooks";
import { RootState } from "../../../../redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTasksAlt } from "@fortawesome/free-solid-svg-icons";
import { PathsEnum } from "../../../../constants/paths";

interface ListProjectModalProps {
  setDisplayProjectModal: (bool: boolean) => void;
  setDisplayNewProject: (bool: boolean) => void;
}

const ListProjectModal: FC<ListProjectModalProps> = ({
  setDisplayProjectModal,
  setDisplayNewProject,
}) => {
  const history = useHistory();
  const listProject = useAppSelector(
    (state: RootState) => state.project.listProject
  );

  return (
    <div className={clsx(classes.modal, classes.projectModal)}>
      <div className={classes.projectsWrapper}>
        {listProject?.length > 0 &&
          listProject.map((project) => (
            <div
              key={project.Id}
              className={classes.modalItem}
              onClick={() => {
                history.push(
                  PathsEnum.PROJECT_DETAIL.replace(":id", `${project.Id}`)
                );
                setDisplayProjectModal(false);
              }}
            >
              {project.Name}
            </div>
          ))}
      </div>
      <div
        className={classes.modalItem}
        onClick={() => {
          history.push(PathsEnum.PROJECTS);
          setDisplayProjectModal(false);
        }}
      >
        <FontAwesomeIcon icon={faTasksAlt} size="sm" />
        <span className={classes.profileItemText}>Tất cả dự án</span>
      </div>
      <div
        className={classes.modalItem}
        onClick={() => {
          setDisplayProjectModal(false);
          setDisplayNewProject(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="sm" />
        <span className={classes.profileItemText}>Tạo dự án mới</span>
      </div>
    </div>
  );
};

export default ListProjectModal;
