import { FC, memo } from "react";
import classes from "./SearchConversation.module.css";
import { useHistory } from "react-router-dom";
import { ConversationType } from "../../types/chat.types";
import { useAppDispatch } from "../../../../hooks/hooks";
import { getMessages, updateMessages } from "../../redux/chat.slice";
import { UserState } from "../../../profile/types/profile.types";
import { getAvatarSrc } from "../../helpers/chat.helpers";
import { PathsEnum } from "../../../../constants/paths";

export interface SearchConversationProps {
  conversations: ConversationType[];
  users?: UserState[];
  groups?: ConversationType[];
  hideSearchResult?: () => void;
}

const SearchConversation: FC<SearchConversationProps> = ({
  conversations,
  users,
  groups,
  hideSearchResult,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleGetMessages = (id: number) => {
    dispatch(
      getMessages({
        conversationId: `${id}`,
      })
    )
      .unwrap()
      .catch(() => {
        console.log("Get messages fail");
      });
  };
  const handleChooseConversation = (userId: number, conId: number) => {
    const existConversation =
      userId === 0
        ? conversations.find((groupChat) => groupChat.Id === conId)
        : conversations.find((singleChat) => singleChat.ReceiverId === userId);
    if (existConversation) {
      hideSearchResult();
      history.push(
        PathsEnum.EXIST_CONVERSATION.replace(":id", `${existConversation.Id}`)
      );
      handleGetMessages(existConversation.Id);
    } else {
      hideSearchResult();
      history.push(
        PathsEnum.NEW_CONVERSATION.replace(":receiverId", `${userId}`)
      );
      dispatch(updateMessages([]));
    }
  };
  return (
    <div className={classes.searchResultContainer}>
      {users?.length > 0 &&
        users.map((user) => {
          return (
            <div
              key={user.Id}
              className={classes.searchResultItem}
              onClick={() => {
                handleChooseConversation(user.Id, 0);
              }}
            >
              <img
                alt="avt"
                src={getAvatarSrc(user.Avatar)}
                className={classes.avatar}
              />
              <div className={classes.fullname}>{user.Fullname}</div>
            </div>
          );
        })}
      {groups?.length > 0 &&
        groups.map((group) => {
          return (
            <div
              key={group.Id}
              className={classes.searchResultItem}
              onClick={() => {
                handleChooseConversation(0, group.Id);
              }}
            >
              <img
                alt="avt"
                src={getAvatarSrc(group.Group.Avatar)}
                className={classes.avatar}
              />
              <div className={classes.fullname}>{group.Group.Name}</div>
            </div>
          );
        })}
      {users && !groups && users.length === 0 && (
        <div className={classes.noResult}>Không có kết quả</div>
      )}
      {!users && groups && groups.length === 0 && (
        <div className={classes.noResult}>Không có kết quả</div>
      )}
      {users && groups && users.length === 0 && groups.length === 0 && (
        <div className={classes.noResult}>Không có kết quả</div>
      )}
    </div>
  );
};

export default memo(SearchConversation);
