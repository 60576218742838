import {
  faClockRotateLeft,
  faFolder,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import ConfirmPopup from "../../../../components/ConfirmPopup/ConfirmPopup";
import Header from "../../../../components/Header/Header";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import Popup from "../../../../components/Popup/Popup";
import { ProjectMessage } from "../../../../constants/api.message";
import { PathsEnum } from "../../../../constants/paths";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { RootState } from "../../../../redux/store";
import { ApiMessageType } from "../../../auth/types/auth.types";
import AddOrEditFeature from "../../components/AddOrEditFeature/AddOrEditFeature";
import AddOrEditTask from "../../components/AddOrEditTask/AddOrEditTask";
import TaskTable from "../../components/TaskTable/TaskTable";
import {
  deleteFeature,
  getFeature,
  getProject,
} from "../../redux/project.slice";
import classes from "./FeatureScreen.module.css";
import AlertPopup from "./../../../../components/AlertPopup/AlertPopup";
import clsx from "clsx";
import { ActionEnum, TaskListTypeEnum } from "../../../../constants/enum";
import { LOGIN_ROUTE } from "../../../../routes/routes.config";
import EditHistory from "../../components/EditHistory/EditHistory";

const FeatureScreen: FC = () => {
  const { id, featureId } = useParams<{
    id: string;
    featureId: string;
  }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  if (!token) {
    window.location.href = LOGIN_ROUTE;
  }
  const project = useAppSelector((state: RootState) => state.project.project);
  const feature = useAppSelector((state: RootState) => state.project.feature);
  const users = useAppSelector((state: RootState) => state.chat.users);

  const [displayCreateModal, setDisplayCreateModal] = useState(false);
  const [displayCreateTask, setDisplayCreateTask] = useState(false);
  const [displayCreateSubfeature, setDisplayCreateSubfeature] = useState(false);
  const [displayEditFeature, setDisplayEditFeature] = useState(false);
  const [displayDeleteFeature, setDisplayDeleteFeature] = useState(false);
  const [displayEditHistory, setDisplayEditHistory] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const [hasLargeFile, setHasLargeFile] = useState(false);

  const createModalRef = useOutsideClick(() => setDisplayCreateModal(false));

  useEffect(() => {
    if (!project) {
      dispatch(getProject(id));
    }
    if (!feature || feature.Id !== Number(featureId)) {
      dispatch(getFeature(Number(featureId)))
        .unwrap()
        .then()
        .catch(() => console.log("Get feature fail"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureId]);

  const handleDeleteFeature = () => {
    dispatch(deleteFeature({ Id: Number(featureId), ProjectId: Number(id) }))
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
        if (data.apiMessage.Type === "success") {
          if (feature.ParentId) {
            history.push(
              PathsEnum.FEATURE.replace(":id", id).replace(
                ":featureId",
                `${feature.ParentId}`
              )
            );
          } else {
            history.push(PathsEnum.PROJECT_DETAIL.replace(":id", id));
          }
          setDisplayDeleteFeature(false);
        }
      })
      .catch(() => console.log("Delete feature fail"));
  };

  return (
    <>
      <Header />
      <div className={classes.featureScreen}>
        <div className={classes.feature}>
          <Breadcrumbs
            linkList={[
              ["Tất cả dự án", PathsEnum.PROJECTS],
              [project?.Name, PathsEnum.PROJECT_DETAIL.replace(":id", id)],
              [
                feature?.Name,
                PathsEnum.FEATURE.replace(":id", id).replace(
                  ":featureId",
                  featureId
                ),
              ],
            ]}
          />
          <div className={classes.featureHeader}>
            <div className={classes.featureName}>
              <div className={classes.title}>{feature?.Name}</div>
              <div className={classes.actionGroup}>
                <div
                  ref={createModalRef}
                  className={clsx(classes.modalWrapper, classes.iconAdd)}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={classes.iconItem}
                    onClick={() => {
                      setDisplayCreateModal(true);
                    }}
                  />
                  {displayCreateModal && (
                    <div className={classes.modal}>
                      <div
                        className={classes.modalItem}
                        onClick={() => setDisplayCreateSubfeature(true)}
                      >
                        Chức năng mới
                      </div>
                      <div
                        className={classes.modalItem}
                        onClick={() => setDisplayCreateTask(true)}
                      >
                        Task mới
                      </div>
                    </div>
                  )}
                </div>
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  className={clsx(classes.iconItem, classes.iconHistory)}
                  onClick={() => {
                    setDisplayEditHistory(true);
                  }}
                />
                <FontAwesomeIcon
                  icon={faPen}
                  className={clsx(classes.iconItem, classes.iconEdit)}
                  onClick={() => {
                    setDisplayEditFeature(true);
                  }}
                />
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className={clsx(classes.iconItem, classes.iconDelete)}
                  onClick={() => {
                    setDisplayDeleteFeature(true);
                  }}
                />
                <div className={classes.add}>Thêm</div>
                <div className={classes.edit}>Chỉnh sửa</div>
                <div className={classes.delete}>Xóa</div>
              </div>
            </div>
            <div className={classes.description}>
              <div className={classes.label}>Mô tả</div>
              <div>
                {feature?.Description ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: feature?.Description,
                    }}
                  ></span>
                ) : (
                  "Chưa có mô tả."
                )}
              </div>
            </div>
          </div>
          <div className={classes.featureBody}>
            <div>
              <div className={classes.label}></div>
              {feature?.SubFeatures?.length > 0 && (
                <div className={classes.subfeatureContainer}>
                  {feature?.SubFeatures?.map((feature) => (
                    <div
                      key={feature.Id}
                      className={classes.subfeatureItem}
                      onClick={() =>
                        history.push(
                          PathsEnum.FEATURE.replace(":id", id).replace(
                            ":featureId",
                            `${feature.Id}`
                          )
                        )
                      }
                    >
                      <div className={classes.nameWrapper}>
                        <FontAwesomeIcon icon={faFolder} />
                        <div className={classes.name}>{feature.Name}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={classes.tableWrapper}>
              <div className={classes.label}></div>
              {feature?.Tasks?.length > 0 && (
                <TaskTable
                  type={TaskListTypeEnum.TASK_IN_FEATURE}
                  taskList={feature?.Tasks}
                />
              )}
            </div>
          </div>
        </div>
        {displayCreateTask && (
          <Popup
            title="Tạo task mới"
            width="600px"
            onClose={() => setDisplayCreateTask(false)}
          >
            <AddOrEditTask
              type={ActionEnum.ADD}
              hasChooseParent={false}
              onClosePopup={() => setDisplayCreateTask(false)}
              setApiMessage={setApiMessage}
              hasLargeFile={hasLargeFile}
              setHasLargeFile={setHasLargeFile}
            />
          </Popup>
        )}
        {displayCreateSubfeature && (
          <Popup
            width="600px"
            title="Tạo chức năng mới"
            onClose={() => setDisplayCreateSubfeature(false)}
          >
            <AddOrEditFeature
              type={ActionEnum.ADD}
              choosenFeature={feature}
              onClosePopup={() => setDisplayCreateSubfeature(false)}
              setApiMessage={setApiMessage}
            />
          </Popup>
        )}
        {displayEditFeature && (
          <Popup
            width="600px"
            title="Chỉnh sửa"
            height="fit-content"
            onClose={() => setDisplayEditFeature(false)}
          >
            <AddOrEditFeature
              type={ActionEnum.EDIT}
              choosenFeature={feature}
              onClosePopup={() => setDisplayEditFeature(false)}
              setApiMessage={setApiMessage}
            />
          </Popup>
        )}
        {displayDeleteFeature && (
          <ConfirmPopup
            title={`Tất cả các sub-feature và task thuộc ${feature.Name} cũng sẽ bị xóa. Chắc chắn muốn xóa?`}
            onClose={() => setDisplayDeleteFeature(false)}
            onOK={handleDeleteFeature}
          />
        )}
        {displayEditHistory && (
          <Popup
            title="Lịch sử chỉnh sửa"
            width="600px"
            onClose={() => setDisplayEditHistory(false)}
          >
            <EditHistory featureId={featureId} users={users} />
          </Popup>
        )}
        {hasLargeFile && (
          <AlertPopup
            title="Không thể gửi tệp"
            message="File bạn chọn quá lớn. Vui lòng chọn file không quá 100MB."
            onClose={() => setHasLargeFile(false)}
          />
        )}
        {apiMessage && (
          <NotifyAlert
            type={apiMessage.Type}
            message={ProjectMessage[apiMessage.Code]}
            setApiMessage={setApiMessage}
          />
        )}
      </div>
    </>
  );
};

export default FeatureScreen;
