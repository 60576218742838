import { FC, useState } from "react";
import { Formik, Form } from "formik";
import classes from "./NewProject.module.css";
import {
  newProjectInitValues,
  newProjectSchema,
  onEnterForm,
} from "../../helpers/project.helpers";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import InputField from "../../../../components/Form/InputField/InputField";
import { RootState } from "../../../../redux/store";
import SearchUser from "../../../chat/components/SearchUser/SearchUser";
import ChooseMemberGroup from "../../../chat/components/ChooseMemberGroup/ChooseMemberGroup";
import { createProject, getProjects } from "../../redux/project.slice";
import { useHistory } from "react-router-dom";
import TextareaField from "../../../../components/Form/TextareaField/TextareaField";
import { PathsEnum } from "../../../../constants/paths";
import CancelButton from "../../../../components/Button/CancelButton/CancelButton";
import TextEditor from "../../../../components/TextEditor/TextEditor";

interface NewProjectProps {
  onClose: () => void;
}

const NewProject: FC<NewProjectProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const currentUser = useAppSelector(
    (state: RootState) => state.profile.currentUser
  );
  const users = useAppSelector((state: RootState) =>
    state.chat.users.filter((user) => user.Id !== currentUser.Id)
  );

  const [usersFilter, setUsersFilter] = useState(users);
  const [keyword, setKeyword] = useState("");
  const [description, setDescription] = useState("");

  const handleCreateNewProject = (values: any) => {
    dispatch(
      createProject({
        CreatedBy: currentUser.Id,
        Name: values.name,
        Description: description,
        MemberIds: values.memberIds,
      })
    )
      .unwrap()
      .then((data) => {
        if (data.apiMessage.Type === "success") {
          dispatch(getProjects(currentUser.Id));
        }
        history.push(
          PathsEnum.PROJECT_DETAIL.replace(":id", `${data.projectId}`)
        );
        onClose();
      });
  };

  return (
    <div className={classes.form}>
      <Formik
        initialValues={newProjectInitValues}
        validationSchema={newProjectSchema}
        onSubmit={handleCreateNewProject}
      >
        {({ values, isSubmitting }) => (
          <Form onKeyDown={onEnterForm}>
            <InputField
              type="text"
              name="name"
              placeholder="Tên dự án"
              label="Tên dự án"
              required
            />
            {/* <TextareaField                                                                                                                                                                                                                                            
              rows={3}
              name="description"
              placeholder="Mô tả"
              label="Mô tả"
              required
            /> */}
            <TextEditor
              text={description}
              setText={setDescription}
              placeholder="Mô tả"
              label="Mô tả"
            />
            <div className={classes.label}>Thêm thành viên</div>
            <SearchUser
              keyword={keyword}
              setKeyword={setKeyword}
              users={users}
              setUsersFilter={setUsersFilter}
            />
            <div role="group" className={classes.checkboxGroup}>
              <ChooseMemberGroup
                name="memberIds"
                users={usersFilter}
                values={values.memberIds}
              />
            </div>
            <div className={classes.buttonGroup}>
              <CancelButton label="Hủy" onClick={onClose} />
              <InputField
                type="submit"
                name="submit"
                value="Tạo"
                width="120px"
                disabled={!values.name.trim() || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewProject;
