import clsx from "clsx";
import { FC, useState } from "react";
import SearchConversation, {
  SearchConversationProps,
} from "../SearchConversation/SearchConversation";
import classes from "./SearchResultNavbar.module.css";

const navbarItems = [
  {
    id: 1,
    name: "Tất cả",
    isCurrent: true,
  },
  {
    id: 2,
    name: "Người",
    isCurrent: false,
  },
  {
    id: 3,
    name: "Nhóm",
    isCurrent: false,
  },
];

const SearchResultNavbar: FC<SearchConversationProps> = ({
  conversations,
  users,
  groups,
  hideSearchResult,
}) => {
  const [items, setItems] = useState(navbarItems);
  const onClick = (id: number) => {
    const newItems = items.map((item) => ({
      ...item,
      isCurrent: item.id === id,
    }));
    setItems(newItems);
  };

  return (
    <>
      <div className={classes.navbar}>
        {items.map((item) => (
          <div
            key={item.id}
            onClick={() => {
              onClick(item.id);
            }}
            className={clsx(classes.navbarItem, {
              [classes.isCurrent]: item.isCurrent,
            })}
          >
            {item.name}
          </div>
        ))}
      </div>
      {items[0].isCurrent && (
        <SearchConversation
          conversations={conversations}
          users={users}
          groups={groups}
          hideSearchResult={hideSearchResult}
        />
      )}
      {items[1].isCurrent && (
        <SearchConversation
          conversations={conversations}
          users={users}
          hideSearchResult={hideSearchResult}
        />
      )}
      {items[2].isCurrent && (
        <SearchConversation
          conversations={conversations}
          groups={groups}
          hideSearchResult={hideSearchResult}
        />
      )}
    </>
  );
};

export default SearchResultNavbar;
