import { FC, useState } from "react";
import { Form, Formik } from "formik";
import {
  changePasswordInitValues,
  changePasswordSchema,
} from "../../helpers/profile.helpers";
import { useAppDispatch } from "../../../../hooks/hooks";
import { changePassword } from "../../redux/profile.slice";
import InputField from "../../../../components/Form/InputField/InputField";
import { ApiMessageType } from "../../../auth/types/auth.types";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import { AuthMessage } from "../../../../constants/api.message";
import InputPasswordField from "../../../../components/Form/InputPasswordField/InputPasswordField";
import classes from "./ChangePassword.module.css";
import { isHasAnySpaces } from "../../../../helpers/helpers";

interface ChangePasswordProps {
  currentUserId: number;
  setApiMessage: (apiMessate: ApiMessageType) => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  currentUserId,
  setApiMessage,
}) => {
  const dispatch = useAppDispatch();
  const [passwordError, setPasswordError] = useState("");

  const handleChangePassword = (values: any) => {
    setPasswordError("");
    dispatch(
      changePassword({
        Id: currentUserId,
        Password: values.password,
        NewPassword: values.newPassword,
      })
    )
      .unwrap()
      .then((data) => {
        if (data.apiMessage.Code === "account104") {
          setPasswordError(AuthMessage[data.apiMessage.Code]);
        } else {
          setApiMessage(data.apiMessage);
        }
      });
  };
  return (
    <>
      <div className={classes.title}>Thay đổi mật khẩu</div>
      <Formik
        initialValues={changePasswordInitValues}
        validationSchema={changePasswordSchema}
        onSubmit={handleChangePassword}
      >
        {({ values }) => (
          <Form>
            <InputPasswordField
              name="password"
              placeholder="Mật khẩu cũ"
              label="Mật khẩu cũ"
              required
              width="100%"
            />
            <InputPasswordField
              name="newPassword"
              placeholder="Mật khẩu"
              label="Mật khẩu mới"
              required
              width="100%"
            />
            <InputPasswordField
              name="newConfirmPassword"
              placeholder="Mật khẩu"
              label="Nhập lại mật khẩu mới"
              required
              width="100%"
            />
            {passwordError && (
              <div className={classes.passwordError}>{passwordError}</div>
            )}
            <div style={{ textAlign: "center" }}>
              <InputField
                type="submit"
                name="submit"
                value="Lưu"
                disabled={
                  isHasAnySpaces(values.newPassword) ||
                  values.newPassword !== values.newConfirmPassword
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
