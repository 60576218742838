import { FC, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { getAvatarSrcNoHost } from "../../../chat/helpers/chat.helpers";
import { editProjectSchema, onEnterForm } from "../../helpers/project.helpers";
import classes from "./EditProject.module.css";
import InputField from "../../../../components/Form/InputField/InputField";
import { ProjectType } from "../../types/project.types";
import { ApiMessageType } from "../../../auth/types/auth.types";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { editProject } from "../../redux/project.slice";
import CancelButton from "../../../../components/Button/CancelButton/CancelButton";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import InputNumberField from "../../../../components/Form/InputNumberField/InputNumberField";

interface EditProjectProps {
  project: ProjectType;
  onClosePopup: () => void;
  setApiMessage: (apiMessage: ApiMessageType) => void;
}

const EditProject: FC<EditProjectProps> = ({
  project,
  onClosePopup,
  setApiMessage,
}) => {
  const dispatch = useAppDispatch();
  const current = useAppSelector((state) => state.profile.currentUser);
  const inputFileRef = useRef<HTMLInputElement>();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const formData = new FormData();
  const [description, setDescription] = useState(project?.Description);

  const editProjectInitValues = {
    name: project?.Name,
    process: project?.Process,
    avatar: project?.Avatar,
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files[0]);
    setFileUrl(URL.createObjectURL(event.target.files[0]));
  };

  const handleEditProject = (values: any) => {
    formData.append("Id", `${project.Id}`);
    formData.append("ChangedBy", `${current.Id}`);
    if (values?.name) {
      formData.append("Name", values.name);
    }
    formData.append("Process", values.process || 0);
    formData.append("Description", description);
    if (file?.name) {
      formData.append("Avatar", file);
    }

    dispatch(editProject(formData))
      .unwrap()
      .then((data) => {
        setApiMessage(data.apiMessage);
        if (data.apiMessage.Type === "success") {
          onClosePopup();
        }
      })
      .catch((error) => console.log("Edit fail"));
  };

  return (
    <div className={classes.form}>
      <Formik
        initialValues={editProjectInitValues}
        validationSchema={editProjectSchema}
        onSubmit={handleEditProject}
      >
        {({ values, isSubmitting }) => (
          <Form onKeyDown={onEnterForm}>
            <div className={classes.editAvatarWrapper}>
              <div className={classes.wrapper}>
                <img
                  alt="avt"
                  src={getAvatarSrcNoHost(fileUrl, project.Avatar)}
                  className={classes.avatarEdit}
                />
                <div
                  className={classes.editAvatarIcon}
                  onClick={() => inputFileRef?.current?.click()}
                >
                  <FontAwesomeIcon
                    icon={faCamera}
                    className={classes.editIcon}
                    size="sm"
                  />
                </div>
              </div>
            </div>
            <input
              type="file"
              id="file"
              name="avatar"
              accept="image/*"
              hidden
              ref={inputFileRef}
              onChange={onChangeFile}
            />
            <InputField
              type="text"
              name="name"
              placeholder="Tên dự án"
              label="Tên dự án"
              required
              value={values.name}
            />
            <InputNumberField
              name="process"
              placeholder="Tiến độ"
              label="Tiến độ"
              width="100%"
              min={0}
              max={100}
            />
            <TextEditor
              text={description}
              setText={setDescription}
              placeholder="Mô tả"
              label="Mô tả"
            />
            {/* <TextareaField
              rows={8}
              name="description"
              placeholder="Mô tả"
              label="Mô tả"
              value={values.description}
            /> */}

            <div className={classes.buttonGroup}>
              <CancelButton label="Hủy" onClick={onClosePopup} />
              <InputField
                type="submit"
                name="submit"
                value="Lưu"
                width="120px"
                disabled={!values.name.trim() || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProject;
