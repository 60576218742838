export const AuthMessage = {
  account100: "Phiên đăng nhập đã hết, vui lòng đăng nhập lại",
  account101: "Email đã tồn tại",
  account102: "Email hoặc mật khẩu chưa đúng",
  account104: "Mật khẩu cũ không đúng",
  account104_2: "Người dùng không tồn tại",
  account107_2: "Người dùng không tồn tại",
  account108_2: "Người dùng không tồn tại",
  account109: "Cập nhật chưa thành công",
  account105: "Email chưa được đăng ký",
  account110_1: "Hết hạn đặt lại mật khẩu. Vui lòng thử lại.",

  account001: "Đăng ký tài khoản thành công",
  account004: "Đổi mật khẩu thành công",
  account005: "Gửi email đặt lại mật khẩu thành công. Vui lòng kiểm tra email.",
  account009: "Cập nhật thông tin thành công",
  account010: "Đặt lại mật khẩu thành công",
};

export const ChatMessage = {
  groupuser001: "Thêm thành viên thành công",
  groupuser002: "Xóa thành viên thành công",
};

export const ProjectMessage = {
  copy001: "Sao chép link thành công",
  project001: "Thêm dự án thành công",
  project002: "Xóa dự án thành công",
  project003: "Cập nhật thành công",
  project004: "Thêm thành công",
  project005: "Xóa thành công",
  project006: "Thêm thành viên thành công",
  project007: "Xóa thành viên thành công",
  project008: "Chỉnh sửa thành công",
  project009: "Lấy tài liệu của dự án thành công",
  task100: "Xóa tài liệu thành công",
  task001: "Tạo task thành công",
  task002: "Xóa thành công",
  task003: "Cập nhật thành công",
  task004: "Lấy thông tin task thành công",
  task005: "Lấy thông tin task của người dùng thành công",
  task007: "Lấy tài liệu của task thành công",
  task008: "Xóa tài liệu thành công",
  feature001: "Thêm chức năng thành công",
  feature002: "Xóa chức năng thành công",
  feature003: "Cập nhật chức năng thành công",
  feature004: "Lấy thông tin chức năng thành công",
  file001: "Thêm tài liệu thành công",
  file002: "Xóa tài liệu thành công",

  search001: "Tìm kiếm dự án thành công",
  search002: "Tìm kiếm task thành công",

  notification001: "Lấy thông báo thành công",

  project101: "Thêm dự án thất bại",
  project107: "Xóa thành viên khỏi dự án thất bại",
  project109: "Không tìm thấy dự án",

  task104: "Không tìm thấy task",
  task107: "Không tìm thấy task",
  task108: "Xóa tài liệu thất bại",

  feature101: "Thêm chức năng thất bại",
  feature104: "Lấy thông tin chức năng thất bại",

  file101: "Thêm tài liệu thất bại",
  file102: "Xóa tài liệu thất bại",

  notification101: "Lấy thông báo thất bại",
};
