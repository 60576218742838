import { FC } from "react";
import classes from "./SearchUser.module.css";
import { removeAccents } from "../../helpers/chat.helpers";
import { useAppDispatch } from "../../../../hooks/hooks";
import { getUsers } from "../../redux/chat.slice";
import { UserState } from "../../../profile/types/profile.types";

export interface SearchUserProps {
  keyword: string;
  setKeyword: (keyword: string) => void;
  onClickDisplay?: () => void;
  users: UserState[];
  setUsersFilter: (filter: UserState[]) => void;
  groups?: any[];
  setGroupsFilter?: (filter: any[]) => void;
  label?: string;
  required?: boolean;
  onBlur?: () => void;
  onClick?: () => void;
}

const SearchUser: FC<SearchUserProps> = ({
  keyword,
  setKeyword,
  onClickDisplay,
  users,
  setUsersFilter,
  groups,
  setGroupsFilter,
  label,
  required = false,
  onBlur,
  onClick,
}) => {
  const dispatch = useAppDispatch();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
    if (event.target.value) {
      const userFilter = users.filter(
        (user) =>
          removeAccents(user.Fullname.toLowerCase())?.includes(
            removeAccents(event.target.value.toLowerCase())
          ) ||
          removeAccents(user.Email.split("@")[0].toLowerCase())?.includes(
            removeAccents(event.target.value.toLowerCase())
          )
      );
      setUsersFilter(userFilter);
      if (groups) {
        const groupFilter = groups.filter((group) =>
          removeAccents(group?.Group?.Name.toLowerCase())?.includes(
            removeAccents(event.target.value.toLowerCase())
          )
        );
        setGroupsFilter(groupFilter);
      }
    } else {
      setUsersFilter(users);
      if (groups) {
        setGroupsFilter(groups);
      }
    }
  };

  const handleGetUsersToChat = () => {
    dispatch(getUsers())
      .unwrap()
      .then()
      .catch(() => {
        console.log("Get users fail");
      });
  };

  return (
    <div className={classes.searchInputContainer}>
      <div onClick={onClickDisplay}>
        {label && (
          <div className={classes.label}>
            {label}
            {required && <span className={classes.required}>*</span>}
          </div>
        )}
        <input
          className={classes.searchInput}
          type="text"
          name="chat-search"
          placeholder="Tìm kiếm"
          autoComplete="off"
          value={keyword}
          onChange={onChange}
          onClick={() => {
            handleGetUsersToChat();
            if (onClick) {
              onClick();
            }
          }}
          required={required}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default SearchUser;
