import { api } from "../../../api/api";
import {
  GetNotificationsResponse,
  ReadNotificationRequest,
  ReadNotificationResponse,
} from "../types/home.types";
import { EndpointsEnum } from "./../../../constants/endpoints";

const getNotificationsApi = (id: number): Promise<GetNotificationsResponse> => {
  return api.get(`${EndpointsEnum.GET_NOTIFICATIONS}?id=${id}`);
};

const readNotificationApi = (
  data: ReadNotificationRequest
): Promise<ReadNotificationResponse> => {
  return api.post(EndpointsEnum.READ_NOTIFICATION, data);
};

export const homeApi = {
  getNotificationsApi,
  readNotificationApi,
};
