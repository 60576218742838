import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import classes from "../ImageView/ImageView.module.css";
import clsx from "clsx";
import { DirectionEnum } from "../../constants/enum";

interface VideoViewProps {
  path: string;
  isAutoHeight?: boolean;
  onClose: () => void;
  hanleGetFile?: (direction: number) => void;
  handleDownloadFile?: () => void;
}

const VideoView: FC<VideoViewProps> = ({
  path,
  isAutoHeight,
  onClose,
  hanleGetFile,
  handleDownloadFile,
}) => {
  return (
    <div className={classes.viewContainer}>
      <FontAwesomeIcon
        icon={faDownload}
        size="2x"
        className={classes.optionIcon}
        onClick={handleDownloadFile}
      />
      <FontAwesomeIcon
        icon={faTimes}
        size="2x"
        className={classes.closeIcon}
        onClick={onClose}
      />
      <div
        className={classes.videoContainer}
        style={{
          margin: "auto",
          width: isAutoHeight ? "auto" : "90%",
          height: isAutoHeight ? "90%" : "auto",
        }}
      >
        <video controls className={classes.video} width="100%" height="100%">
          <source src={path} type="video/mp4" />
        </video>
      </div>
      <div className={classes.arrowContainer}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          size="2x"
          className={clsx(classes.arrow, classes.prevArrow)}
          onClick={() => hanleGetFile(DirectionEnum.UP)}
        />
      </div>
      <div className={classes.arrowContainer}>
        <FontAwesomeIcon
          icon={faChevronRight}
          size="2x"
          className={clsx(classes.arrow, classes.nextArrow)}
          onClick={() => hanleGetFile(DirectionEnum.DOWN)}
        />
      </div>
    </div>
  );
};

export default VideoView;
