import * as Yup from "yup";
import {
  fullnameRegExp,
  phoneRegExp,
  password,
} from "./../../auth/helpers/auth.helper";

export const newConfirmPassword = Yup.string()
  .required("Mật khẩu không khớp")
  .oneOf([Yup.ref("newPassword")], "Mật khẩu không khớp");

export const fullname = Yup.string()
  .required("Không bỏ trống trường này")
  .matches(fullnameRegExp, "Không nhập ký tự đặc biệt");
export const phone = Yup.string()
  .nullable()
  .matches(phoneRegExp, "Nhập đúng định dạng số điện thoại");

export const changePasswordInitValues = {
  password: "",
  newPassword: "",
  newConfirmPassword: "",
};

export const changePasswordSchema = Yup.object({
  password: password,
  newPassword: password,
  newConfirmPassword: newConfirmPassword,
});

export const profileSchema = Yup.object({
  fullname: fullname,
  phone: phone,
});
