export enum EndpointsEnum {
  //auth_user
  LOGIN = "/user/login",
  REGISTER = "/user/register",
  REFRESH_TOKEN = "/user/refresh_token",
  FORGET_PASSWORD = "/user/forget_password",
  RESET_PASSWORD = "/user/reset_password",
  GET_CURRENT_USER = "/user/get_currentuser",
  GET_USER_PROFILE = "/user/get_inforuser",
  CHANGE_PASSWORD = "/user/change_password",
  CHANGE_PROFILE = "/user/update",
  GET_USERS = "/user/getlist",
  //chat
  GET_CONVERSATIONS = "/chat/conversation/getbyuserid",
  CREAT_CONVERSATION = "/chat/conversation/insert",
  CHANGE_GROUP_INFORMATION = "/chat/conversation/update",
  DELETE_CONVERSATION = "/chat/conversation/delete",
  GET_MESSAGES = "/chat/message/getbypage",
  SEARCH_MESSAGES = "/chat/message/getbysearch",
  SEND_PRIVATE_MESSAGE = "/chat/message/send_private_message",
  SEND_GROUP_MESSAGE = "/chat/message/send_group_message",
  SEND_FILE_MESSAGE = "/chat/message/send_file_message",
  DELETE_MESSAGE = "/chat/message/delete",
  GET_IMPORTANT = "/chat/message/get_important",
  MARK_IMPORTANT = "/chat/message/change_important",
  ADD_MEMBER = "/chat/conversationuser/insert",
  DELETE_MEMBER = "/chat/conversationuser/delete",
  GET_FILES = "/chat/conversation/getfiles",
  GET_FILE = "/chat/conversation/getfile",
  CHANGE_IS_READ = "/chat/conversationuser/update_read",
  //project
  GET_PROJECTS = "/project/get_project_by_userid",
  GET_PROJECT = "/project/get_project_by_id",
  CREATE_PROJECT = "/project/insert_project",
  EDIT_PROJECT = "/project/update_project",
  DELETE_PROJECT = "/project/delete_project",
  CREATE_FEATURE = "/project/insert_feature",
  GET_FEATURE = "/project/get_feature_by_id",
  EDIT_FEATURE = "/project/update_feature",
  DELETE_FEATURE = "/project/delete_feature",
  GET_ALL_FEATURE = "/project/get_allfeature_by_projectid",
  ADD_MEMBER_PROJECT = "/project/add_user_to_project",
  EDIT_MEMBER_PROJECT = "/project/update_user_in_project",
  DELETE_MEMBER_PROJECT = "/project/delete_user_in_project",
  GET_PROJECT_DOCUMENT = "/project/get_attachment_in_project",
  GET_TASKS = "/project/get_task_by_userid",
  GET_TASK = "/project/get_task_by_id",
  CREATE_TASK = "/project/insert_task",
  EDIT_TASK = "/project/update_task",
  DELETE_TASK = "/project/delete_task",
  DELETE_TASK_DOCUMENT = "/project/delete_attachment_by_id",
  DOWNLOAD_FILE = "/project/download_file",
  SEARCH_PROJECTS = "/project/get_project_by_search",
  SEARCH_TASKS = "/project/get_task_by_search",
  ADD_PROJECT_DOCUMENT = "/project/insert_file",
  DELETE_PROJECT_DOCUMENT = "/project/delete_file",
  GET_NOTIFICATIONS = "/project/get_notification_by_userid",
  READ_NOTIFICATION = "/project/update_read_notif",
  GET_PROEJCT_EDIT_HISTORY = "/project/get_history_by_projectid",
  GET_FEATURE_EDIT_HISTORY = "/project/get_history_by_featureid",
  GET_TASK_EDIT_HISTORY = "/project/get_history_by_taskid",
}
