import { ProjectDocumentType } from "./../features/project/types/project.types";
import { AttachmentType } from "./../features/chat/types/chat.types";
import _ from "lodash";

export const isArray = (data: any) => Array.isArray(data);
export const isObject = (data: any) =>
  data === Object(data) && !isArray(data) && typeof data !== "function";

export const toCamelCase = (data: any) => {
  if (isObject(data)) {
    const newObject: any = {};
    Object.keys(data).forEach(
      (key) => (newObject[_.camelCase(key)] = toCamelCase(data[key]))
    );
    return newObject;
  }
  if (isArray(data)) {
    return data.map((object: any) => toCamelCase(object));
  }
  return data;
};

//file extensions
export const imageNameExtensions = [
  "png",
  "jpg",
  "jfif",
  "jpeg",
  "pjp",
  "pjpeg",
  "svg",
  "webp",
  "gif",
];

export const videoNameExtensions = ["mov", "mp4", "m4p", "webm"];

//type 1: image, type 2: video, type 0: others
export const getFileTypes = (
  array: ProjectDocumentType[] | AttachmentType[]
) => {
  const fileTypes: number[] = Array(array?.length).fill(0);
  if (array?.length > 0) {
    array.forEach((file, id) => {
      imageNameExtensions.forEach((extension) => {
        if (file.Path.toLowerCase().includes(extension)) {
          fileTypes[id] = 1;
        }
      });
      videoNameExtensions.forEach((extension) => {
        if (file.Path.toLowerCase().includes(extension)) {
          fileTypes[id] = 2;
        }
      });
    });
  }
  return fileTypes;
};

export const getFileType = (path: string) => {
  let type: number;
  imageNameExtensions.forEach((extension) => {
    if (path?.toLowerCase().includes(extension)) {
      type = 1;
    }
  });
  videoNameExtensions.forEach((extension) => {
    if (path?.toLowerCase().includes(extension)) {
      type = 2;
    }
  });
  return type;
};

export const filterMediaFiles = (
  files: AttachmentType[] | ProjectDocumentType[],
  types: number[]
) => {
  const mediaFiles = [];
  const mediaTypes = [];
  types?.forEach((type, id) => {
    if (type !== 0) {
      mediaFiles.push(files[id]);
      mediaTypes.push(type);
    }
  });
  return { mediaFiles, mediaTypes };
};

export const disableDates = () => {
  let today = new Date();
  let date = today.getDate() + 1 < 10 ? `0${today.getDate()}` : today.getDate();
  let month =
    today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  let year = today.getFullYear();
  return year + "-" + month + "-" + date;
};

export const isHasOnlySpaces = (value: string) => {
  return /^\s*$/.test(value.trim());
};

export const isHasAnySpaces = (value: string) => {
  return /\s/.test(value);
};
