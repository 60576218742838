import React, { FC, memo, useEffect, useRef, useState } from "react";
import classes from "./UploadFiles.module.css";

interface UploadFilesProps {
  name?: string;
  label?: string;
  required?: boolean;
  files: File[];
  setFiles: (files: File[]) => void;
  sizeOfFiles: string[];
  setSizeOfFiles: (size: string[]) => void;
  filesUrl?: any[];
  setFilesUrl?: (filesUrl: any) => void;
  dragFile: boolean;
  setDragFile: (dragFile: boolean) => void;
  handleDrag: (event: React.DragEvent) => void;
  inputTextRef: React.MutableRefObject<HTMLTextAreaElement>;
  children: React.ReactNode;
  setHasLargeFile?: (bool: boolean) => void;
}

const UploadFiles: FC<UploadFilesProps> = ({
  name,
  label,
  required = false,
  files,
  setFiles,
  filesUrl,
  sizeOfFiles,
  setSizeOfFiles,
  setFilesUrl,
  dragFile,
  setDragFile,
  handleDrag,
  inputTextRef,
  children,
}) => {
  const inputFileRef = useRef<HTMLInputElement>();

  const handleSetFiles = (fileList: any) => {
    const fileListKeys = Object.keys(fileList);
    const newFiles: File[] = [];
    const newFilesUrl = [];
    const newSizeOfFiles = [];
    let [width, height] = [0, 0];

    fileListKeys.forEach((key) => {
      if (fileList[key].size <= 100 * 1024 * 1024) {
        newFiles.push(fileList[key]);
        setFiles([...files, ...newFiles]);
      }

      let element: any;
      const newFileUrl = {
        fileUrl: URL.createObjectURL(fileList[key]),
        name: fileList[key].name,
        type: fileList[key].type,
        isAutoHeight: false,
      };
      if (fileList[key].type.includes("image")) {
        element = document.createElement("img");
        element.src = newFileUrl.fileUrl;
        element.onload = (e: any) => {
          [width, height] = [e.target.naturalWidth, e.target.naturalHeight];
          newFileUrl.isAutoHeight = width < height;
          newFilesUrl.push(newFileUrl);
          setFilesUrl([...filesUrl, ...newFilesUrl]);
          newSizeOfFiles.push(`${width}x${height}`);
          if (fileList[key].size <= 100 * 1024 * 1024) {
            setSizeOfFiles([...sizeOfFiles, ...newSizeOfFiles]);
          }
        };
      } else if (fileList[key].type.includes("video")) {
        element = document.createElement("video");
        element.src = newFileUrl.fileUrl;
        element.onloadedmetadata = (e: any) => {
          [width, height] = [e.target.videoWidth, e.target.videoHeight];
          newFileUrl.isAutoHeight = width < height;
          newFilesUrl.push(newFileUrl);
          setFilesUrl([...filesUrl, ...newFilesUrl]);
          newSizeOfFiles.push(`${width}x${height}`);
          if (fileList[key].size <= 100 * 1024 * 1024) {
            setSizeOfFiles([...sizeOfFiles, ...newSizeOfFiles]);
          }
        };
      } else {
        newFilesUrl.push(newFileUrl);
        setFilesUrl([...filesUrl, ...newFilesUrl]);
        newSizeOfFiles.push(`0x0`);
        if (fileList[key].size <= 100 * 1024 * 1024) {
          setSizeOfFiles([...sizeOfFiles, ...newSizeOfFiles]);
        }
      }
    });
  };

  if (inputTextRef?.current?.focus) {
    window.addEventListener("paste", (e: ClipboardEvent) => {
      handleSetFiles(e.clipboardData.files);
    });
  }

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSetFiles(e.target.files);
    inputTextRef?.current?.focus();
  };

  const onDropFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragFile(false);
    handleSetFiles(e.dataTransfer.files);
    inputTextRef?.current?.focus();
  };

  return (
    <>
      {label && (
        <div className={classes.label}>
          {label}
          {required && <span className={classes.required}>*</span>}
        </div>
      )}
      <input
        ref={inputFileRef}
        type="file"
        id="files"
        name={name || "Files"}
        hidden
        multiple
        onChange={onChangeFile}
        onDrag={onDropFile}
        onClick={(event) => (event.currentTarget.value = null)}
      />
      <div onClick={() => inputFileRef?.current?.click()}>{children}</div>

      {dragFile && (
        <div
          className={classes.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={onDropFile}
        >
          + Drag and drop file here
        </div>
      )}
    </>
  );
};

export default memo(UploadFiles);
