import { FC, memo, useRef } from "react";
import { useField } from "formik";
import classes from "../InputField/InputField.module.css";
import clsx from "clsx";

export interface InputFieldProps {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  width?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
}

const InputNumberField: FC<InputFieldProps> = (props) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.inputWrapper} style={{ width: props.width }}>
      {props.label && (
        <div className={classes.label}>
          {props.label}
          {props.required && <span className={classes.required}>*</span>}
        </div>
      )}
      <div className={clsx(props.name === "process" && classes.inputField)}>
        <input
          type="number"
          className={classes.input}
          {...field}
          {...props}
          autoComplete="off"
        />
        {props.name === "process" && <span className={classes.process}>%</span>}
      </div>

      {meta.touched && meta.error ? (
        <p className={classes.wrongInput}>{meta.error}</p>
      ) : (
        <p className={classes.rightInput}>{props.hint}</p>
      )}
    </div>
  );
};

export default memo(InputNumberField);
