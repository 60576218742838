import { FC } from "react";
import { FileTypeEnum } from "../../constants/enum";
import { HOST } from "../../constants/variable";
import { getFile } from "../../features/chat/redux/chat.slice";
import { AttachmentType } from "../../features/chat/types/chat.types";
import { ProjectDocumentType } from "../../features/project/types/project.types";
import { getFileType } from "../../helpers/helpers";
import { useAppDispatch } from "../../hooks/hooks";
import FileView from "../FileView/FileView";
import ImageView from "../ImageView/ImageView";
import VideoView from "../VideoView/VideoView";

interface FileListViewProps {
  // list?: AttachmentType[] | ProjectDocumentType[];
  conversationId?: number;
  currentFile: AttachmentType | ProjectDocumentType;
  setCurrentFile: (currentFile: AttachmentType | ProjectDocumentType) => void;
  viewType: number;
  setViewType: (displayType: number) => void;
  handleDownloadFile: (path: string, name: string) => void;
}

const FileListView: FC<FileListViewProps> = ({
  conversationId,
  currentFile,
  setCurrentFile,
  viewType,
  setViewType,
  handleDownloadFile,
}) => {
  const dispatch = useAppDispatch();
  const [width, height] = currentFile?.Size
    ? currentFile?.Size?.split("x")
    : [0, 0];

  const handleGetFile = (direction: number) => {
    dispatch(
      getFile({
        Id: currentFile.Id,
        ConversationId: conversationId,
        Direction: direction,
      })
    )
      .unwrap()
      .then((data) => {
        setViewType(getFileType(data?.file?.Path));
        setCurrentFile(data.file);
      });
  };
  return (
    <>
      {currentFile?.Id && viewType === FileTypeEnum.IMAGE && (
        <ImageView
          path={`${HOST}${currentFile.Path}`}
          isAutoHeight={width < height}
          onClose={() => setCurrentFile(null)}
          hanleGetFile={handleGetFile}
          handleDownloadFile={() =>
            handleDownloadFile(currentFile.Path, currentFile.Name)
          }
        />
      )}
      {currentFile?.Id && viewType === FileTypeEnum.VIDEO && (
        <VideoView
          path={`${HOST}${currentFile.Path}`}
          isAutoHeight={width < height}
          onClose={() => setCurrentFile(null)}
          hanleGetFile={handleGetFile}
          handleDownloadFile={() =>
            handleDownloadFile(currentFile.Path, currentFile.Name)
          }
        />
      )}
      {currentFile?.Id && viewType === FileTypeEnum.OTHER && (
        <FileView
          onClose={() => setCurrentFile(null)}
          handleDownloadFile={() =>
            handleDownloadFile(currentFile.Path, currentFile.Name)
          }
        />
      )}
      {/* {mediaFiles.length > 0 && (
        <div className={classes.silderPreview}>
          {mediaFiles.map((file, id) => (
            <div key={file.Path} className={classes.preview}>
              {mediaTypes[id] === 1 ? (
                <img
                  alt="avt"
                  src={`${HOST}${file.Path}`}
                  className={classes.thumbnail}
                />
              ) : (
                <video
                  src={`${HOST}${file.Path}`}
                  className={classes.thumbnail}
                />
              )}
            </div>
          ))}
        </div>
      )} */}
    </>
  );
};

export default FileListView;
