import { RouteItemDef } from "./../../../types/routes.types";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen/RegisterScreen";
import ForgetPasswordScreen from "../screens/ForgetPasswordScreen/ForgetPasswordScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen/ResetPasswordScreen";
import { PathsEnum } from "../../../constants/paths";

const LOGIN_SCREEN: RouteItemDef = {
  id: "login",
  path: PathsEnum.LOGIN,
  component: LoginScreen,
};

const REGISTER_SCREEN: RouteItemDef = {
  id: "register",
  path: PathsEnum.REGISTER,
  component: RegisterScreen,
};

const FORGET_PASSWORD_SCREEN: RouteItemDef = {
  id: "forgetpassword",
  path: PathsEnum.FORGET_PASSWORD,
  component: ForgetPasswordScreen,
};

const RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "resetpassword",
  path: PathsEnum.RESET_PASSWORD,
  component: ResetPasswordScreen,
};

const AUTH_ROUTES = [
  LOGIN_SCREEN,
  REGISTER_SCREEN,
  FORGET_PASSWORD_SCREEN,
  RESET_PASSWORD_SCREEN,
];

export default AUTH_ROUTES;
