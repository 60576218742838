import { FC, memo, useState } from "react";
import { Formik, Form } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import classes from "./LoginScreen.module.css";
import {
  loginInitValues,
  loginValidateSchema,
} from "../../helpers/auth.helper";
import { ApiMessageType, LoginRequest } from "../../types/auth.types";
import { login } from "../../redux/auth.slice";
import InputField from "../../../../components/Form/InputField/InputField";
import { RootState } from "../../../../redux/store";
import { ROOT_ROUTE } from "../../../../routes/routes.config";
import { AuthMessage } from "../../../../constants/api.message";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import InputPasswordField from "../../../../components/Form/InputPasswordField/InputPasswordField";
import { PathsEnum } from "../../../../constants/paths";
import logo from "../../../../assets/logo.png";
import { clsx } from "clsx";
import { getConversationsWithToken } from "../../../chat/redux/chat.slice";
import Loader from "../../../../components/Loader/Loader";

const LoginScreen: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const token = useAppSelector((state: RootState) => state.auth.token);
  const [loginError, setLoginError] = useState("");
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();
  const [loading, setLoading] = useState(false);

  if (token) {
    window.location.href = PathsEnum.CHAT;
  }

  const handleLogin = (values: LoginRequest) => {
    setLoginError("");
    setLoading(true);
    dispatch(login(values))
      .unwrap()
      .then((data) => {
        if (data.apiMessage?.Type === "error") {
          setLoading(false);
          setLoginError(AuthMessage[data.apiMessage?.Code]);
        } else {
          dispatch(
            getConversationsWithToken({
              userId: data?.Id,
              token: data?.Token,
            })
          )
            .unwrap()
            .then((data) => {
              if (data.length > 0) {
                window.location.href = PathsEnum.EXIST_CONVERSATION.replace(
                  ":id",
                  `${data[0]?.Id}`
                );
              }
            });
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <div>
      {loading && <Loader isFullScreen={true} />}
      <div className={clsx(classes.loginScreen, classes.login)}>
        <img alt="logo" src={logo} className={classes.logo} />
        <div className={classes.title}>Project manager</div>
        <div className={classes.loginText}>Đăng nhập</div>
        <Formik
          initialValues={loginInitValues}
          validationSchema={loginValidateSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <InputField
              type="email"
              name="email"
              placeholder="Email"
              label="Email"
              required
            />
            <InputPasswordField
              name="password"
              placeholder="Mật khẩu"
              label="Mật khẩu"
              required
              isLoginPassword
            />
            <div
              className={classes.forgetPassword}
              onClick={() => history.push(PathsEnum.FORGET_PASSWORD)}
            >
              Quên mật khẩu?
            </div>
            {loginError && (
              <div className={classes.authError}>{loginError}</div>
            )}
            <InputField type="submit" name="submit" value="Đăng nhập" />
          </Form>
        </Formik>
        <div className={classes.introText}>
          Bạn chưa có tài khoản?&nbsp;
          <Link to={PathsEnum.REGISTER} className={classes.registerLink}>
            Đăng ký
          </Link>
          &nbsp;ngay!
        </div>
      </div>
      {apiMessage && (
        <NotifyAlert
          type={apiMessage.Type}
          message={AuthMessage[apiMessage.Code]}
          setApiMessage={setApiMessage}
        />
      )}
    </div>
  );
};

export default memo(LoginScreen);
