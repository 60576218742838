import clsx from "clsx";
import { FC } from "react";
import { UserState } from "../../../profile/types/profile.types";
import { getAvatarSrc } from "../../helpers/chat.helpers";
import classes from "./NoChat.module.css";

interface NoChatProps {
  currentUser: UserState;
  setDisplayNewChat: (bool: boolean) => void;
  setDisplayNewGroupChat: (bool: boolean) => void;
  screenWidth: number;
}

const NoChat: FC<NoChatProps> = ({
  currentUser,
  setDisplayNewChat,
  setDisplayNewGroupChat,
  screenWidth,
}) => {
  const isResponsive = screenWidth < 992;
  const isMobile = screenWidth < 575;
  return (
    <div
      className={clsx(classes.noChat, {
        [classes.isResponsive]: isResponsive,
        [classes.isMobile]: isMobile,
      })}
    >
      <div className={classes.noChatContainer}>
        <div className={classes.noChatHeader}>
          <img
            alt="avt"
            src={getAvatarSrc(currentUser.Avatar)}
            className={classes.avatar}
          />
          <div>
            <div>Xin chào!</div>
            <div className={classes.username}>{currentUser.Fullname}</div>
          </div>
        </div>
        <div className={classes.noChatBoby}>
          <div>Hãy bắt đầu cuộc trò chuyện!</div>
          <div className={classes.actionGroup}>
            <button
              className={classes.createBtn}
              onClick={() => setDisplayNewChat(true)}
            >
              Cuộc trò chuyện mới
            </button>
            <button
              className={classes.createBtn}
              onClick={() => setDisplayNewGroupChat(true)}
            >
              Nhóm trò chuyện mới
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoChat;
