import { FC, useState } from "react";
import { Formik, Form } from "formik";
import classes from "./ResetPasswordScreen.module.css";
import InputField from "../../../../components/Form/InputField/InputField";
import { ApiMessageType } from "../../types/auth.types";
import {
  resetPasswordInitValues,
  resetPasswordSchema,
} from "../../helpers/auth.helper";
import { useAppDispatch } from "../../../../hooks/hooks";
import { AuthMessage } from "../../../../constants/api.message";
import { resetPassword } from "../../redux/auth.slice";
import jwtDecode from "jwt-decode";
import { Link, useHistory } from "react-router-dom";
import NotifyAlert from "../../../../components/NotifyAlert/NotifyAlert";
import InputPasswordField from "../../../../components/Form/InputPasswordField/InputPasswordField";
import { PathsEnum } from "../../../../constants/paths";
import logo from "../../../../assets/logo.png";

const ResetPasswordScreen: FC = () => {
  const locationParams = window.location.search.split("&");
  const email = locationParams[0].split("=")[1];
  const token = locationParams[1].split("=")[1];
  const expireIn = jwtDecode<{ exp: number }>(token)?.exp * 1000;
  const nowDate = new Date().getTime();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState<ApiMessageType>();

  const handleResetPassword = (values: any) => {
    if (expireIn > nowDate) {
      dispatch(
        resetPassword({
          Email: email,
          Password: values.password,
        })
      )
        .unwrap()
        .then((data) => {
          setApiMessage(data.apiMessage);
          if (data.apiMessage.Type === "success") {
            setTimeout(() => history.push(PathsEnum.LOGIN), 1000);
          }
        });
    } else {
      setApiMessage({
        Type: "error",
        Code: "account110_1",
      });
    }
  };
  return (
    <>
      <div className={classes.resetPasswordScreen}>
        <img alt="logo" src={logo} className={classes.logo} />
        <div className={classes.title}>Project manager</div>
        <div className={classes.resetPasswordText}>Đặt lại mật khẩu</div>
        <Formik
          initialValues={resetPasswordInitValues}
          validationSchema={resetPasswordSchema}
          onSubmit={handleResetPassword}
        >
          {({ values }) => (
            <Form>
              <InputPasswordField
                name="password"
                label="Mật khẩu"
                placeholder="Mật khẩu"
                required
              />
              <InputPasswordField
                name="confirmPassword"
                label="Nhập lại mật khẩu"
                placeholder="Mật khẩu"
                required
              />
              {apiMessage?.Type === "error" && (
                <div className={classes.error}>
                  {AuthMessage[apiMessage?.Code]}
                </div>
              )}
              <InputField
                type="submit"
                name="submit"
                value="OK"
                disabled={
                  !values.password.trim() ||
                  values.password !== values.confirmPassword
                }
              />
              <div className={classes.loginLink}>
                <Link to={PathsEnum.LOGIN} className={classes.loginLink}>
                  Quay lại Đăng nhập
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {apiMessage?.Type === "success" && (
        <NotifyAlert
          type={apiMessage.Type}
          message={AuthMessage[apiMessage?.Code]}
          setApiMessage={setApiMessage}
        />
      )}
    </>
  );
};

export default ResetPasswordScreen;
