export const LocalStorage = {
  setCreateTask() {
    localStorage.setItem("create_task", "1");
  },
  getCreateTask() {
    return localStorage.getItem("create_task");
  },
  removeCreateTask() {
    localStorage.removeItem("create_task");
  },
  setCreateProject() {
    localStorage.setItem("create_project", "1");
  },
  getCreateProject() {
    return localStorage.getItem("create_project");
  },
  removeCreateProject() {
    localStorage.removeItem("create_project");
  },
  setCreateFeature() {
    localStorage.setItem("create_feature", "1");
  },
  getCreateFeature() {
    return localStorage.getItem("create_feature");
  },
  removeCreateFeature() {
    localStorage.removeItem("create_feature");
  },
};
