import { RouteItemDef } from "./../../../types/routes.types";
import ProfileScreen from "../screens/ProfileScreen/ProfileScreen";
import { PathsEnum } from "../../../constants/paths";

const PROFILE_SCREEN: RouteItemDef = {
  id: "profile",
  path: PathsEnum.PROFILE,
  component: ProfileScreen,
};

const PROFILE_ROUTES = [PROFILE_SCREEN];

export default PROFILE_ROUTES;
