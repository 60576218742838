import { RouteItemDef } from "./../../../types/routes.types";
import { PathsEnum } from "../../../constants/paths";
import ProjectListScreen from "../screens/ProjectListScreen/ProjectListScreen";
import FeatureScreen from "../screens/FeatureScreen/FeatureScreen";
import ProjectDetailScreen from "../screens/ProjectDetailScreen/ProjectDetailScreen";
import ProjectMemberScreen from "../screens/ProjectMemberScreen/ProjectMemberScreen";
import ProjectDocumentScreen from "../screens/ProjectDocumentScreen/ProjectDocumentScreen";
import TaskScreen from "../screens/TaskScreen/TaskScreen";
import TaskListScreen from "../screens/TaskListScreen/TaskListScreen";

const PROJECT_LIST_SCREEN: RouteItemDef = {
  id: "projects",
  path: PathsEnum.PROJECTS,
  component: ProjectListScreen,
};

const PROJECT_DETAIL_SCREEN: RouteItemDef = {
  id: "project_detail",
  path: PathsEnum.PROJECT_DETAIL,
  component: ProjectDetailScreen,
};

const PROJECT_MEMBER_SCREEN: RouteItemDef = {
  id: "project_member",
  path: PathsEnum.PROJECT_MEMBER,
  component: ProjectMemberScreen,
};

const PROJECT_DOCUMENTS_SCREEN: RouteItemDef = {
  id: "project_documents",
  path: PathsEnum.PROJECT_DOCUMENTS,
  component: ProjectDocumentScreen,
};

const FEATURE_SCREEN: RouteItemDef = {
  id: "feature",
  path: PathsEnum.FEATURE,
  component: FeatureScreen,
};

const TASK_LIST_SCREEN: RouteItemDef = {
  id: "task_list",
  path: PathsEnum.TASKS,
  component: TaskListScreen,
};

const TASK_SCREEN: RouteItemDef = {
  id: "task",
  path: PathsEnum.TASK,
  component: TaskScreen,
};

const PROJECT_ROUTES = [
  PROJECT_LIST_SCREEN,
  PROJECT_DETAIL_SCREEN,
  PROJECT_MEMBER_SCREEN,
  PROJECT_DOCUMENTS_SCREEN,
  FEATURE_SCREEN,
  TASK_SCREEN,
  TASK_LIST_SCREEN,
];

export default PROJECT_ROUTES;
