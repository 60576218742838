import { FC } from "react";
import { Link } from "react-router-dom";
import classes from "./Breadcrumbs.module.css";

interface BreadcrumbsProps {
  linkList: string[][];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ linkList }) => {
  const items = [];
  let key = 0;
  linkList.forEach((_, id) => {
    if (items.length > 0) {
      items.push(
        <span key={key++} className={classes.separate}>
          &gt;
        </span>
      );
    }
    if (linkList[id].length === 2) {
      items.push(
        <Link className={classes.link} key={key++} to={linkList[id][1]}>
          {linkList[id][0]}
        </Link>
      );
    } else {
      items.push(
        <span key={key++} className={classes.item}>
          {linkList[id][0]}
        </span>
      );
    }
  });
  return <div className={classes.breadcrumbs}>{items}</div>;
};

export default Breadcrumbs;
