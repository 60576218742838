import { projectApi } from "./../api/project.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ProjectState,
  CreateProjectRequest,
  DeleteProjectRequest,
  AddMemberProjectRequest,
  DeleteMemberProjectRequest,
  CreateFeatureRequest,
  EditFeatureRequest,
  DeleteFeatureRequest,
  DeleteTaskRequest,
  DeleteDocumentRequest,
  SearchProjectsRequest,
  SearchTasksRequest,
  GetEditHistoryRequest,
} from "./../types/project.types";

const initialState: ProjectState = {
  listProject: null,
  project: null,
  feature: null,
  listTask: null,
  task: null,
  editHistory: null,
  apiMessage: null,
  loading: true,
};

export const getProjects = createAsyncThunk<any, number>(
  "project/getProjects",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getProjectsApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProjects = createAsyncThunk<any, SearchProjectsRequest>(
  "project/searchProjects",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.searchProjectsApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getProject = createAsyncThunk<any, string>(
  "project/getProject",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getProjectApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProject = createAsyncThunk<any, CreateProjectRequest>(
  "project/createProject",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.createProjectApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editProject = createAsyncThunk<any, FormData>(
  "project/editProject",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.editProjectApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProject = createAsyncThunk<any, DeleteProjectRequest>(
  "project/deleteProject",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.deleteProjectApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFeature = createAsyncThunk<any, CreateFeatureRequest>(
  "project/createFeature",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.createFeatureApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFeature = createAsyncThunk<any, number>(
  "project/getFeature",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getFeatureApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editFeature = createAsyncThunk<any, EditFeatureRequest>(
  "project/editFeature",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.editFeatureApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFeature = createAsyncThunk<any, DeleteFeatureRequest>(
  "project/deleteFeature",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.deleteFeatureApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addMemberProject = createAsyncThunk<any, AddMemberProjectRequest>(
  "project/addMemberProject",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.addMemberProjectApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editMemberProject = createAsyncThunk<any, AddMemberProjectRequest>(
  "project/editMemberProject",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.editMemberProjectApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteMemberProject = createAsyncThunk<
  any,
  DeleteMemberProjectRequest
>("project/deleteMemberProject", async (requestData, { rejectWithValue }) => {
  try {
    const response = await projectApi.deleteMemberProjectApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProjectDocument = createAsyncThunk<any, string>(
  "project/getProjectDocument",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getProjectDocumentApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTasks = createAsyncThunk<any, number>(
  "project/getTasks",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getTasksApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchTasks = createAsyncThunk<any, SearchTasksRequest>(
  "project/searchTasks",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.searchTasksApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTask = createAsyncThunk<any, FormData>(
  "project/createTask",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.createTaskApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTask = createAsyncThunk<any, number>(
  "project/getTask",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getTaskApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editTask = createAsyncThunk<any, FormData>(
  "project/editTask",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.editTaskApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk<any, DeleteTaskRequest>(
  "project/deleteTask",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.deleteTaskApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTaskDocument = createAsyncThunk<any, DeleteDocumentRequest>(
  "project/deleteTaskDocument",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.deleteTaskDocumentApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadFile = createAsyncThunk<any, string>(
  "project/downloadFile",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.downloadFileApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProjectDocument = createAsyncThunk<any, FormData>(
  "project/addProjectDocument",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.addProjectDocumentApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProjectDocument = createAsyncThunk<
  any,
  DeleteDocumentRequest
>("project/deleteProjectDocument", async (requestData, { rejectWithValue }) => {
  try {
    const response = await projectApi.deleteProjectDocumentApi(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAllFeature = createAsyncThunk<any, string>(
  "project/getAllFeature",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await projectApi.getAllFeatureApi(requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEditHistory = createAsyncThunk<any, GetEditHistoryRequest>(
  "project/getEditHistory",
  async (requestData, { rejectWithValue }) => {
    try {
      let response;
      if (requestData.ProjectId) {
        response = await projectApi.getProjectEditHistoryApi(requestData);
      }
      if (requestData.FeatureId) {
        response = await projectApi.getFeatureEditHistoryApi(requestData);
      }
      if (requestData.TaskId) {
        response = await projectApi.getTaskEditHistoryApi(requestData);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreEditHistory = createAsyncThunk<any, GetEditHistoryRequest>(
  "project/getMoreEditHistory",
  async (requestData, { rejectWithValue }) => {
    try {
      let response;
      if (requestData.ProjectId) {
        response = await projectApi.getProjectEditHistoryApi(requestData);
      }
      if (requestData.FeatureId) {
        response = await projectApi.getFeatureEditHistoryApi(requestData);
      }
      if (requestData.TaskId) {
        response = await projectApi.getTaskEditHistoryApi(requestData);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    addMemberProjectReducer: (state, action) => {
      state.project.Members.push(action.payload);
    },
    editMemberProjectReducer: (state, action) => {
      const index = state.project.Members.map((member) => member.Id).indexOf(
        action.payload.Id
      );
      state.project.Members[index] = action.payload;
    },
    // addFeatureReducer: (state, action) => {
    //   if (action.payload.ParentId) {
    //     const index = state.project.Features.map(
    //       (feature) => feature.Id
    //     ).indexOf(action.payload.ParentId);
    //     state.project.Features[index].SubFeatures.push(action.payload);
    //   } else {
    //     state.project.Features.push(action.payload);
    //   }
    // },
    editFeatureReducer: (state, action) => {
      state.feature.Name = action.payload?.Name;
      state.feature.Description = action.payload?.Description;
      const index = state.project.Features.map((feature) => feature.Id).indexOf(
        action.payload.Id
      );
      state.project.Features[index].Name = action.payload?.Name;
      state.project.Features[index].Description = action.payload?.Description;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.listProject = action.payload.listProject;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.project = action.payload.project;
    });
    builder.addCase(deleteMemberProject.fulfilled, (state, action) => {
      const newMembers = state.project.Members.filter(
        (member) => member.Id !== action.payload.userId
      );
      state.project.Members = newMembers;
    });
    builder.addCase(editProject.fulfilled, (state, action) => {
      state.project.Avatar = action.payload.project.Avatar;
      state.project.Name = action.payload.project.Name;
      state.project.Description = action.payload.project.Description;
      state.project.Process = action.payload.project.Process;
      if (state.listProject) {
        const index = state.listProject
          .map((project) => project.Id)
          .indexOf(action.payload.project.Id);
        state.listProject[index].Avatar = action.payload.project.Avatar;
        state.listProject[index].Name = action.payload.project.Name;
        state.listProject[index].Description =
          action.payload.project.Description;
        state.listProject[index].Process = action.payload.project.Process;
      }
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.listProject = state.listProject.filter(
        (project) => project.Id !== action.payload.id
      );
    });
    builder.addCase(createFeature.fulfilled, (state, action) => {
      if (action.payload.feature.ParentId) {
        if (state.feature.SubFeatures) {
          state.feature.SubFeatures.push(action.payload.feature);
        } else {
          state.feature.SubFeatures = [action.payload.feature];
        }
      } else {
        if (state.project.Features) {
          state.project.Features.push(action.payload.feature);
        } else {
          state.project.Features = [action.payload.feature];
        }
      }
    });
    builder.addCase(getFeature.fulfilled, (state, action) => {
      // const index = state.project.Features.map((feature) => feature.Id).indexOf(
      //   action.payload.feature.Id
      // );
      state.feature = action.payload.feature;
    });
    builder.addCase(editFeature.fulfilled, (state, action) => {
      if (state.feature) {
        state.feature.Name = action.payload.feature?.Name;
        state.feature.Description = action.payload.feature?.Description;
      }
      if (!action.payload.feature.ParentId) {
        const index = state.project.Features.map(
          (feature) => feature.Id
        ).indexOf(action.payload.feature?.Id);
        state.project.Features[index].Name = action.payload.feature?.Name;
        state.project.Features[index].Description =
          action.payload.feature?.Description;
      }
    });
    builder.addCase(deleteFeature.fulfilled, (state, action) => {
      const newFeatures = state.project.Features.filter(
        (feature) => feature.Id !== action.payload.id
      );
      state.project.Features = newFeatures;
    });
    builder.addCase(getProjectDocument.fulfilled, (state, action) => {
      state.project.Files = action.payload.attachments;
    });
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.listTask = action.payload.listTask;
      state.loading = false;
    });
    // builder.addCase(createTask.fulfilled, (state, action) => {
    //   state.feature.Tasks.push(action.payload.id);
    // });
    builder.addCase(getTask.fulfilled, (state, action) => {
      state.task = action.payload.task;
    });
    builder.addCase(editTask.fulfilled, (state, action) => {
      state.task = action.payload.task;
      action.payload.task.Files.forEach((file) =>
        state.project.Files.unshift(file)
      );
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      const newTasks = state.feature.Tasks.filter(
        (task) => task.Id !== action.payload.taskId
      );
      state.feature.Tasks = newTasks;
    });
    builder.addCase(deleteTaskDocument.fulfilled, (state, action) => {
      state.task.Files = state.task.Files.filter(
        (file) => file.Id !== action.payload.attachmentId
      );
      state.project.Files = state.project.Files.filter(
        (file) => file.Id !== action.payload.attachmentId
      );
    });
    builder.addCase(deleteProjectDocument.fulfilled, (state, action) => {
      state.project.Files = state.project.Files.filter(
        (file) => file.Id !== action.payload.attachmentId
      );
    });
    builder.addCase(addProjectDocument.fulfilled, (state, action) => {
      action.payload.listFile.forEach((file) =>
        state.project.Files.unshift(file)
      );
    });
    builder.addCase(getEditHistory.fulfilled, (state, action) => {
      state.editHistory = action.payload.list;
    });
    builder.addCase(getMoreEditHistory.fulfilled, (state, action) => {
      state.editHistory = [...state.editHistory, ...action.payload.list];
    });
  },
});

export const {
  addMemberProjectReducer,
  editMemberProjectReducer,
  // addFeatureReducer,
  editFeatureReducer,
} = projectSlice.actions;

export const projectReducer = projectSlice.reducer;
