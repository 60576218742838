import { CreateGroupChatRequest } from "./../types/chat.types";
import * as Yup from "yup";

export const newGroupChatInitValues: Pick<
  CreateGroupChatRequest,
  "Name" | "MemberGroup"
> = {
  Name: "",
  MemberGroup: [],
};

export const addMemberInitValues: Pick<CreateGroupChatRequest, "MemberGroup"> =
  {
    MemberGroup: [],
  };

export const newMessageInitValues: any = {
  Message: "",
  Files: [],
};

const Name = Yup.string();
const MemberGroup = Yup.array<string[]>();
const Message = Yup.string();
const Files = Yup.array<string[]>();

export const newGroupChatSchema = Yup.object({
  Name,
  MemberGroup,
});

export const addMemberSchema = Yup.object({
  MemberGroup,
});

export const newMessageSchema = Yup.object({
  Message,
  Files,
});
